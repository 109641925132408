import { Base } from '../Base'
import PoliticalExposedPerson from '@master/model/kyc-form/external/PoliticalExposedPerson'
import { Default } from '@master/annotation/Default'
import { MaxChar } from '@master/annotation/MaxChar'
import { InputMaxChar } from '@master/enum/MaxCharsEnum'
import { IDateService } from '@master/services/IDateService'
import { Ignore } from '@master/annotation/Ignore'
import { cid, container } from 'inversify-props'

export default class ClientDeclaration extends Base {

@Default([])
payersSourceFundList: string[] = []

@Default([])
thirdPartySourceFundList: string[] = []

@Default([])
payersWealthSourceList: string[] = []

@Default('')
othersPayersSourceRemark : string=''
// third party payer
 @Default('')
 tpName : string=''
 @Default('')
 tpNationality: string=''
 @Default('')
 tpCountryOfResidence: string=''
 @Default('')
 tpRelationWithClient: string=''
 @Default('')
 tpRelationWithClientOtherThirdParty: string=''
 @Default('')
 tpIdentityNumber:string=''
 @Default('')
 tpnric:string=''
 @Default('')
 tpPaymentReason: string =''
 @Default([])
 tpInsuranceInvestmentPortfolios: string[] = []
 @Default('')
 tpOthersPayersSourceRemark : string=''

 @Default('')
  OthersPayersWealthSourceRemark : string=''

  //tax declaration
  @Default('')
  isCovictedTaxCrime : string=''
  @Default('')
  selectedTaxCrimeClient : string=''
  @Default('')
  taxCrimeRemark : string=''

//Beneficial Owner
  @Default('')
  isHavingBeneficialOwner : string=''
  @Default([])
  selectedInsurancePlanForBO: string[] = []
  @Default('')
  beneficialOwnerRemark : string=''
  
  //consent
  @Default([])
  consentList: string[] = []
  
  // aps acknowledgement
  @Default('')
  selectedAPSCategoryForClient1: string =''
  @Default('')
  selectedAPSCategoryForClient2: string =''

  @Default(false)
  isAPSCategoryAvailableForClient1: boolean =false
  @Default(false)
  isAPSCategoryAvailableForClient2: boolean =false
  @Default('')
  selectedAPSCategoryForDep: string =''
  @Default('')
  selectedAPSCategory: string =''
  // @Default('')
  // selectedAPSCategoryForDep3: string =''
  // @Default('')
  // selectedAPSCategoryForDep4: string =''
  @Default(false)
  isAPSAcknowledged: boolean =false

  //mode of aquisition
  @Default('')
  aquisitionMode : string=''
  @Default('')
  aquisitionModeLocation : string=''
  @Default('')
  aquisitionModeEventDate : string=''
  @Default('')
  aquisitionModeOthersRemark : string=''


  //meeting
  @Default('')
  selectedMeetingOption : string=''

  // @Default('')
  // f2fEventLocation: string=''
  // @Default('')
  // f2fEventDate: string=''
  // @Default('')
  // f2fOtherOptions: string=''
   //non relationshipWithTenant
  @Default('')
  isHavingRelationshipWithTenant : string=''
  @Default('')
  relationshipRemark: string =''
  //cka
  @Default('')
  CKADeclarationC1:string =''
  @Default('')
  CKADeclarationC2:string =''
  //car
  @Default('')
  CARDeclarationC1:string =''
  @Default('')
  CARDeclarationC2:string =''

  identifyingPrioritiesAdditionalNotes = '' // was ma_identifying_priorities_additional_notes // no completion check needed
  clientDeclFcProvideCopy?: boolean = false // was caDeclFcProvideCopy
  clientDeclStatements?: boolean = false // was caDeclStatements
  clientDeclAdvisoryProcess = '' // was declarationAdvisoryProcess
  clientDeclIntroduced = '' // was declarationIntroduced

  @MaxChar(70)
  clientDeclIntroducedId = '' // was declarationIntroducedId

  @MaxChar(70)
  clientDeclIntroducedName = '' // was declarationIntroducedName

  clientDeclPoliticallyExposed = '' // was declarationPoliticallyExposed
  clientDecisionAgree?: boolean = false // used in recommendation page's client decision // no completion check needed
  clientDeclAcknowledgement = '' // was declarationAcknowledgementByClient
  contactingYou?: boolean = false
  crcPartI?: boolean = false
  crcPartIi?: boolean = false
  crcPartIii?: boolean = false
  crcPartIv?: boolean = false
  crcPartV?: boolean = false
  crcPartVi?: boolean = false
  crcPartVii?: boolean = false
  crcPartViii?: boolean = false
  disclosure?: boolean = false
  disclosureAps?: boolean = false
  explainedFeesCharges = ''
  pdpaConsent = '' // was ppdaConsent
  marketingConsent = ''
  reviewDate?: number = undefined // not sure if need completion check
  suggestedNextFinancialUpdate?: number = undefined
  cashRebate = ''
  client0ContactMode: string[] = []
  client1ContactMode: string[] = []

  @MaxChar(InputMaxChar.LARGE)
  discloseDependant?: boolean = false

  relationshipAdviser = ''

  @Default('annually')
  frequency = '' // to determine the calculation of the financial needs analysis calculation, either "monthly" or "annually"

  client0DeclarationPoliticallyExposed = '' // Client 1 declaration political exposed
  client1DeclarationPoliticallyExposed = '' // Client 2 declaration political exposed

  client0AccreditedInvestor = '' // Client 1 accredited investor
  client1AccreditedInvestor = '' // Client 2 accredited investor

  @Default([])
  pepList?: PoliticalExposedPerson[] =[]

  isInsurenceProductsAllowed: Boolean
  isInvestmentsProductsAllowed: Boolean

  afterExtractData (data: { pepList: any[] }) {
    // Formatting pep list
    const pepList = (!data.pepList.length || Object.keys(data.pepList)) ? [] : data.pepList
    data.pepList = pepList.map((rawPep) => {
      const pep = new PoliticalExposedPerson()
      Object.assign(pep, pep.extractData(rawPep))
      return pep
    })

    this.autosetSuggestedReviewDate()
    return data
  }

  // to get back the raw data for saving to backend
  convertToRawData () {
    this.autosetSuggestedReviewDate()
    const result: any = super.convertToRawData()
    const rawPepList: {}[] = []
    this.pepList.forEach((pep) => {
      rawPepList.push(pep.convertToRawData())
    })
    result.pep_list = rawPepList // eslint-disable-line @typescript-eslint/camelcase
    return result
  }

  autosetSuggestedReviewDate () {
    if (!this.suggestedNextFinancialUpdate && this.reviewDate) {
      const dateService = container.get<IDateService>(cid.IDateService)
      const d = dateService.add(this.reviewDate, 'year', 1)
      this.suggestedNextFinancialUpdate = d.valueOf()
    }
  }
}
