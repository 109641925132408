import { RouteRecordRaw } from 'vue-router'
 
export const groupsRouter = ((): RouteRecordRaw[] => {
  return [
    {
      path: '/groups',
      name: 'Groups',
      component: () => import('@core/pages/groups/Groups.vue'),
      // meta: {
      //   subject: ['group']
      // }
    },
    {
      path: '/groups/create',
      name: 'Create Group',
      component: () => import('@core/pages/groups/CreateGroup.vue'),
      // meta: {
      //   subject: ['group']
      // }
    },
    {
      path: '/groups/:goid/edit',
      name: 'Edit Group',
      component: () => import('@core/pages/groups/EditGroup.vue'),
      // meta: {
      //   subject: ['group']
      // }
    }]
})()