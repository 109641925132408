import { SwitchingReplacement } from '@core/model/kyc-form/SwitchingReplacement';
import { Base } from '../Base';

export class SwitchReplaceAdditionalData extends Base {
    switchReplaceAdditionalData:SwitchingReplacement

    constructor(){
        super()
        this.switchReplaceAdditionalData = null
    }
}