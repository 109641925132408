import { ClientBase } from '../../ClientBase'
import { Ignore } from '@master/annotation/Ignore'
import { Default } from '@master/annotation/Default'
import { sum, valueIsUndefined } from '@master/model/kyc-form/need-analysis/math'
import { MaxChar } from '@master/annotation/MaxChar'
import { InputMaxChar } from '@master/enum/MaxCharsEnum'

/**
 * When declaring CashFlow, do note that if frequency
 * is not provided, it will default all values based on
 * annually
 */
export default class CashFlow extends ClientBase {
  isUserDeclinedCashFlow = false
  declineRemark = ''

  isSimplifiedMode

  cashFlowPlanning = ''

  @MaxChar(InputMaxChar.MEDIUM)
  cashFlowPlanningReasons = ''

  netEmploymentIncome

  business

  mortgageLoan

  carLoan

  lifestyleExpenses

  investmentIncome

  dividendIncome

  rentalIncome

  bonus

  otherIncome

  @MaxChar(InputMaxChar.XXSMALL)
  others10Text = ''

  @Default(0)
  others10 = ''

  @MaxChar(InputMaxChar.XXSMALL)
  others11Text = ''

  @Default(0)
  others11 = ''

  @Default(0)
  loanRepayment = ''

  @Default(0)
  householdExpenses = ''

  @Default(0)
  parentalSupport = ''

  // @Default(0)
  // education = ''

  @Default(0)
  personalExpenses = ''

  @Default(0)
  insurancePremiums = ''

  @Default(0)
  regularSavings = ''

  @MaxChar(InputMaxChar.XXSMALL)
  others12Text = ''

  @Default(0)
  others12 = ''

  @MaxChar(InputMaxChar.XXSMALL)
  others13Text = ''

  @Default(0)
  others13 = ''

  @MaxChar(InputMaxChar.XXSMALL)
  others14Text = ''

  @Default(0)
  others14 = ''

  @Default(37740)
  cpfAnnInflowAmt

  cashFlowChanges = ''

  @MaxChar(InputMaxChar.MEDIUM)
  cashFlowChangesReasons = ''

  @MaxChar(InputMaxChar.XXXLARGE)
  additionalNotes = '' // previously was your_finances_additional_notes

  // Summary - I do not wish to fully disclose my cash flow information
  discloseCashFlowInfo: boolean | null = null

  // Summary - Any changes to your income and expenditure
  significantFactorsNext12Months: boolean | null = null

  // Summary - Any changes to your income and expenditure - Please provide details
  @MaxChar(InputMaxChar.XXXLARGE)
  significantFactorsDetails = ''

  // Summary - Justification - Not disclosing expenses
  @MaxChar(InputMaxChar.XXXLARGE)
  totalOutflowZeroJustification = ''

  // Summary - Justification - Not entering value to all CPF Inflow Fields
  @MaxChar(InputMaxChar.XXXLARGE)
  cpfInflowIncompleteJustification = ''

  // Summary - Justification - Sum of CPF Amount Disclosed Exceeds Stipulated CPF Guidelines
  @MaxChar(InputMaxChar.XXXLARGE)
  cpfInflowExceedGuidelinesJustification = ''

  // Not to be stored variables
  @Ignore()
  frequency = '' // to determine the calculation of the financial needs analysis calculation, either 'monthly' or 'annually'

  // Summary - Total Monthly Inflow
  get totalIncome () { 
    return sum(this.netEmploymentIncome, this.bonus, this.dividendIncome, this.rentalIncome, this.otherIncome) || 0
  }

  get totalIncomeForSummary () { 
    return sum(this.netEmploymentIncome, this.bonus, this.dividendIncome, this.rentalIncome, this.otherIncome)
  }

  utilities

  groceries

  propertyTax

  cashMortgage

  maidServices

  householdOthersDescription = ''

  householdOthersAmount

  get totalHouseholdExpenses () {
    return sum(this.utilities,this.groceries,this.propertyTax,this.cashMortgage,this.maidServices,this.householdOthersAmount) || 0
  }

  get totalHouseholdExpensesForSummary () {
    return sum(this.utilities,this.groceries,this.propertyTax,this.cashMortgage,this.maidServices,this.householdOthersAmount)
  }

  toSummaryJson() {
    return {
      discloseCashFlowInfo: this.discloseCashFlowInfo,
      significantFactorsNext12Months: this.significantFactorsNext12Months,
      significantFactorsDetails: this.significantFactorsDetails,
      totalOutflowZeroJustification: this.totalOutflowZeroJustification,
      cpfInflowIncompleteJustification: this.cpfInflowIncompleteJustification,
      cpfInflowExceedGuidelinesJustification: this.cpfInflowExceedGuidelinesJustification,
      additionalNotes: this.additionalNotes,
    };
  }

  toHouseholdJson() {
    return {
      groceries: this.groceries,
      utilities: this.utilities,
      propertyTax: this.propertyTax,
      cashMortgage: this.cashMortgage,
      maidServices: this.maidServices,
      householdOthersAmount: this.householdOthersAmount,
      householdOthersDescription: this.householdOthersDescription,
      totalHouseholdExpenses: this.totalHouseholdExpenses
    };
  }

  foodDiningExpense

  vacation

  mobileInternetCableExpense

  entertainment

  groomingShoppingExpense

  incomeTax

  personalOthersDescription = ''

  personalOthersAmount

  get totalPersonalExpenses () {
    return sum(this.vacation,this.incomeTax,this.personalOthersAmount,this.entertainment,this.foodDiningExpense,this.mobileInternetCableExpense,this.groomingShoppingExpense) || 0
  }

  get totalPersonalExpensesForSummary () {
    return sum(this.vacation,this.incomeTax,this.personalOthersAmount,this.entertainment,this.foodDiningExpense,this.mobileInternetCableExpense,this.groomingShoppingExpense)
  }

  toPersonalJson() {
    return {
      vacation: this.vacation,
      incomeTax: this.incomeTax,
      personalOthersAmount: this.personalOthersAmount,
      entertainment: this.entertainment,
      groomingShoppingExpense: this.groomingShoppingExpense,
      foodDiningExpense: this.foodDiningExpense,
      personalOthersDescription: this.personalOthersDescription,
      totalPersonalExpenses: this.totalPersonalExpenses,
      mobileInternetCableExpense: this.mobileInternetCableExpense
    };
  }

  medical

  schoolFees

  parentsAllowances

  childrenAllowances

  dependantsOthersDescription = ''

  dependantsOthersAmount

  get totalDependantExpenses () {
    return sum(this.medical,this.schoolFees,this.dependantsOthersAmount,this.parentsAllowances,this.childrenAllowances) || 0
  }

  get totalDependantExpensesForSummary () {
    return sum(this.medical,this.schoolFees,this.dependantsOthersAmount,this.parentsAllowances,this.childrenAllowances)
  }

  toDependantJson() {
    return {
      medical: this.medical,
      schoolFees: this.schoolFees,
      parentsAllowances: this.parentsAllowances,
      childrenAllowances: this.childrenAllowances,
      dependantsOthersAmount: this.dependantsOthersAmount,
      dependantsOthersDescription: this.dependantsOthersDescription,
      totalDependantExpenses: this.totalDependantExpenses,
    };
  }

  carInsurance

  publicTransport

  parkingPetrolExpense

  transportationOthersDescription1 = ''

  transportationOthersDescription2 = ''

  transportationOthersAmount1

  transportationOthersAmount2

  roadTaxCarServicingExpense

  get totalTransportationExpenses () {
    return sum(this.carLoan,this.carInsurance,this.transportationOthersAmount1,this.transportationOthersAmount2,this.publicTransport,this.parkingPetrolExpense, this.roadTaxCarServicingExpense) || 0
  }

  get totalTransportationExpensesForSummary () {
    return sum(this.carLoan,this.carInsurance,this.transportationOthersAmount1,this.transportationOthersAmount2,this.publicTransport,this.parkingPetrolExpense, this.roadTaxCarServicingExpense)
  }

  toTransportationJson() {
    return {
      carLoan: this.carLoan,
      carInsurance: this.carInsurance,
      transportationOthersAmount1: this.transportationOthersAmount1,
      transportationOthersAmount2: this.transportationOthersAmount2,
      publicTransport: this.publicTransport,
      parkingPetrolExpense: this.parkingPetrolExpense,
      transportationOthersDescription1: this.transportationOthersDescription1,
      transportationOthersDescription2: this.transportationOthersDescription2,
      roadTaxCarServicingExpense: this.roadTaxCarServicingExpense,
      totalTransportationExpenses: this.totalTransportationExpenses,
    };
  }

  parentPremium

  childrenPremium

  personalPremium

  insuranceCashPremiumDescription = ''

  insuranceCashPremiumAmount

  get totalInsuranceCashPremiumExpenses () {
    return sum(this.parentPremium,this.childrenPremium,this.personalPremium, this.insuranceCashPremiumAmount) || 0
  }
  
  get totalInsuranceCashPremiumExpensesForSummary () {
    return sum(this.parentPremium,this.childrenPremium,this.personalPremium, this.insuranceCashPremiumAmount)
  }

  toInsuranceCashPremiumJson() {
    return {
      parentPremium: this.parentPremium,
      childrenPremium: this.childrenPremium,
      personalPremium: this.personalPremium,
      insuranceCashPremiumDescription: this.insuranceCashPremiumDescription,
      insuranceCashPremiumAmount: this.insuranceCashPremiumAmount,
      totalInsuranceCashPremiums: this.totalInsuranceCashPremiumExpenses,
    };
  }

  endowment

  unitTrusts

  SharesStocks

  regularInvestmentCashDescription = ''

  regularInvestmentCashAmount

  get totalRegularInvestmentCashExpenses () {
    return sum(this.endowment,this.unitTrusts,this.SharesStocks, this.regularInvestmentCashAmount) || 0
  }

  get totalRegularInvestmentCashExpensesForSummary () {
    return sum(this.endowment,this.unitTrusts,this.SharesStocks, this.regularInvestmentCashAmount)
  }

  toRegularInvestmentCashJson() {
    return {
      endowment: this.endowment,
      unitTrusts: this.unitTrusts,
      SharesStocks: this.SharesStocks,
      regularInvestmentCashAmount:this.regularInvestmentCashAmount,
      regularInvestmentCashDescription: this.regularInvestmentCashDescription,
      totalRegularInvestmentCash: this.totalRegularInvestmentCashExpenses,
    };
  }

  specialAccount
  
  totalCPFInflow

  medisaveAccount

  ordinaryAccount

  // Total CPF Inflow
  get totalCpfInflowEnpenses() {
    return sum(this.specialAccount, this.medisaveAccount, this.ordinaryAccount) || 0
  }

  get totalCpfInflowEnpensesForSummary() {
    return sum(this.specialAccount, this.medisaveAccount, this.ordinaryAccount)
  }

  toCpfInflowJson() {
    return {
      specialAccount: this.specialAccount,
      medisaveAccount: this.medisaveAccount,
      ordinaryAccount: this.ordinaryAccount,
      totalCPFInflow: this.totalCpfInflowEnpenses,
    };
  }

  maOutflow

  oaMortgage

  oaRegularInvestments

  saRegularInvestments

  // Total CPF Outflow
  get totalCpfOutflowEnpenses() {
    return sum(this.oaMortgage, this.oaRegularInvestments, this.saRegularInvestments, this.maOutflow) || 0
  }

  get totalCpfOutflowEnpensesForSummary() {
    return sum(this.oaMortgage, this.oaRegularInvestments, this.saRegularInvestments, this.maOutflow)
  }

  toCpfOutflowJson() {
    return {
      maOutflow: this.maOutflow,
      oaMortgage: this.oaMortgage,
      oaRegularInvestments: this.oaRegularInvestments,
      saRegularInvestments: this.saRegularInvestments,
      totalCPFOutflow: this.totalCpfOutflowEnpenses
    };
  }

  financialMiscellaneousAmount1

  financialMiscellaneousAmount2

  loansAmount

  miscellaneousLoanDescription: ''

  charityOrTithes

  financialMiscellaneousDescription1 = ''
  
  financialMiscellaneousDescription2 = ''
  
  get totalMiscellaneousExpenses () {
    return sum(this.financialMiscellaneousAmount1, this.financialMiscellaneousAmount2, this.loansAmount, this.charityOrTithes) || 0
  }

  get totalMiscellaneousExpensesForSummary () {
    return sum(this.financialMiscellaneousAmount1, this.financialMiscellaneousAmount2, this.loansAmount, this.charityOrTithes)
  }

  toMiscellaneousJson() {
    return {
      financialMiscellaneousAmount1: this.financialMiscellaneousAmount1,
      financialMiscellaneousAmount2: this.financialMiscellaneousAmount2,
      financialMiscellaneousDescription1: this.financialMiscellaneousDescription1,
      financialMiscellaneousDescription2: this.financialMiscellaneousDescription2,
      loansAmount: this.loansAmount,
      miscellaneousLoanDescription: this.miscellaneousLoanDescription,
      charityOrTithes: this.charityOrTithes,
      totalMiscellaneousExpenses: this.totalMiscellaneousExpenses,
    };
  }

  // Simplified - Total Inflow
  simplifiedTotalInflow

  // Simplified - Total Outflow
  simplifiedTotalOutflow
  
  // Simplified - Summary - Total Monthly Cashflow
  get simplifiedTotalMonthlyCashflow() {
    if (valueIsUndefined(this.simplifiedTotalInflow) && valueIsUndefined(this.simplifiedTotalOutflow)) return null
    return sum(this.simplifiedTotalInflow, this.simplifiedTotalOutflow * -1);
  }

  // Simplified - Summary - Total Annual Cashflow
  get simplifiedTotalAnnualCashflow() {
    if (this.simplifiedTotalMonthlyCashflow === null) return null
    return this.simplifiedTotalMonthlyCashflow * 12;
  }

  /**
   * Although in the case of annually both
   * totalIncome and totalAnnualIncome will return the same
   * value, this is for scenarios when the frequency is monthly
   */
  get totalAnnualIncome () {
    if (this.totalIncome === null) return null
    return this.totalIncome * 12
  }
  
  get totalExpense() { // was totalAnnualExpenses
    return sum(this.totalPersonalExpenses, 
      this.totalHouseholdExpenses, 
      this.totalDependantExpenses, 
      this.totalInsuranceCashPremiumExpenses, 
      this.totalTransportationExpenses, 
      this.totalRegularInvestmentCashExpenses) || 0;
  }

  // Summary - Total Monthly Outflow
  get totalMonthlyOutflow() {
    return sum(this.totalPersonalExpenses, 
      this.totalHouseholdExpenses, 
      this.totalDependantExpenses, 
      this.totalInsuranceCashPremiumExpenses, 
      this.totalTransportationExpenses, 
      this.totalRegularInvestmentCashExpenses,
      this.totalMiscellaneousExpenses) || 0;
  }

  get totalMonthlyOutflowForSummary() {
    return sum(this.totalPersonalExpensesForSummary, 
      this.totalHouseholdExpensesForSummary, 
      this.totalDependantExpensesForSummary, 
      this.totalInsuranceCashPremiumExpensesForSummary, 
      this.totalTransportationExpensesForSummary, 
      this.totalRegularInvestmentCashExpensesForSummary,
      this.totalMiscellaneousExpensesForSummary);
  }

  // Summary - CPF
  get totalCPF() {
    if (valueIsUndefined(this.totalCpfInflowEnpenses) && valueIsUndefined(this.totalCpfOutflowEnpenses)) return null
    return sum(this.totalCpfInflowEnpenses, this.totalCpfOutflowEnpenses * -1);
  }

  get totalCPFForSummary() {
    if (valueIsUndefined(this.totalCpfInflowEnpensesForSummary) && valueIsUndefined(this.totalCpfOutflowEnpensesForSummary)) return null
    return sum(this.totalCpfInflowEnpensesForSummary, this.totalCpfOutflowEnpensesForSummary * -1);
  }

  // Summary - Total Monthly Cashflow
  get totalMonthlyCashflow() {
    if (valueIsUndefined(this.totalIncome) && valueIsUndefined(this.totalMonthlyOutflow)) return null
    return sum(this.totalIncome, this.totalMonthlyOutflow * -1);
  }

  get totalMonthlyCashflowForSummary() {
    if (valueIsUndefined(this.totalIncomeForSummary) && valueIsUndefined(this.totalMonthlyOutflowForSummary)) return null
    return sum(this.totalIncome, this.totalMonthlyOutflow * -1);
  }

  // Summary - Total Annual Cashflow
  get totalAnnualCashflow() {
    if (this.totalMonthlyCashflow === null) return null
    return this.totalMonthlyCashflow * 12;
  }

  get totalAnnualCashflowForSummary() {
    if (this.totalMonthlyCashflowForSummary === null) return null
    return this.totalMonthlyCashflow * 12;
  }

  get totalAnnualCPFInflow() {
    if (this.totalCpfInflowEnpenses === null) return null
    return this.totalCpfInflowEnpenses * 12;
  }

  /**
   * Although in the case of annually both
   * totalExpenses and totalAnnualExpenses will return the same
   * value, this is for scenarios when the frequency is monthly
   */
  get totalAnnualExpenses () {
    if (this.totalExpense === null) return null
    return this.totalExpense * 12
  }

  /**
   * When frequency is annually, calling surplusShortfall
   * and surplusAnnualShortfall is the same. In the case
   * of monthly, surplusShortfall will return the monthly
   * shortfall
   */
  get surplusShortfall () {
    if (valueIsUndefined(this.totalIncome) && valueIsUndefined(this.totalExpense)) return null
    return sum(this.totalIncome, -this.totalExpense)
  }

  get surplusAnnualShortfall () {
    if (valueIsUndefined(this.totalAnnualIncome) && valueIsUndefined(this.totalAnnualExpenses)) return null
    return sum(this.totalAnnualIncome, -this.totalAnnualExpenses)
  }

  constructor (type: 'client_0' | 'client_1' = 'client_0', frequency: 'annually' | 'monthly' = 'monthly') {
    super(type)
    this.frequency = frequency;
  }

  /**
   * Depending on the customer's needs we will change the default the frequency
   * accordingly to what is required
   * @param data
   */
  convertToRawData () {
    return {
      ...super.convertToRawData()
    }
  }

  toInflowJson() {
    return {
      bonus: this.bonus,
      otherIncome: this.otherIncome,
      rentalIncome: this.rentalIncome,
      dividendIncome: this.dividendIncome,
      totalMonthlyInflow: this.totalIncome,
      netEmploymentIncome: this.netEmploymentIncome,
    };
  }

  toOutflowJson() {
    return {
      personalJson: this.toPersonalJson(),
      householdJson: this.toHouseholdJson(),
      dependantsJson: this.toDependantJson(),
      transportationJson: this.toTransportationJson(),
      insuranceCashPremiumJson: this.toInsuranceCashPremiumJson(),
      regularInvestmentCashJson: this.toRegularInvestmentCashJson(),
      totalMonthlyOutflow: this.totalExpense,
    };
  }

  toJSON() {
    return {
      inflowComprehensiveJson: this.toInflowJson(),
      outflowComprehensiveJson: this.toOutflowJson(),
      cpfInflowComprehensiveJson: this.toCpfInflowJson(),
      cpfOutflowComprehensiveJson: this.toCpfOutflowJson(),
      miscellaneousComprehensiveJson: this.toMiscellaneousJson(),
      summaryComprehensiveJson: this.toSummaryJson(),
      isUserDeclinedCashFlow: this.isUserDeclinedCashFlow,
      declineRemark: this.declineRemark,
      isSimplifiedMode: this.isSimplifiedMode
    };
  }

  toSimplifiedJSON() {
    return {
      simplifiedTotalInflow: this.simplifiedTotalInflow,
      simplifiedTotalOutflow: this.simplifiedTotalOutflow,
      cpfInflowComprehensiveJson: this.toCpfInflowJson(),
      cpfOutflowComprehensiveJson: this.toCpfOutflowJson(),
      summaryComprehensiveJson: this.toSummaryJson(),
      isUserDeclinedCashFlow: this.isUserDeclinedCashFlow,
      declineRemark: this.declineRemark,
      isSimplifiedMode: this.isSimplifiedMode
    };
  }

  setData(newData) {
    for (const key in newData) {
      if (this.hasOwnProperty(key)) { 
        this[key] = newData[key];
      }
    }
  }
}
