import { RouteRecordRaw, createRouter, createWebHistory } from 'vue-router'
import { compensationRoutes } from '@/modules/compensationSetup/router'
import { designationRoutes } from '@core/pages/designation/route'
import KYCClient from '@company-common/model/kyc-form/KYCClient'
import { clientFormLoader } from '@core/components/ClientFormWrapper'
import { routerRedirect } from '@core/logic/RouterRedirect'
import { FormsList } from '@core/model/recruit/FormsList'
import { commissionModuleRouter } from '@core/pages/CommissionModule/route'
import { ClientManagementRoutes } from '@core/pages/clientManagement-v2/router'
// import Vue from 'vue'
// import VueRouter, { RouteRecordRaw, createRouter, createWebHistory } from 'vue-router'
import store from '../store/index'
import { cpdRoutes } from './cpd/CpdRoutes'
import { newBusinessRegisterRouter } from './new-business-register/NewBusinessRegisterRoutes'
import { scheduleRoutes } from './schedule/ScheduleRoute'
import { userProfileRoutes } from '@core/pages/user-profile/route'
import { approvalsRouter } from './approvals/ApprovalRoutes'
import { commissionRouter } from '@core/pages/commission/route'
import { groupsRouter } from './groups/GroupRoutes'
import { efnaConfigRouter } from '@core/pages/tenant/route/index'
import { ALL_PERMISSION_TYPE } from "@core/utils/global.json"
// \\wsl.localhost\Ubuntu\home\aparna\frontend\src\components\Test\TestComponent.vue
// Vue.use(VueRouter)

// Wrapper for type check
function kycClientFormLoader(title: string, key: keyof KYCClient, componentPromise: any) {
  // function kycClientFormLoader (title: string, key: keyof KYCClient, componentPromise: Promise<typeof import('*.vue')>) {
  return clientFormLoader(title, key, componentPromise)
}

const routes: Array<any> = [
  {
    path: '/',
    redirect: 'login'
  },
  {
    path: '/error',
    name: 'Error',
    component: () => import('@core/pages/error/ErrorPage.vue'),
    meta: {
      title: 'Error Page'
    }
  },
  {
    path: '/data-load',
    name: 'data-load',
    component: () => import('@core/pages/data-load/DataLoadList.vue'),
    meta: {
      subject: ['company']
    }
  },
  {
    path: '/login/',
    name: 'Login',
    component: () => import('@core/pages/OktaLogin.vue'),
    meta: {
      title: 'Login'
    }
  },
  {
    path: '/authorization-code/callback',
    name: 'Authorization',
    component: () => import('@core/pages/OktaAuthorization.vue'),
    meta: {
      title: 'Authorization'
    }
  },
  {
    path: '/reset/:authToken',
    name: 'Reset',
    component: () => import('@core/pages/Reset.vue'),
    meta: {
      title: 'Reset Password'
    }
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import('@core/pages/Home.vue'),
    meta: {
      title: 'Home'
    }
  },
  {
    path: '/landing-home',
    name: 'Landing',
    component: () => import('@core/pages/Landing/Landing.vue'),
    meta: {
      title: 'Home'
    }
  },
  {
    path: '/kyc-pdf/:kycPdfOid',
    name: 'KYCPdf',
    component: () => import('../views/kyc-pdf/KycPdf.vue'),
    meta: {
      title: 'KYC Pdf',
      subject: ['kycPdf', 'ifamFileSystem']
    }
  },
  {
    path: '/kyc-form/:formOid',
    name: 'KYCForm',
    redirect: to => {
      const formOid = to.params.formOid
      return `kyc-form/${formOid}/important-notices`
    },
    component: () => import('../views/kyc-form/KYCForm.vue'),
    meta: {
      title: 'KYC Form',
      subject: ['kycForm', 'kycPdf']
    },
    // Note: Order of this Route should be according to Side Bar Menu to work top navigation buttons
    children: [
      {
        path: 'important-notices',
        name: 'Important Notices',
        component: () => import('../views/kyc-form/section/ImportantNoticesView.vue')
      },
      {
        path: 'about-you',
        name: 'About You',
        redirect: { name: 'Personal Details' },
        component: () => import('../views/kyc-form/section/about-you/ThePersonalDetails.vue')
      },
      {
        path: 'about-you/personal-details',
        name: 'Personal Details',
        component: () => import('../views/kyc-form/section/about-you/ThePersonalDetails.vue')
      },
      {
        path: 'about-you/trusted-individual',
        name: 'Trusted Individual',
        component: () => import('../views/kyc-form/section/about-you/TrustedIndividualView.vue')
      },
      {
        path: 'about-you/dependants',
        name: 'Dependants',
        component: () => import('../views/kyc-form/section/about-you/DependantsView.vue')
      },
      {
        path: 'financial-objectives',
        name: 'Financial Objectives',
        component: () => import('../views/kyc-form/section/FinancialObjectives.vue')
      },
      {
        path: 'your-finances',
        redirect: { name: 'Inflow & Outflow' },
        name: 'Your Finances',
        component: () => import('../views/kyc-form/section/your-finances/CashFlowView.vue')
      },
      {
        path: 'your-finances/cash-flow',
        name: 'Inflow & Outflow',
        component: () => import('../views/kyc-form/section/your-finances/CashFlowView.vue')
      },

      {
        path: 'your-finances/net-worth',
        name: 'Assets & Liabilities',
        component: () => import('../views/kyc-form/section/your-finances/NetWorthView.vue')
      },
      // {
      //   path: 'your-finances/budget',
      //   name: 'Budget',
      //   component: () => import('../views/kyc-form/section/your-finances/BudgetView.vue')
      // },
      // {
      //   path: 'current-portfolio',
      //   redirect: { name: 'Existing Insurance'},
      //   name: 'Current Portfolio',
      //   component: () => import('../views/kyc-form/section/current-plans/insurance-portfolio/YourCurrentPlansPage.vue')
      // },
      // {
      //   path: 'current-portfolio/insurance',
      //   name: 'Existing Insurance',
      //   component: () => import('../views/kyc-form/section/current-plans/insurance-portfolio/YourCurrentPlansPage.vue')
      // },
      // {
      //   path: 'current-portfolio/investment',
      //   name: 'Existing Investments',
      //   component: () => import('../views/kyc-form/section/current-plans/investment-portfolio/YourCurrentInvestPage.vue')
      // },
      {
        path: 'current-plans',
        redirect: { name: 'Existing Investments' },
        name: 'Current Plans',
        component: () => import('../views/kyc-form/section/current-plans/TheExistingInvestmentPage.vue')
      },
      {
        path: 'current-plans/investment',
        name: 'Existing Investments',
        component: () => import('../views/kyc-form/section/current-plans/TheExistingInvestmentPage.vue')
      },
      {
        path: 'current-plans/insurance',
        name: 'Existing Insurance',
        component: () => import('../views/kyc-form/section/current-plans/existing-insurance/ExistingInsurancePage.vue')
      },
      {
        path: 'investment-risk-profile',
        redirect: { name: 'Investment Risk Profile' },
        name: 'Risk Profile',
        component: () => import('../views/kyc-form/section/investment-risk-profile/RiskProfileAssessmentView.vue')
      },
      {
        path: 'investment-risk-profile/risk-profile-assessment',
        name: 'Investment Risk Profile',
        component: () => import('../views/kyc-form/section/investment-risk-profile/RiskProfileAssessmentView.vue')
      },
      {
        path: 'investment-risk-profile/customer-knowledge-assessment',
        name: 'Customer Knowledge Assessment',
        component: () => import('../views/kyc-form/section/investment-risk-profile/CustomerKnowledgeAssessmentView.vue')
      },
      {
        path: 'needs-analysis',
        name: 'Needs Analysis',
        component: () => import('../views/kyc-form/section/needs-analysis/NeedsAnalysis.vue')
      },
      {
        path: 'affordability',
        name: 'Affordability',
        component: () => import('../views/kyc-form/section/Affordability.vue')
      },
      {
        path: 'client-summary',
        name: 'Client Summary',
        component: () => import('../views/kyc-form/section/client-summary/ClientSummary.vue')
      },
      // {
      //   path: 'needs-analysis-old',
      //   redirect: { name: 'Adjustments & Assumptions'},
      //   name: 'Your Priorities & Needs Analysis',
      //   component: () => import('../views/kyc-form/section/needs-analysis/AdjustmentsAndAssumptions.vue')
      // },
      // {
      //   path: 'needs-analysis-old/identifying-your-priorities',
      //   name: 'Identifying Your Priorities',
      //   component: () => import('../views/kyc-form/section/needs-analysis/IdentifyingYourPrioritiesView.vue')
      // },
      // {
      //   path: 'needs-analysis-old/needs-analysis-calculation',
      //   name: 'Needs Analysis Calculation',
      //   component: () => import('../views/kyc-form/section/needs-analysis/NeedsAnalysisCalculation.vue')
      // },
      // {
      //   path: 'needs-analysis-old/adjustments-and-assumptions',
      //   name: 'Adjustments & Assumptions',
      //   component: () => import('../views/kyc-form/section/needs-analysis/AdjustmentsAndAssumptions.vue')
      // },
      // Our Recommendations
      {
        path: 'our-recommendation',
        redirect: { name: 'Insurance Products Recommendation' },
        name: 'Our Recommendation',
        component: () => import('../views/kyc-form/section/recommendations/insurance-product/TheProductRecommendations.vue')
      },
      {
        path: 'our-recommendation/insurance-product-recommendation',
        name: 'Insurance Products Recommendation',
        component: () => import('../views/kyc-form/section/recommendations/insurance-product/TheProductRecommendations.vue')
      },
      {
        path: 'our-recommendation/ilp-sub-funds',
        name: 'For Investments - ILP/Sub funds',
        component: () => import('../views/kyc-form/section/recommendations/TheILPSubfunds.vue')
      },
      {
        path: 'our-recommendation/basis-of-recommendation',
        name: 'Basis of Recommendation',
        component: () => import('../views/kyc-form/section/recommendations/TheBasisOfRecommendation.vue')
      },
      // {
      //   path: 'our-recommendation/client-decision',
      //   name: 'Client\'s Decision',
      //   component: () => import('../views/kyc-form/section/recommendations/TheClientDecision.vue')
      // },
      {
        path: 'our-recommendation/replacement-or-switching',
        name: 'Replacement / Switching',
        // component: () => import('../views/kyc-form/section/ReplacementOrSwitching.vue')
        component: () => import('../views/kyc-form/section/ReplacementOrSwitching.vue')
      },
      // {
      //   path: 'disclaimers-and-client-checklist',
      //   name: 'Disclaimers & Client\'s Checklist',
      //   component: () => import('../views/kyc-form/section/DisclaimersAndClientChecklist.vue')
      // },
      {
        path: 'client-declaration',
        name: 'Client\'s Declaration',
        component: () => import('../views/kyc-form/section/ClientDeclaration.vue')
      },
      // {
      //   path: 'financial-consultant-declaration',
      //   name: 'Financial Adviser\'s Declaration',
      //   component: () => import('../views/kyc-form/section/FinancialConsultantDeclaration.vue')
      // },
      // {
      //   path: 'kyc-confirmation',
      //   name: 'KYC Confirmation',
      //   component: () => import('../views/kyc-form/section/kyc-confirmation/KYCConfirmation.vue')
      // },
      {
        path: 'bop',
        name: 'Additional Forms',
        component: () => import('../views/kyc-form/section/ECDD.vue')
      },
      // {
      //   path: 'pep',
      //   name: 'Politically Exposed Person Form',
      //   component: () => import('../views/kyc-form/section/PEP.vue')
      // },
      // {
      //   path: 'aml',
      //   name: 'Client\'s AML Check',
      //   component: () => import('../views/kyc-form/section/AML.vue')
      // },
      {
        path: 'generate-sign-pdf',
        name: 'Generate & Sign PDF',
        component: () => import('../views/kyc-form/section/GenerateSignPdf.vue')
      },
      // {
      //   path: 'appendix',
      //   name: 'Appendix - Upload PDFs',
      //   component: () => import('../views/kyc-form/section/UploadDocument.vue')
      // }
      {
        path: 'appendix',
        name: 'Appendix - Upload PDFs',
        component: () => import('../views/kyc-form/section/ProdAppUploadDocument.vue')
      }
    ]
  },
  {
    path: '/client-list/',
    name: 'client-list',
    component: () => import('@core/pages/ClientList.vue'),
    meta: {
      title: 'Client List',
      subject: ['client', 'clientRelationship']
    }
  },
  {
    path: '/rep-list/',
    name: 'Rep List',
    component: () => import('@core/pages/adviser-team-management/pages/MyRepListPage.vue'),
    meta: {
      title: 'Rep List'
    }
  },
  {
    path: '/client-management',
    name: 'client-management',
    component: () => import('@core/pages/client-management/views/v2/ClientProductListPage.vue'),
    meta: {
      subject: ['client', 'clientRelationship']
    }
  },
  {
    path: '/client-info/:clientOid',
    name: 'client-info',
    redirect: to => {
      const clientOid = to.params.clientOid
      return `/client-info/${clientOid}/profile`
    },
    component: () => import('@core/pages/clientManagement-v2/views/ClientInfo.vue'),
    meta: {
      subject: ['client', 'clientRelationship']
    },
    children: ClientManagementRoutes
  },
  // {
  //   path: '/landing-home/',
  //   name: 'landing-home',
  //   component: () => import('@core/pages/Landing/Landing.vue'),
  //   meta: {
  //     title: 'Home'
  //   }
  // },
  {
    path: '/home-adviser-old/',
    name: 'home-adviser-old',
    component: () => import('../views/HomeAdviser.vue'),
    meta: {
      title: 'Home Adviser',
      subject: ['kycForm', 'kycPdf']
    }
  },
  {
    path: '/home-adviser',
    name: 'home-adviser',
    component: () => import('@core/pages/efna-dashboard/Adviser.vue'),
    meta: {
      title: 'Home Adviser',
      subject: ['kycForm', 'kycPdf']
    }
  },
  {
    path: '/home-manager',
    name: 'home-manager',
    component: () => import('@core/pages/efna-dashboard/Manager.vue'),
    meta: {
      title: 'Home Manager',
      subject: ['kycForm', 'kycPdf']
    }
  },
  {
    path: '/home-business-support',
    name: 'home-business-support',
    component: () => import('@core/pages/efna-dashboard/BusinessSupport.vue'),
    meta: {
      title: 'Home Business Support',
      subject: ['kycForm', 'kycPdf']
    }
  },
  {
    path: '/home-business-assurance',
    name: 'home-business-assurance',
    component: () => import('@core/pages/efna-dashboard/BusinessAssurance.vue'),
    meta: {
      title: 'Home Business Assurance',
      subject: ['kycForm', 'kycPdf']
    }
  },
  {
    path: '/home-compliance',
    name: 'home-compliance',
    component: () => import('@core/pages/efna-dashboard/Compliance.vue'),
    meta: {
      title: 'Home Compliance',
      subject: ['kycForm', 'kycPdf']
    }
  },
  {
    path: '/home-submission/',
    name: 'home-submission',
    component: () => import('@core/pages/HomeSubmission.vue'),
    meta: {
      title: 'Home Submission',
      subject: ['kycForm', 'kycPdf']
    }
  },
  {
    path: '/home-supervisor/',
    name: 'home-supervisor',
    component: () => import('@core/pages/HomeSupervisor.vue'),
    meta: {
      title: 'Home Supervisor',
      subject: ['kycForm', 'kycPdf']
    }
  },
  {
    path: '/bsc-home/',
    name: 'bsc-home',
    component: () => import('@core/pages/bsc/BscHome.vue'),
    meta: {
      subject: ['bscSupervisorReview', 'bscUserReviewQuarter', 'bscPolicyInfractions', 'bscReviewQuarter']
    }
  },
  {
    path: '/bsc-setup/',
    name: 'bsc-setup',
    component: () => import('@core/pages/bsc-setup/bscSetup.vue'),
    // meta: {
    //   subject: ['bscSupervisorReview', 'bscUserReviewQuarter', 'bscPolicyInfractions', 'bscReviewQuarter']
    // }
  },
  {
    path: '/bsc-grading/:bscBaseRecordOid',
    name: 'bsc grading',
    component: () => import('@core/pages/bsc-grading/bscGradingRecord.vue'),
    // meta: {
    //   subject: ['bscSupervisorReview', 'bscUserReviewQuarter', 'bscPolicyInfractions', 'bscReviewQuarter']
    // }
  },
  {
    path: '/publish-grade/:bscBaseRecordOid',
    name: 'publish grade',
    component: () => import('@core/pages/bsc-grading/publishGrades.vue'),
    // meta: {
    //   subject: ['bscSupervisorReview', 'bscUserReviewQuarter', 'bscPolicyInfractions', 'bscReviewQuarter']
    // }
  },
  {
    path: '/adviser-grade-view/:type?/:bscBaseRecordOid/:adviserCode/:fromadviserCode?/:directorAdviserCode?/:from?/:beforeFrom?',
    name: 'adviser grade view',
    component: () => import('@core/pages/bsc-grading/components/adviserGradeView.vue'),
    // meta: {
    //   subject: ['bscSupervisorReview', 'bscUserReviewQuarter', 'bscPolicyInfractions', 'bscReviewQuarter']
    // }
  },
  {
    path: '/supervisor-grade-view/:type?/:bscBaseRecordOid/:adviserCode/:fromadviserCode?/:directorAdviserCode?/:from?/:beforeFrom?',
    name: 'supervisor grade view',
    component: () => import('@core/pages/bsc-grading/components/supervisorGradeView.vue'),
    // meta: {
    //   subject: ['bscSupervisorReview', 'bscUserReviewQuarter', 'bscPolicyInfractions', 'bscReviewQuarter']
    // }
  },
  {
    path: '/director-grade-view/:type?/:bscBaseRecordOid/:adviserCode/:fromadviserCode?/:directorAdviserCode?/:from?/:beforeFrom?',
    name: 'director grade view',
    component: () => import('@core/pages/bsc-grading/components/directorGradeView.vue'),
    // meta: {
    //   subject: ['bscSupervisorReview', 'bscUserReviewQuarter', 'bscPolicyInfractions', 'bscReviewQuarter']
    // }
  },
  {
    path: '/grade-preview/:record/:from?/:type?',
    name: 'grade preview',
    component: () => import('@core/pages/bsc-grading/components/gradePreview.vue'),
    // meta: {
    //   subject: ['bscSupervisorReview', 'bscUserReviewQuarter', 'bscPolicyInfractions', 'bscReviewQuarter']
    // }
  },
  // {
  //   path: '/bsc-preview/123',
  //   name: 'bsc preview',
  //   component: () => import('@core/pages/bsc-grading/bscPreview.vue'),
  //   // meta: {
  //   //   subject: ['bscSupervisorReview', 'bscUserReviewQuarter', 'bscPolicyInfractions', 'bscReviewQuarter']
  //   // }
  // },
  {
    path: '/bsc-preview/:originalbaseRecordOid/:bscBaseRecordOid/:tab',
    name: 'bsc preview',
    component: () => import('@core/pages/bsc-grading/bscPreview.vue'),
    // meta: {
    //   subject: ['bscSupervisorReview', 'bscUserReviewQuarter', 'bscPolicyInfractions', 'bscReviewQuarter']
    // }
  },


  {
    path: '/bsc-grading/',
    name: 'bsc-grading-list',
    component: () => import('@core/pages/bsc-grading/bscGrading.vue'),
    // meta: {
    //   subject: ['bscSupervisorReview', 'bscUserReviewQuarter', 'bscPolicyInfractions', 'bscReviewQuarter']
    // }
  },
  {
    path: '/bsc-list-new/:reviewOid',
    name: 'bsc-list-new',
    component: () => import('@core/pages/bsc/BscListNew.vue'),
    meta: {
      subject: ['bscSupervisorReview', 'bscUserReviewQuarter', 'bscPolicyInfractions', 'bscReviewQuarter']
    }
  },
  {
    path: '/bsc-policy-review',
    name: 'bsc-policy-review',
    component: () => import('@core/pages/bsc/pages/BSCPolicyReviewPage.vue')
  },
  {
    path: '/company-codes-list',
    name: 'company-codes-list',
    component: () => import('@core/pages/companyDetails/CompanyCodesList.vue'),
    meta: {
      subject: ['company'],
      ability : [
        {subject : "company", action: "manage" },
        {subject : "company", action: "read_all" }
      ]
    }
  },
  {
    path: '/user-list',
    name: 'user-list',
    component: () => import('@core/pages/account/UserListV2.vue'),
    meta: {
      subject: ['userAccount'],
      ability: {
        subject: "userAccount",
        action: "manage"
      }
      // target: ALL_PERMISSION_TYPE
    }
  },
  {
    path: '/tenant-configuration',
    name: 'tenant-configuration',
    component: () => import('@core/pages/tenant/Configuration.vue'),
    meta: {
      subject: ['userAccount']
    }
  },
  // {
  //   path: '/dashboard-configuration',
  //   name: 'dashboard-configuration',
  //   component: () => import('@core/pages/tenant/Dashboard.vue'),
  //   meta: {
  //     subject: ['userAccount']
  //   }
  // },
  {
    path: '/group-list',
    name: 'group-list',
    component: () => import('@core/pages/branch-manager/BranchManagerList.vue'),
    meta: {
      subject: ['userAccount', 'structure', 'member'],
      ability: [
        { subject: "structure", action: "manage" },
        { subject: "member", action: "manage" },
        { subject: "userAccount", action: "manage" },
      ]
    }
  },
  {
    path: '/group-profile/:groupOid',
    name: 'group-profile',
    component: () => import('@core/pages/account/GroupProfile.vue')
  },
  {
    path: '/retrieve_myinfo',
    name: 'retrieve-my-info',
    component: () => import('@core/pages/myinfo/TheMyInfoView.vue')
  },
  {
    path: '/commission-home',
    name: 'commission-home',
    component: () => import('@core/pages/CommissionModule/CommissionModule.vue')
  },
  {
    path: '/commission-details/:_oid?',
    name: 'commission-details',
    component: () => import('@core/pages/CommissionModule/CommissionDetails.vue')
  },
  // {
  //   path: '/change-log',
  //   name: 'change-log',
  //   component: () => import('@core/pages/change-log/Changelog.vue'),
  //   meta: {
  //     title: 'Change Log'
  //   }
  // },
  {
    path: '/admin-pdfUpload',
    name: 'admin-pdfUpload',
    component: () => import('@core/pages/admin-pdfUpload/AdminPdfUpload.vue')
  },
  {
    path: '/admin-products',
    name: 'Admin Product List',
    component: () => import('@core/pages/product-master/ProductMaster.vue'),
    meta: {
      title: 'Admin Product Master',
      ability: [
        { subject: "productMaster", action: "manage" },
        { subject: "productlist", action: "manage" },
      ]
    }
  },
  {
    path: '/adviser-sales-code-update',
    name: 'adviser-sales-code-update',
    component: () => import('@core/pages/account/AgentCodesMassUpdate.vue'),
    meta: {
      title: 'Adviser Sales Code Update',
      subject: ['adviserCodeMassImport']
    }
  },
  {
    path: '/admin-assumptions',
    name: 'Global Assumptions',
    component: () => import('@core/pages/assumptions/AssumptionsAdmin.vue'),
    meta: {
      title: 'Global Assumptions',
      subject: ['config']
    }
  },
  {
    path: '/payee-list',
    name: 'Payee List',
    component: () => import('@core/pages/intermediary/Payee.vue'),
    meta: {
      title: 'Intermediary List',
      subject: ['payee']
    }
  },
  {
    path: '/manage',
    name: 'Admin',
    component: () => import('@core/pages/Admin.vue'),
    meta: {
      title: 'Manage'
    }
  },
  {
    path: '/resources',
    name: 'Resource Management System',
    component: () => import('@core/pages/resources/Resources.vue'),
    meta: {
      title: 'Resources Management System',
      subject: ['resources']
    }
  },
  {
    path: '/resources-file/:fileOid',
    name: 'Resource File Download',
    component: () => import('@core/pages/resources/OneTimeOpen.vue'),
    meta: {
      title: 'Resources File Download'
    }
  },
  {
    path: '/report-management',
    name: 'Report Dashboard',
    component: () => import('@core/pages/report-management/ReportManagement.vue'),
    meta: {
      title: 'Report Management Dashboard',
      subject: ['reportManagement']
    }
  },
  ...newBusinessRegisterRouter,
  ...scheduleRoutes,
  {
    path: '/home-recruiter/',
    name: 'home-recruiter',
    component: () => import('@core/pages/HomeRecruiter.vue'),
    meta: {
      title: 'Home Recruiter'
    }
  },
  {
    path: '/recruit/:recruitAccountOid',
    name: 'recruit',
    component: () => import('@core/pages/activity/Board.vue'),
    meta: {
      title: 'Recruit Activity'
    }
  },
  {
    path: '/recruit/:recruitAccountOid/forms',
    name: 'recruit-forms',
    redirect: `/recruit/:recruitAccountOid/forms/${FormsList[0]?.path}`,
    component: () => import('@core/pages/recruit/RecruitForms.vue'),
    children: FormsList
  },
  {
    path: '/sales-team-administration/',
    name: 'Sales Team Administration',
    component: () => import('@core/pages/sales-team-administration/Landing.vue')
  },
  ...compensationRoutes,
  ...commissionModuleRouter,
  ...cpdRoutes,
  ...commissionRouter,
  ...designationRoutes,
  ...efnaConfigRouter,
  userProfileRoutes,
  // {
  //   name: 'BSC Record',
  //   path: 'userrecord/:bscOid',
  //   meta: {
  //     parent: 'User BSC Records'
  //   },
  //   shouldHide: true,
  //   component: () => import('@core/pages/bsc/UserBscRecord.vue')
  // },
  {
    path: '/system-rights-administration',
    name: 'System Rights Administration',
    component: () => import('@core/pages/system-rights-administration/SystemRightsAdministration.vue'),
    meta: {
      subject: ['userAccount']
    }
  },
  {
    path: '/disign-panel',
    name: 'Disign Panel',
    component: () => import('@core/pages/disign/DisignHome.vue')
  },
  ...approvalsRouter,
  {
    path: '/audit-log',
    name: 'Audit Log',
    component: () => import('@core/pages/auditLog/components/AuditLog.vue'),
    meta: {
      title: 'Audit Log',
      subject: ['auditLogs']
    }
  },
  {
    path: '/country-master',
    name: 'Country Master',
    component: () => import('@core/pages/country-master/CountryMaster.vue'),
    meta: {
      title: 'Country Master'
    }
  },
  ...groupsRouter,
  {
    // path: '*',
    path: '/:catchAll(.*)',
    component: () => import('@core/pages/error/NotFoundPage.vue')
  },
  {
    path: '/ezsub-callback',
    name: 'EzSubCallback',
    component: () => import('@core/pages/ezSub/Callback.vue')
  },
  {
    path: '/efna/adviser/cases',
    name: 'EfnaAdviser',
    component: () => import('@core/pages/efna-dashboard/Adviser.vue'),
    meta: {
      title: 'Efna Adviser',
      subject: ['kycForm']
    },
  },
  {
    path: '/efna/adviser/expiring-cases',
    name: 'EfnaAdviserExpiringCases',
    component: () => import('@core/pages/efna-dashboard/ExpiringCases.vue'),
    meta: {
      title: 'Efna Adviser Expiring Cases',
      subject: ['kycForm']
    },
  },
  {
    path: '/efna/case/:oid/:dashboard',
    name: 'EfnaViewCase',
    component: () => import('@core/pages/efna-dashboard/ViewCase.vue'),
    meta: {
      title: 'Efna View Cases',
      subject: ['kycForm']
    },
  },
  {
    path: '/efna/adviser/returned-case/:oid',
    name: 'EfnaAdviserReturnedCaseView',
    component: () => import('@core/pages/efna-dashboard/ReturnedCaseView.vue'),
    meta: {
      title: 'Efna Adviser Returned Case View',
      subject: ['kycForm']
    },
  },
  {
    path: '/efna/adviser/returned-case/add-form',
    name: 'AddSupplementaryView',
    component: () => import('@core/pages/efna-dashboard/SupplementaryView.vue'),
    meta: {
      title: 'e-Supplementary View',
      subject: ['kycForm']
    },
  }
]

const routes2: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: 'login'
  },
  {
    path: '/error',
    name: 'Error',
    component: () => import('@/components/Test/TestComponent.vue'),
    meta: {
      title: 'Error Page'
    }
  },
  {
    path: '/login/',
    name: 'Login',
    component: () => import('@core/pages/Login.vue'),
    meta: {
      title: 'Login'
    }
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import('@/components/Test/TestComponent.vue'),
    meta: {
      title: 'Home'
    }
  },
  {
    // path: '*',
    path: '/:catchAll(.*)',
    component: () => import('@core/pages/error/NotFoundPage.vue')
  },
]

const router = createRouter({
  history: createWebHistory('/web'),
  routes
})

const publicPage = ['Login', 'Reset', 'AdminLogin', 'retrieve-my-info', 'Error', 'Resource File Download', 'Authorization', 'EzSubCallback']

// Before
router.beforeEach(routerRedirect(store, publicPage))

export default router
