import NeedsBase from '@master/model/kyc-form/need-analysis/NeedsBase'
import KYCClient from '@company-common/model/kyc-form/KYCClient'
import { Ignore } from '@master/annotation/Ignore'
import { NA_ERROR_MSG } from '../utils/constants'

import { pv, sum, valueIsUndefined,infAdjReturn} from '@master/model/kyc-form/need-analysis/math'
/**
 * Depending on the structure of the PDF, additionalNotes may not be in used
 */
export default class LongTermCareDisabilityIncomeModel extends NeedsBase {

  @Ignore()
  selectedClient?:  KYCClient = undefined

  name = 'Long Term Care/Disability Income'
  needs = 'Old Age Disabilities'
  isProtection = true
  amountNeeded?: number = undefined
  period?: number = undefined

   // required
   required1?: number = undefined
   required2?: number = undefined
   required3?: number = undefined
   required4?: number = undefined
   required5?: number = undefined

   // available
   available1?: number = undefined
   available2?: number = undefined
   available3?: number = undefined
   available4?: number = undefined
   available5?: number = undefined

   available1Reason?: string = undefined

   totalExistingInsurance?: number = undefined

  additionalNotes = ''
  amountNeededReason?: string = undefined

  amountNeededCR?:boolean = undefined
  available1CR?:boolean = undefined

  cachedAmountNeeded?: number = undefined
  cachedAvailable1?: number = undefined

  get sumAvailable () {
    const total = sum(this.available1, this.available2, this.available3, this.available4, this.available5) || 0
    return parseFloat(total.toFixed(2))
  }

  get sumRequired () {
    const total = sum(this.required1, this.required2, this.required3, this.required4, this.required5) || 0
    return parseFloat(total.toFixed(2))
  }

  get annualAmountNeeded () {
    if(valueIsUndefined(this.amountNeeded)) return null
    return this.amountNeeded * 12
  }

  get grandTotal () {
    const total = sum(this.available1, -(this.amountNeeded)) || 0
    return parseFloat(total.toFixed(2))
  }

  get isCompleted () {
    return (this.amountNeeded != null && this.period != null)? true: false
  }

  get amountNeededReasonStatus () {
    let isNeedReason = false
    const selectedClientByCashFlow = this.selectedClient?.cashFlow
    if (!selectedClientByCashFlow) return false
    if (selectedClientByCashFlow?.isUserDeclinedCashFlow){
      this.amountNeededReason =  undefined
      this.amountNeededCR =  false
      return isNeedReason
    }
    const { isSimplifiedMode, netEmploymentIncome, totalMonthlyOutflow, simplifiedTotalInflow, simplifiedTotalOutflow } = selectedClientByCashFlow
    
    if (isSimplifiedMode) {
      const valToCompare = simplifiedTotalOutflow > simplifiedTotalInflow ? simplifiedTotalOutflow : simplifiedTotalInflow
      isNeedReason = this.amountNeeded > valToCompare
    } else {
      const valToCompare = netEmploymentIncome > totalMonthlyOutflow ? netEmploymentIncome : totalMonthlyOutflow
      isNeedReason = this.amountNeeded > valToCompare
    }
    if (isNeedReason) {
      if (this.amountNeeded !== this.cachedAmountNeeded) {
        this.amountNeededCR = false
        this.cachedAmountNeeded = this.amountNeeded
      }
    } else {
      this.amountNeededReason =  undefined
      this.amountNeededCR = false
      this.cachedAmountNeeded = this.amountNeeded
    }
    return isNeedReason
  }

  get available1ReasonStatus (){
    let isNeedReason = false
    
    if(this.available1 < this.totalExistingInsurance){
      isNeedReason = true
    } else {
      this.available1Reason = undefined
      this.available1CR = false
    }

    return isNeedReason
  }

  get available1Error (){
    if (this.available1 > this.totalExistingInsurance) {
      return NA_ERROR_MSG.TB
    } else {
      return null
    }
  }

  set setSelectedClient (client) {
    this.selectedClient = client
  }

  constructor (type: 'client_0' | 'client_1' = 'client_0', id = 'p5') {
    super(`${type}_ltcdi_${id}`)
  }

  resetAllFields (): void {
    super.resetAllFields()
    this.isProtection = true
  }
}
