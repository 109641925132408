import { Ignore } from '@master/annotation/Ignore'
import { IDateService } from '@master/services/IDateService'
import { ArrayBase } from '../ArrayBase'
import { MaxChar } from '@master/annotation/MaxChar'
import { InputMaxChar } from '@master/enum/MaxCharsEnum'
import { ClientBase } from '@master/model/ClientBase';

export default class ExistingInsurenceDetails extends ClientBase {
  discloseExistingInsurance: boolean | null = null;
  hasNoExistingInsurance: boolean | null = null;
  optOutNeedsAnalysis: boolean | null = null;
  justification = ''

  justificationError: boolean | null = false;

  get skipOptions() {
    if(this.hasNoExistingInsurance) return 'no_insurance';
    if(this.optOutNeedsAnalysis) return 'no_disclose';
    return ''
  }

  DDdepentants = [];
  insuranceRecords = [];
  dependants = [];
  hospitalPlanCover: boolean


  existingInsurenceHospitalPlanCover: boolean | null = null;

  cantRememberDate: boolean | null = null;

  @MaxChar(InputMaxChar.XXXLARGE)
  ExistinInsurenceDeclrationNote = ''

  ExistingInsureAdditionalNote   = ''

  constructor (type: 'client_0' | 'client_1' = 'client_0') {
    super(type);
    this.DDdepentants = [];
    this.insuranceRecords = [];
    this.dependants = [];
  }

  toFieldUpdateJson() {
    return {
      discloseExistingInsurance: this.discloseExistingInsurance,
      hasNoExistingInsurance: this.hasNoExistingInsurance,
      optOutNeedsAnalysis: this.optOutNeedsAnalysis,
      justification : this.justification,
      ExistingInsureAdditionalNote : this.ExistingInsureAdditionalNote,
    }
  }

  setData(newData) {
    for (const key in newData) {
      if (this.hasOwnProperty(key)) {
        this[key] = newData[key];
      }
    }
  }
}

export class ExistingInsurence extends ArrayBase {
  type = 'ExistingInsurence'

  @Ignore()
  dateService: IDateService

  constructor(dateService: IDateService) {
    super()
  }

  hospitalPlanCover: boolean = false
  owner: string = ''
  commencementDate?: string = ''
  cannotRemember: boolean = false
  lifeCompany: string = ''
  policyType: string = ''
  premium: number
  sumAssuredDeath: number
  sumAssuredTPD: number
  sumAssuredCI: number
  sumAssuredPA: number
  sumAssuredMaternity: number
  monthlyBenefitLTC: number
  cashValue: number
  remarks: string = ''
  lifeCompanyAndPolicyType: string = ''
}
