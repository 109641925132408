<template>
  <div>
    <div class="title">
      {{ title }} aa
      <AppSwitch
        :options="['Client 1', 'Client 2']"
        :selected="selectedClient"
        @option-selected="clientOptionSelected" />
    </div>
    <slot :client="client" :clientIndex="clientIndex" :kycForm="kycForm">
    </slot>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import AppSwitch from '@core/components/AppSwitch.vue'
import { namespace } from 'vuex-facing-decorator'
import { SimpleForm } from '@master/model/kyc-form/FormOptions'
import { inject } from 'inversify-props'
import { UIService } from '@core/services/UIService'
import { SharedVariable } from '@core/model/SharedVariable'

const kycFormStore = namespace('KYCFormStore')

@Component({
  components: {
    AppSwitch
  }
})
export default class AppClientForm extends Vue {
  @Prop() title!: string

  @kycFormStore.State
  kycForm!: SimpleForm

  uiService: UIService

  @inject()
  private sharedVariable: SharedVariable

  selectedClient = 'Client 1'

  mounted () {
    // take injected UIService and set it to local
    this.uiService = this.sharedVariable.uiService
  }

  async clientOptionSelected (option: string) {
    if (option === 'client2' && !this.isClient2Included()) {
      await this.uiService.alert.show({
        type: 'warning',
        message: 'Client 2 is not included in this KYC Form. To include, go to "Client Summary" page and enable the checkbox.'
      })
      return
    }
    this.selectedClient = option
  }

  isClient2Included () {
    return this.kycForm.hasClient2
  }

  get client () {
    return this.selectedClient === 'client1' ? this.kycForm.client1 : this.kycForm.client2
  }

  get clientIndex () {
    return this.selectedClient === 'client1' ? 0 : 1
  }
}
</script>
