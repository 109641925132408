export enum FinancialObjectivesType {
    PROTECTION_DEATH = "deathProtectionNeeds",
    PROTECTION_DISABILITY = "disabilityProtectionNeeds",
    PROTECTION_CRITICAL_ILLNESS = "criticalIllnessNeeds",
    PROTECTION_DISABILITY_INCOME = "disabilityIncomeNeeds",
    PROTECTION_MEDICAL_COST = "hospitalizationsNeeds",
    PROTECTION_PERSONAL_ACCIDENT = "personalAccident",  
    PROTECTION_PREGNACY = "pregnancyProtection",
    PROTECTION_DEPENDANTS = "depentantsProtectionNeeds",
    ACCUMULATION_RETIREMENT = "retirementNeedsNew",
    ACCUMULATION_CHILDREN_EDUCATION = "educationNeeds",
    ACCUMULATION_OTHER = "savingForOtherPurpose",
    PLANNING_SRS = "PLANNING_SRS", // TODO: Update with actual prop name in NeedAnalysisValidation.ts
    PLANNING_ESTATE = "PLANNING_ESTATE", // TODO: Update with actual prop name in NeedAnalysisValidation.ts
  }

export const NA_ERROR_MSG = {
    S: "The value has deviated from the recommended guide, please explain here.",
    S2: "The value has deviated from the recommended guide, please explain.",
    TA: "The amount is lesser than 'Existing Insurance' section, please state how this amount is derived.",
    TA2: "This amount is lesser than the Existing Insurance section. Please state how this amount is derived.",
    TB: "This amount has exceeded the amount declared in the Existing Insurance section. Please adjust.",
    U: "The amount is different from the 'Assets & Liabilities' section, please state how this amount is derived.",
    U2: "This amount is different from the 'Assets & Liabilities' section, please state how this amount is derived.",
    V: "Input value must be within the range of 0.00% to 9.00%, please state how this value is derived.",
    W: "This value must be within the range of -2.00% to 9.00%, please state how this value is derived.",
    X: "The amount has exceeded Section Inflow & Outflow - Net Employment Income / Total Monthly Inflow / Total Monthly Outflow. Please state how this amount is derived.",
    X2: "The value has deviated from the recommended guide, please explain here.",
    YA: {
        title: "You have over-utilised the Cash assets (Savings, Fixed Deposits, etc) for these needs:",
        message: "Please adjust the amount or input a justification below before continuing.",
        reasonTitle: "Over-utilisation of Cash assets (Savings, Fixed Deposits, etc)",
        reasonMsg: "Over-utilising Cash assets (Savings, Fixed Deposits, etc)"
    },
    YB: {
        title: "You have under-utilised the Cash assets (Savings, Fixed Deposits, etc) for these needs:",
        message: "Please adjust the amount or input a justification below before continuing.",
        reasonTitle: "Under-utilisation of Cash assets (Savings, Fixed Deposits, etc)",
        reasonMsg: "Not fully utilising Cash assets (Savings, Fixed Deposits, etc)"
    },
    ZA: {
        title: "You have over-utilised the Investments (Bonds, Unit Trusts, Shares)  for these needs:",
        message: "Please adjust the amount or input a justification below before continuing.",
        reasonTitle: "Over-utilisation of Investments (Bonds, Unit Trusts, Shares)",
        reasonMsg: "Over-utilising Investments (Bonds, Unit Trusts, Shares)"
    },
    ZB: {
        title: "You have under-utilised the Investments (Bonds, Unit Trusts, Shares) for these needs:",
        message: "Please adjust the amount or input a justification below before continuing.",
        reasonTitle: "Under-utilisation of Investments (Bonds, Unit Trusts, Shares)",
        reasonMsg: "Not fully utilising Investments (Bonds, Unit Trusts, Shares)"
    }
}

export const Global = {
    STATUTORY_RETIRE_AGE: 63,
    LIFE_EXPECT_MALE: 82,
    LIFE_EXPECT_FEMALE: 86,
    ASSUMED_INFLATION_RATE: 3.00,
    CRITICAL_ILLNESS_YEARS_REQ: 4, // The number of years required for critical illness needs (recovery period)
    MAX_INTEREST_RATE: 9, // Valid rate range is 0% - 9%
    UNI_ENTRY_AGE_SON: 21,
    UNI_ENTRY_AGE_DAUGH: 19,
    EDU_INFLATION_RATE: 3.00
}
