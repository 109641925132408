
import { IProjectSettings } from '@master/config/IProjectSettings'
import AdviserDetails from '@master/model/kyc-form/AdviserDetails'
import CoreKYCForm from '@master/model/kyc-form/KYCForm'
import { IDateService } from '@master/services/IDateService'
import { IRiskRatingService } from '@master/services/IRiskRatingService'
import AdviserDeclaration from './AdviserDeclaration'
import KYCClient from './KYCClient'
import { CustomDependant } from './CustomDependant'
import CustomTrustedIndividual from './CustomTrustedIndividual'
import { IExistingInvestmentWrapper } from '@master/model/kyc-form/existing-plans/IExistingInvestmentWrapper'
import { IExistingPortfolioWrapper } from '@master/model/kyc-form/existing-plans/IExistingPortfolioWrapper'
import CustomExistingPortfolioDTO from './existing-plans/v2/CustomExistingPortfolioDTO'
import ExistingInvestmentDTO from '@master/model/kyc-form/existing-plans/v2/ExistingInvestmentDTO'
import CustomRecommendation from './CustomRecommendation'
import ILPPortfolioArray from '@master/model/kyc-form/ILPPortfolioArray'
import ClientTrustedIndividual from '@master/model/kyc-form/ClientTrustedIndividual'
import FinancialObjectivesDTO from '@master/model/kyc-form/FinancialObjectives'
import { DependantDetails, Dependant } from '@master/model/kyc-form/Dependant'
import AffordabilityModal from '@master/model/kyc-form/Affordability'
import { InsuranceProduct } from "@master/model/kyc-form/InsuranceProduct";
import CommonProductErrors from '@master/model/kyc-form/CommonProductErrors'
import Switching from '@master/model/kyc-form/Switching'
import { SwitchReplaceAdditionalData } from '@master/model/kyc-form/SwitchReplaceAdditionalData'

export default class KYCForm extends CoreKYCForm {
  declare client1: KYCClient
  declare client2: KYCClient
  declare client1TrustedIndividual: ClientTrustedIndividual
  declare client2TrustedIndividual: ClientTrustedIndividual
  declare client1FinancialObjectives: FinancialObjectivesDTO
  declare client2FinancialObjectives: FinancialObjectivesDTO
  declare client1DependantDetails: DependantDetails
  declare client2DependantDetails: DependantDetails
  declare trustedIndividual: CustomTrustedIndividual
  declare dependants: CustomDependant[]
  declare recommendations: CustomRecommendation
  declare insuranceProduct: InsuranceProduct
  declare switching: Switching
  declare commonProductErrors: CommonProductErrors
  declare switchReplaceAdditionalForm: SwitchReplaceAdditionalData
  newIlpPortfolios: ILPPortfolioArray

  existingPortfolio: IExistingPortfolioWrapper = new CustomExistingPortfolioDTO()
  existingInvestment: IExistingInvestmentWrapper = new ExistingInvestmentDTO()
  mandatoryDocUploads:any[] = []

  // Needs Analysis
  _getNeedsAnalysis: any = [];

  adviser = {
    details: new AdviserDetails(),
    declaration: new AdviserDeclaration()
  }

  caseNo: string
  meta: any
  myInfoResponseDate

  visitedSection: {
    url?: string
    tab: 'client1' | 'client2'
  }

  constructor (dateService: IDateService, projectSettings: IProjectSettings, riskRatingService: IRiskRatingService) {
    super(dateService, projectSettings, riskRatingService)

    this.client1 = new KYCClient('client_0', dateService, projectSettings)
    this.client2 = new KYCClient('client_1', dateService, projectSettings)
    this.client1TrustedIndividual = new ClientTrustedIndividual('client_0')
    this.client2TrustedIndividual = new ClientTrustedIndividual('client_1')
    this.client1FinancialObjectives = new FinancialObjectivesDTO('client_0')
    this.client2FinancialObjectives = new FinancialObjectivesDTO('client_1')
    this.client1DependantDetails = new DependantDetails('client_0')
    this.client2DependantDetails = new DependantDetails('client_1')
    this.dependants = Array(5).fill(null).map(() => new CustomDependant(dateService))
    this.trustedIndividual = new CustomTrustedIndividual()
    this.newIlpPortfolios = new ILPPortfolioArray()
    this.recommendations = new CustomRecommendation(riskRatingService)
    this.insuranceProduct = new InsuranceProduct()
    this.switchReplaceAdditionalForm = new SwitchReplaceAdditionalData()

    this.commonProductErrors = new CommonProductErrors()
    this.switching =  new Switching()
    this._getNeedsAnalysis = [];
    this.mandatoryDocUploads = []

    this.visitedSection = {
      url: undefined,
      tab: 'client1' 
    }
  }

  getClient1FinancialObjectives(): FinancialObjectivesDTO {
    return this.client1FinancialObjectives;
  }

  getClient2FinancialObjectives(): FinancialObjectivesDTO {
    return this.client2FinancialObjectives;
  }

  fromAssetLiabilityJSON(data: any) {
    if (data && data.comprehensiveJson) {
      this.client1.netWorth.setData(getFlattenObject(data.comprehensiveJson.client1));
      this.client2.netWorth.setData(getFlattenObject(data.comprehensiveJson.client2));
    }
    if (data && data.simplifiedJson) {
      this.client1.netWorth.setDataSimplified(data.simplifiedJson.client1);
      this.client2.netWorth.setDataSimplified(data.simplifiedJson.client2);
    }
  }

  resetTrustedIndividual() {
    this.client1TrustedIndividual = new ClientTrustedIndividual('client_0');
    this.client2TrustedIndividual = new ClientTrustedIndividual('client_1');
  }

  resetAssetLiability(data: any) {
    if(data && data.type === "client_0") {
      delete data.isSimplifiedMode
      this.client1.netWorth.setData(data);
    }

    if(data && data.type === "client_1") {
      delete data.isSimplifiedMode
      this.client2.netWorth.setData(data);
    }
  }

  toAssetLiabilityJSON() {
    const isClient2Included = this.client2.personalDetails.included;
    return {
      comprehensiveJson: {
        client1: this.client1.netWorth?.toJSON(),
        client2: isClient2Included ? this.client2.netWorth?.toJSON() : null,
      },
      simplifiedJson: {
        client1: this.client1.netWorth?.toSimplifiedJSON(),
        client2: isClient2Included ? this.client2.netWorth?.toSimplifiedJSON() : null,
      }
    }
  }

  fromCashFlowJSON(data: any) {
    if (data && data.comprehensiveJson) {
      this.client1.cashFlow.setData(getFlattenObject(data.comprehensiveJson.client1));
      this.client2.cashFlow.setData(getFlattenObject(data.comprehensiveJson.client2));
    }
    if (data && data.simplifiedJson) {
      this.client1.cashFlow.setData(getFlattenObject(data.simplifiedJson.client1));
      this.client2.cashFlow.setData(getFlattenObject(data.simplifiedJson.client2));
    }
  }

  resetCashFlow(data: any) {
    if(data && data.type === "client_0") {
      delete data.isSimplifiedMode
      this.client1.cashFlow.setData(data);
    }

    if(data && data.type === "client_1") {
      delete data.isSimplifiedMode
      this.client2.cashFlow.setData(data);
    }
  }

  toCashFlowJSON() {
    const isClient2Included = this.client2.personalDetails.included;
    return {
      comprehensiveJson: {
        client1: this.client1.cashFlow?.toJSON(),
        client2: isClient2Included ? this.client2.cashFlow?.toJSON() : null,
      },
      simplifiedJson: {
        client1: this.client1.cashFlow?.toSimplifiedJSON(),
        client2: isClient2Included ? this.client2.cashFlow?.toSimplifiedJSON() : null,
      },
    };
  }

  toExistingInvestment() {
    const isClient2Included = this.client2.personalDetails.included;
    return {
        client1: this.client1.existingInvestment?.toJSON(),
        client2: isClient2Included ? this.client2.existingInvestment?.toJSON() : null,

    };
  }

  updateClient1Dependant(dependants: any[]) {
    this.client1DependantDetails.DDdepentants = dependants;
  }

  updateClient2Dependant(dependants: any[]) {
    this.client2DependantDetails.DDdepentants = dependants;
  }
}

function getFlattenObject(data) {
  const result = {};
  function traverse(obj) {
    for (const key in obj) {
      if (typeof obj[key] === "object" && obj[key] !== null) {
        traverse(obj[key]); // Recursively traverse nested objects
      } else {
        result[key] = obj[key]; // Add properties to the result object
      }
    }
  }
  traverse(data);
  return result;
}

