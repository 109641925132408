import { Default } from "@master/annotation/Default";
import { Base } from "../Base";

export interface Fund {
  fundOid: string,
  fundCategory : string,
  fundHouse : string,
  name : string,
  fundISIN : string,
  fundCode : string,
  assetClass : string,
  geographicalFocus : string,
  riskRating : number,
  riskClassification : string,
  frequency : string,
  currency : string,
  allocation : number,
  invAmt : number,
  salesCharge : number,
  settlementMode : string

  borFundDetails?: string;
  borFundRisks?: string
  borFundSwitch?: string
  borAddInput?: string
}

export default class ILPPortfolioV3 extends Base {
  proposer = "";
  needs = [];
  crp = "";
  invPlatform = "";
  nwa = "";
  switch = "";
  totalInvestment: number;
  fund = "";
  wrap = "";
  wrapFee = "";
  choice: boolean | null = null;
  ////old dto fields

  @Default(false)
  isSubmitted: boolean = false

  highPriorityNeedsReasonQa1_C1 = '';
  highPriorityNeedsReasonQa1_C2 = '';
  highPriorityNeedsReasonQa2 = '';
  //timeHorizonDeclaration = false
  @Default(false)
  timeHorizonDeclaration: boolean = false

  buySwitch = "";
  // choice = "";
  for = "";

  funds: Fund[] = [];

  name = "";
  platform = "";
  accountNumber = "";

  oid = "";
  issuerOid = "";
  paymentMode = "";
  rcmdReferenceId = ""; // this used for mapping on the NBR, it will show relevant ilp subList on nbr detail page


  @Default("")
  borReason?: string;

  @Default("")
  borReasonLastEdited?: string;

  // @Default(false)
  // isSwitchingPolicy

  @Default("")
  borSwitchingPolicyJustification

  @Default("")
  borNeedsAdditionalInfo

  @Default("")
  borRiskProfileAdditionalInfo

  @Default("")
  borBudgetAdditionalInfo

  @Default("")
  borFundDetails?: string;

  @Default("")
  borFundRisks?: string

  @Default("")
  borFundSwitch?: string

  @Default("")
  borFundDetailsLastEdited?: string;

  clearData() {
    const excludeKeys = ["type", "startIndex", "curIndex"];
    Object.keys(this).forEach((key) => {
      if (!excludeKeys.includes(key)) {
        // need assign value as empty string to overwrite the data that is stored in database,
        if (this[key] instanceof Array) {
          this[key] = [];
        } else {
          this[key] = "";
        }
      }
    });
  }

  afterExtractDataBase(data: { funds: any[] }) {
    return super.afterExtractData(data);
  }

  afterExtractData(data: { funds: any[] }) {
    const defaultMap = super.afterExtractData(data);

    data.funds = data.funds.map((element) => {
      /* eslint-disable @typescript-eslint/camelcase */
      const fund = {
        fundOid : element.fundOid,
        fundCategory : element.fundCategory,
        fundHouse : element.fundHouse,
        name : element.name,
        fundISIN : element.fundISIN,
        fundCode : element.fundCode,
        assetClass : element.assetClass,
        geographicalFocus : element.geographicalFocus,
        riskRating : element.riskRating,
        riskClassification : element.riskClassification,
        frequency : element.frequency,
        currency : element.currency,
        allocation : element.allocation,
        invAmt : element.invAmt,
        salesCharge : element.salesCharge,
        settlementMode : element.settlementMode,
        borFundDetails: element.borFundDetails,
        borFundRisks: element.borFundRisks,
        borAddInput: element.borAddInput
      };
      return fund;
    });

    Object.assign(data, defaultMap);
    return data;
  }

  convertToRawData() {
    const data: any = super.convertToRawData();

    // Convert funds
    /* eslint-disable @typescript-eslint/camelcase */
    const rawFunds = this.funds.map((fund) => {
      return {
        fundOid : fund.fundOid,
        fundCategory : fund.fundCategory,
        fundHouse : fund.fundHouse,
        name : fund.name,
        fundISIN : fund.fundISIN,
        fundCode : fund.fundCode,
        assetClass : fund.assetClass,
        geographicalFocus : fund.geographicalFocus,
        riskRating : fund.riskRating,
        riskClassification : fund.riskClassification,
        frequency : fund.frequency,
        currency : fund.currency,
        allocation : fund.allocation,
        invAmt : fund.invAmt,
        salesCharge : fund.salesCharge,
        settlementMode : fund.settlementMode,
        borFundDetails: fund.borFundDetails,
        borFundRisks: fund.borFundRisks,
        borAddInput: fund.borAddInput
      };
    });
    data.funds = rawFunds;
    return data;
  }
}
