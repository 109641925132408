import { ClientBase } from '@master/model/ClientBase';

export default class ClientSummary extends ClientBase {

  isSummaryCompleted:boolean = false
  isOtherSectionsCompleted: boolean = false

  isInflowOutflowVisited: boolean = false
  isAssestsLiabilitiesVisited: boolean = false
  isInvAssesmentVisited: boolean = false
  isNeedAnalysisVisited: boolean = false

  isClient2DisableBlocked: boolean

  lastSectionUrl: string;

  constructor (type: 'client_0' | 'client_1' = 'client_0') {
    super(type)
  }

}
