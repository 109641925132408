export default class FormKeyConverter {
  convertToFormattedKey (rawKey: string) {
    let reconstructKey = rawKey

    // there are existing fields with client_
    const regex = new RegExp(/^client_[a-zA-Z]/)
    if (regex.test(reconstructKey)) {
      reconstructKey = 'client_0' + reconstructKey.substr(6)
    } else if (rawKey.startsWith('client_2')) {
      reconstructKey = 'client_1' + reconstructKey.substr(8)
    }

    switch (rawKey) {
      case 'ppda_consent' :
        reconstructKey = 'pdpa_consent'
        break
      case 'ca_decl_fc_provide_copy':
        reconstructKey = 'client_decl_fc_provide_copy'
        break
      case 'ca_decl_statements':
        reconstructKey = 'client_decl_statements'
        break
      case 'declaration_advisory_process':
        reconstructKey = 'client_decl_advisory_process'
        break
      case 'declaration_beneficial_owner': // affects StampedBeneficialOwnerPdfApi, kycPdfLeftPanelController and kycPdfSignaturePanelController
        reconstructKey = 'client_decl_beneficial_owner'
        break
      case 'declaration_introduced':
        reconstructKey = 'client_decl_introduced'
        break
      case 'declaration_introduced_id':
        reconstructKey = 'client_decl_introduced_id'
        break
      case 'declaration_introduced_name':
        reconstructKey = 'client_decl_introduced_name'
        break
      case 'declaration_introduced_company':
        reconstructKey = 'client_decl_introduced_company'
        break
      case 'declaration_politically_exposed':
        reconstructKey = 'client_decl_politically_exposed'
        break
      case 'declaration_acknowledgement_by_client':
        reconstructKey = 'client_decl_acknowledgement'
        break
      case 'declaration_remuneration_cis':
        reconstructKey = 'client_decl_remuneration_cis'
        break
      case 'declaration_remuneration_life':
        reconstructKey = 'client_decl_remuneration_life'
        break
      case 'declaration_recommendation_by_fc':
        reconstructKey = 'fc_decl_recommendation'
        break
      case 'declaration_acknowledgement_by_fc':
        reconstructKey = 'fc_decl_acknowledgement'
        break
      case 'declaration_acknowledgement_fc_notes':
        reconstructKey = 'fc_decl_acknowledgement_notes'
        break
      case 'client_decision_agree':
        reconstructKey = 'client_decision_agree'
        break
    }

    // replace all key starts with ma/ja
    if (reconstructKey.startsWith('ma_')) {
      reconstructKey = 'client_0_' + reconstructKey.substr(3)
    } else if (rawKey.startsWith('ja_')) {
      reconstructKey = 'client_1_' + reconstructKey.substr(3)
    }

    // rename rpa attributes
    if (reconstructKey.startsWith('rpa_ma_')) {
      reconstructKey = 'client_0_rpa_' + reconstructKey.substr(7)
    } else if (reconstructKey.startsWith('rpa_ja_')) {
      reconstructKey = 'client_1_rpa_' + reconstructKey.substr(7)
    }

    if (reconstructKey.indexOf('risk_score') > -1) {
      reconstructKey = reconstructKey.replace('risk_score', 'rpa_risk_profile_calculated')
    } else if (reconstructKey.indexOf('rp_result') > -1) {
      reconstructKey = reconstructKey.replace('rp_result', 'rpa_risk_score')
    } else if (reconstructKey.indexOf('own_choice') > -1) {
      reconstructKey = reconstructKey.replace('own_choice', 'rpa_own_choice')
    } else if (reconstructKey.indexOf('risk_profile') > -1) {
      reconstructKey = reconstructKey.replace('risk_profile', 'rpa_risk_profile')
    } else if (reconstructKey.indexOf('risk_profile_preferred') > -1) {
      reconstructKey = reconstructKey.replace('risk_profile_preferred', 'rpa_risk_profile_preferred')
    }

    // change the key accordingly for cka and rpa
    if (reconstructKey === 'complete_cka_rpa') {
      reconstructKey = 'client_0_rpa_completed'
    }
    if (reconstructKey === 'complete_cka') {
      reconstructKey = 'client_0_cka_completed'
    }

    // if it happens to be any of the below, replace it accordingly
    if (reconstructKey.indexOf('identifying_priorities_additional_notes') > -1) {
      reconstructKey = 'identifying_priorities_additional_notes'
    } else if (reconstructKey.indexOf('identifying_priorities') > -1) {
      reconstructKey = reconstructKey.replace('identifying_priorities_', '')
    } else if (reconstructKey.indexOf('protection_additional_notes') > -1) {
      reconstructKey = reconstructKey.replace('protection_additional_notes', 'p1_additional_notes')
    } else if (reconstructKey.indexOf('others_additional_notes') > -1) {
      reconstructKey = reconstructKey.replace('others_additional_notes', 'm1_additional_notes')
    } else if (reconstructKey.indexOf('pls_specify') > -1) {
      reconstructKey = reconstructKey.replace('pls_specify', 'previous_occupation')
    } else if (reconstructKey.indexOf('ppda_marketing') > -1) {
      reconstructKey = reconstructKey.replace('ppda_marketing', 'pdpa_marketing')
    } else if (reconstructKey.indexOf('your_finances_additional_notes') > -1) {
      reconstructKey = reconstructKey.replace('your_finances_additional_notes', 'client_0_cash_flow_additional_notes')
    }

    if (reconstructKey === '') {
      reconstructKey = rawKey
    }

    return reconstructKey
  }

  mapRawToFormatted (kycForm: any) {
    const formattedKYCForm: any = {} // eslint-disable-line @typescript-eslint/consistent-type-assertions
    let reconstructKey = ''
    for (const key of Object.keys(kycForm)) {
      reconstructKey = this.convertToFormattedKey(key)

      // After all has been renamed, put the into the formatted form
      formattedKYCForm[reconstructKey] = kycForm[key]
      if (reconstructKey.endsWith('education')) {
        formattedKYCForm[`${reconstructKey}_level`] = kycForm[key]
      }
    }

    return formattedKYCForm
  }

  convertToRawKey (formattedKey: string) {
    let reconstructKey = formattedKey

    switch (formattedKey) {
      case 'pdpa_consent' :
        reconstructKey = 'pdpa_consent'
        break
      case 'client_decl_fc_provide_copy':
        reconstructKey = 'ca_decl_fc_provide_copy'
        break
      case 'client_decl_statements':
        reconstructKey = 'ca_decl_statements'
        break
      case 'client_decl_advisory_process':
        reconstructKey = 'declaration_advisory_process'
        break
      case 'client_decl_beneficial_owner':
        reconstructKey = 'declaration_beneficial_owner'
        break
      case 'client_decl_introduced':
        reconstructKey = 'declaration_introduced'
        break
      case 'client_decl_introduced_id':
        reconstructKey = 'declaration_introduced_id'
        break
      case 'client_decl_introduced_name':
        reconstructKey = 'declaration_introduced_name'
        break
      case 'client_decl_politically_exposed':
        reconstructKey = 'declaration_politically_exposed'
        break
      case 'client_decl_acknowledgement':
        reconstructKey = 'declaration_acknowledgement_by_client'
        break
      case 'client_decl_introduced_company':
        reconstructKey = 'declaration_introduced_company'
        break
      case 'client_decl_remuneration_cis':
        reconstructKey = 'declaration_remuneration_cis'
        break
      case 'client_decl_remuneration_life':
        reconstructKey = 'declaration_remuneration_life'
        break
      case 'fc_decl_recommendation':
        reconstructKey = 'declaration_recommendation_by_fc'
        break
      case 'fc_decl_acknowledgement':
        reconstructKey = 'declaration_acknowledgement_by_fc'
        break
      case 'fc_decl_acknowledgement_notes':
        reconstructKey = 'declaration_acknowledgement_fc_notes'
        break
      case 'client_decision_agree':
        reconstructKey = 'client_decision_agree'
        break
      case 'identifying_priorities_additional_notes':
        reconstructKey = 'ma_identifying_priorities_additional_notes'
        break
    }

    // replace all key starts with ma/ja
    if (reconstructKey.startsWith('client_0_')) {
      reconstructKey = 'ma_' + reconstructKey.substr(9)
    } else if (formattedKey.startsWith('client_1_')) {
      reconstructKey = 'ja_' + reconstructKey.substr(9)
    }

    if (reconstructKey.indexOf('rpa_risk_profile_calculated') > -1) {
      reconstructKey = reconstructKey.replace('rpa_risk_profile_calculated', 'risk_score')
    } else if (reconstructKey.indexOf('rpa_risk_score') > -1) {
      reconstructKey = reconstructKey.replace('rpa_risk_score', 'rp_result')
    } else if (reconstructKey.indexOf('rpa_own_choice') > -1) {
      reconstructKey = reconstructKey.replace('rpa_own_choice', 'own_choice')
    } else if (reconstructKey.indexOf('rpa_risk_profile') > -1) {
      reconstructKey = reconstructKey.replace('rpa_risk_profile', 'risk_profile')
    } else if (reconstructKey.indexOf('rpa_risk_profile_preferred') > -1) {
      reconstructKey = reconstructKey.replace('rpa_risk_profile_preferred', 'risk_profile_preferred')
    }

    // change the key accordingly for cka and rpa
    if (reconstructKey === 'ma_rpa_completed') {
      reconstructKey = 'complete_cka_rpa'
    }
    if (reconstructKey === 'ma_cka_completed') {
      reconstructKey = 'complete_cka'
    }

    // rename rpa attributes
    if (reconstructKey.startsWith('ma_rpa_')) {
      reconstructKey = 'rpa_ma_' + reconstructKey.substr(7)
    } else if (reconstructKey.startsWith('ja_rpa_')) {
      reconstructKey = 'rpa_ja_' + reconstructKey.substr(7)
    }

    // if it happens to be any of the below, replace it accordingly
    if (reconstructKey.indexOf('p1_additional_notes') > -1) {
      reconstructKey = reconstructKey.replace('p1_additional_notes', 'protection_additional_notes')
    } else if (reconstructKey.indexOf('m1_additional_notes') > -1) {
      reconstructKey = reconstructKey.replace('m1_additional_notes', 'others_additional_notes')
    } else if (reconstructKey.indexOf('previous_occupation') > -1) {
      reconstructKey = reconstructKey.replace('previous_occupation', 'pls_specify')
    } else if (reconstructKey.indexOf('pdpa_marketing') > -1) {
      reconstructKey = reconstructKey.replace('pdpa_marketing', 'pdpa_marketing')
    } else if (reconstructKey.indexOf('ma_cash_flow_additional_notes') > -1) {
      reconstructKey = reconstructKey.replace('ma_cash_flow_additional_notes', 'your_finances_additional_notes')
    }

    // Rename back to original naming with client_ and client_2_
    if (reconstructKey.startsWith('ma_')) {
      const rename = 'client_'
      if (reconstructKey.indexOf('product_risk_and_limitations_reason') > -1) {
        reconstructKey = rename + 'product_risk_and_limitations_reason'
      } else if (reconstructKey.indexOf('liquidity_consideration') > -1) {
        reconstructKey = rename + 'liquidity_consideration'
      } else if (reconstructKey.indexOf('product_risk_and_limitations_elaboration') > -1) {
        reconstructKey = rename + 'product_risk_and_limitations_elaboration'
      } else if (reconstructKey.indexOf('time_horizon_deviation_reason') > -1) {
        reconstructKey = rename + 'time_horizon_deviation_reason'
      } else if (reconstructKey.indexOf('risk_concentration_deviation_reason') > -1) {
        reconstructKey = rename + 'risk_concentration_deviation_reason'
      } else if (reconstructKey.indexOf('risk_concentration') > -1) {
        reconstructKey = rename + 'risk_concentration'
      } else if (reconstructKey.indexOf('product_recommendation_elaboration') > -1) {
        reconstructKey = rename + 'product_recommendation_elaboration'
      } else if (reconstructKey.indexOf('product_deviations_existence_reason') > -1) {
        reconstructKey = rename + 'product_deviations_existence_reason'
      } else if (reconstructKey.indexOf('risk_tolerance_deviation_reason') > -1) {
        reconstructKey = rename + 'risk_tolerance_deviation_reason'
      } else if (reconstructKey.indexOf('liquidity_emergency_funds_duration') > -1) {
        reconstructKey = rename + 'liquidity_emergency_funds_duration'
      } else if (reconstructKey.indexOf('product_recommendation_reason') > -1) {
        reconstructKey = rename + 'product_recommendation_reason'
      } else if (reconstructKey.indexOf('liquidity_deviation_reason') > -1) {
        reconstructKey = rename + 'liquidity_deviation_reason'
      } else if (reconstructKey.indexOf('product_deviations_existence') > -1) {
        reconstructKey = rename + 'product_deviations_existence'
      } else if (reconstructKey.indexOf('time_horizon_savings') > -1) {
        reconstructKey = rename + 'time_horizon_savings'
      } else if (reconstructKey.indexOf('recommendation_decision') > -1) {
        reconstructKey = rename + 'recommendation_decision'
      } else if (reconstructKey.indexOf('time_horizon_protection') > -1) {
        reconstructKey = rename + 'time_horizon_protection'
      } else if (reconstructKey.indexOf('risk_tolerance_in_tune') > -1) {
        reconstructKey = rename + 'risk_tolerance_in_tune'
      }
    } else if (reconstructKey.startsWith('ja_')) {
      const rename = 'client_2_'
      if (reconstructKey.indexOf('product_risk_and_limitations_reason') > -1) {
        reconstructKey = rename + 'product_risk_and_limitations_reason'
      } else if (reconstructKey.indexOf('liquidity_consideration') > -1) {
        reconstructKey = rename + 'liquidity_consideration'
      } else if (reconstructKey.indexOf('product_risk_and_limitations_elaboration') > -1) {
        reconstructKey = rename + 'product_risk_and_limitations_elaboration'
      } else if (reconstructKey.indexOf('time_horizon_deviation_reason') > -1) {
        reconstructKey = rename + 'time_horizon_deviation_reason'
      } else if (reconstructKey.indexOf('risk_concentration_deviation_reason') > -1) {
        reconstructKey = rename + 'risk_concentration_deviation_reason'
      } else if (reconstructKey.indexOf('risk_concentration') > -1) {
        reconstructKey = rename + 'risk_concentration'
      } else if (reconstructKey.indexOf('product_recommendation_elaboration') > -1) {
        reconstructKey = rename + 'product_recommendation_elaboration'
      } else if (reconstructKey.indexOf('product_deviations_existence_reason') > -1) {
        reconstructKey = rename + 'product_deviations_existence_reason'
      } else if (reconstructKey.indexOf('risk_tolerance_deviation_reason') > -1) {
        reconstructKey = rename + 'risk_tolerance_deviation_reason'
      } else if (reconstructKey.indexOf('liquidity_emergency_funds_duration') > -1) {
        reconstructKey = rename + 'liquidity_emergency_funds_duration'
      } else if (reconstructKey.indexOf('product_recommendation_reason') > -1) {
        reconstructKey = rename + 'product_recommendation_reason'
      } else if (reconstructKey.indexOf('liquidity_deviation_reason') > -1) {
        reconstructKey = rename + 'liquidity_deviation_reason'
      } else if (reconstructKey.indexOf('product_deviations_existence') > -1) {
        reconstructKey = rename + 'product_deviations_existence'
      } else if (reconstructKey.indexOf('time_horizon_savings') > -1) {
        reconstructKey = rename + 'time_horizon_savings'
      } else if (reconstructKey.indexOf('recommendation_decision') > -1) {
        reconstructKey = rename + 'recommendation_decision'
      } else if (reconstructKey.indexOf('time_horizon_protection') > -1) {
        reconstructKey = rename + 'time_horizon_protection'
      } else if (reconstructKey.indexOf('risk_tolerance_in_tune') > -1) {
        reconstructKey = rename + 'risk_tolerance_in_tune'
      }
    }

    const regex = /(ma_|ja_)(.[0-9]_)(level|plan|text)$/
    const found = reconstructKey.match(regex) || []
    if (found.length > 0) {
      reconstructKey = found[1] + 'identifying_priorities_' + found[2] + found[3]
    }

    if (reconstructKey === '') {
      reconstructKey = formattedKey
    }

    return reconstructKey
  }

  mapFormattedToRaw (snakeCaseKycForm: {}) {
    // console.log('snakeCaseKycForm received', snakeCaseKycForm);
    
    const rawKycForm = {
      _oid: ''
    }
    let reconstructKey = ''
    for (const key of Object.keys(snakeCaseKycForm)) {
      reconstructKey = this.convertToRawKey(key)
    // console.log('key: ', key, '--reconstructKey:', reconstructKey);

      rawKycForm[reconstructKey as keyof {}] = snakeCaseKycForm[key as keyof {}]
    }
    // console.log('rawKycForm in mapFormattedToRaw', rawKycForm);
    
    return rawKycForm
  }
}
