/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ContractDetailDTO } from './ContractDetailDTO';
import type { PersonalItemIssuedDetailDTO } from './PersonalItemIssuedDetailDTO';
import type { RequirementCheckListDTO } from './RequirementCheckListDTO';

export type CompanyInformation = {
    masYearlyRenewalDate: any;
    masTriYearlyRenewalDate: any;
    userOid?: string;
    corporateEmail: string;
    paymentStatus?: string;
    companyCode: string;
    masRepNo?: string;
    masApplicationDate?: number;
    closeMonitoringFromDate?: number;
    closemonitoringToDate?: number;
    remark?: string;
    suspendedFromDate?: number;
    suspendedToDate?: number;
    requirementCheckList?: RequirementCheckListDTO;
    contractType?: any;
    ifpasNo?: string;
    contractDetails?: ContractDetailDTO;
    contractedFromDate?: number;
    terminatedDate?: number;
    issuedDate?: number;
    lastPromotionDate?: number;
    withExperience?: string;
    personalItemsIssued?: PersonalItemIssuedDetailDTO;
    channel?: CompanyInformation.channel;
    isChannelVisible?: boolean;
    isProxyManagerAppointed?: boolean;
}

export namespace CompanyInformation {

    export enum channel {
        SG = 'SG',
        PS = 'PS',
        PFP = 'PFP',
    }


}