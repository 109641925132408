export const DEFAULT_TENANT_CONFIGS = {
    "minClientAge": null,
    "myinfoValidDays": null,
    "yearsSupportSon": null,
    "yearsSupportDaugh": null,
    "lifeExpectMale": null,
    "lifeExpectFemale": null,
    "cpfAnnInflowAmt": "",
    "cpfMaBal": "",
    "statutoryRetireAge": null,
    "criticalIllnessYearsReq": null,
    "assumedInflationRate": "",
    "eduInflationRate": "",
    "uniEntryAgeSon": null,
    "uniEntryAgeDaugh": null,
    "fundReqUploadFactsheet": "",
    "ezsubAppformExpAdvDaysLimit": null,
    "ezsubAppformExpMgrDaysLimit": null,
    "ezsubAppformExpBizsupDaysLimit": null,
    "ezsubAppformExpAlertDays": null,
    "casePurgedDays": null,
    "casePurged1stEmailNotifyDays": null,
    "casePurged2ndEmailNotifyDays": null,
    "casePurged3rdEmailNotifyDays": null,
    "casePurgedDailyEmailNotifyDays": null,
    "emailBusinessSupport": "",
    "emailBusinessAssurance": "",
    "emailSupportGeneral": "",
    "cashFlowNetworthInputMode": ""
}