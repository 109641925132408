import { ADVISER_PERMISSION_TYPE , ALL_PERMISSION_TYPE} from "@core/utils/global.json"
interface IRouteBasic {
    name: string;
    path: string;
    pathToRegexpOptions?: { strict: boolean };
    component: () => void;
    meta?: Record<string, string | Array<string> | any>;
    redirect?: {name: string; path?: string};
    children?: IChildrenRouteStructure [];
  }
  
  export interface IChildrenRouteStructure extends IRouteBasic{
    shouldDisabled?: boolean | ((T) => boolean);
    shouldHide?: boolean| ((T) => boolean);
  }
  
  export function bscReviewerRestriction (systemRole: string) {
    return systemRole === 'bsc-reviewer'
  }
  
  export function nonAdminRestriction (systemRole: string) {
    return systemRole !== 'ops-admin' && systemRole !== 'it-super-admin'
  }

  export const userProfileRoutes: IRouteBasic = {
    name: 'User Profile Main',
    path: '/user/:userOid/',
    component: () => import('../components/UserProfileV2.vue'),
    meta: {
      subject: ['adviserDetail', 'adviserEducation', 'adviserEmployment', 'adviserFinancialScheme', 'adviserLicence', 'adviserQualification', 'config', 'commissionRecord', 'country', 'productLicence', 'userAccount', 'userCompanyInfo', 'userPersonalInfo', 'userAccessAdministration', 'proxySupervisor'],
      ability: [
        {
          subject : "adviserDetail", 
          action : "read"
        },
        {
          subject : "adviserDetail", 
          action : "write"
        },
        {
          subject : "userAccount", 
          action : "manage"
        },
        {
          subject : "userCompanyInfo", 
          action : "manage"
        },
        {
          subject : "userPersonalInfo", 
          action : "manage"
        }
      ]
    },
    redirect: { name: 'User Profile' },
    children:
     [ 
      {
        name: 'User Profile admin',
        path: 'admin-profile-summary',
        shouldDisabled: bscReviewerRestriction,
        component: () => import('../components/UserProfileSummaryAdmin.vue'),
        meta: { 
          requiresAdmin: 'admin',title: 'User Profile', routeName: "ADMIN_PROFILE",
          subject : ['adviserDetail', "userAccount", "userCompanyInfo", "userPersonalInfo"],
          target : ALL_PERMISSION_TYPE
        } // Add a meta field for roles
      },
      {
        name: 'User Profile',
        path: 'profile-summary',
        shouldDisabled: bscReviewerRestriction,
        component: () => import('../components/UserProfileSummary.vue'),
        meta: {
          subject : ['adviserDetail', "userAccount", "userCompanyInfo", "userPersonalInfo"],
        }
      },
      {
        path: 'adviser-details',
        name: 'Personal Detail', // changed this route name from 'Personal Details' to 'Personal Detail' to avoid duplicate route name with 'Personal Details' on the KYC Form
        shouldDisabled: bscReviewerRestriction,
        component: () => import('../components/UserAdviserDetailsV2.vue')
      },
      {
        path: 'bsc-records',
        name: 'BSC Records',
        shouldDisabled: bscReviewerRestriction,
        component: () => import('../components/UserBscRecordsV2.vue')
      },
      // {
      //   path: 'bsc-records',
      //   name: 'BSC Records',
      //   component: () => import('@core/pages/user-profile/AdviserProfilePassThrough.vue'),
      //   children: [
      //     {
      //       name: 'BSC Records',
      //       path: 'records',
      //       meta: {
      //         parent: 'BSC Records'
      //       },
      //       shouldDisabled: bscReviewerRestriction,
      //       component: () => import('@core/pages/user-profile/components/UserBscRecordsV2.vue')
      //     },
      //     {
      //       name: 'BSC Record',
      //       path: 'record/:bscOid',
      //       meta: {
      //         parent: 'BSC Records'
      //       },
      //       component: () => import('@core/pages/bsc/UserBscRecord.vue')
      //     }
      //   ]
      // },
      // {
      //   path: 'bsc-records',
      //   name: 'BSC Records',
      //   component: () => import('../components/UserBscRecordsV2.vue'),
      //   children: [
      //     {
      //       name: 'BSC Record',
      //       path: 'record/:bscOid',
      //       // meta: {
      //       //   parent: 'User BSC Records'
      //       // },
      //       shouldDisabled: bscReviewerRestriction,
      //       shouldHide: true,
      //       component: () => import('@core/pages/bsc/UserBscRecord.vue')
      //     }
      //   ]
      // },
      {
        path: 'cpd-records',
        name: 'CPD Records',
        shouldDisabled: bscReviewerRestriction,
        component: () => import('../components/UserCpdRecordsV2.vue')
      },
      {
        path: 'commission-structure',
        name: 'Commission Statements',
        shouldDisabled: bscReviewerRestriction,
        component: () => import('../components/UserCommissionStructure.vue')
      }
    ]
}
