/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CsvExportDto } from '../models/CsvExportDto';
import type { FilterOptionsResponseDto } from '../models/FilterOptionsResponseDto';
import type { ListApprovalRequestDto } from '../models/ListApprovalRequestDto';
import type { ListApprovalResponseDto } from '../models/ListApprovalResponseDto';
import type { UpdateRequestApprovalDto } from '../models/UpdateRequestApprovalDto';
import { request as __request } from '@openapi/request';

export class ApprovalService {

    /**
     * @param requestBody 
     * @returns ListApprovalResponseDto 
     * @throws ApiError
     */
    public static async getApprovalList(
        requestBody: ListApprovalRequestDto,
        ): Promise<ListApprovalResponseDto> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/approval/list`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns any 
     * @throws ApiError
     */
    public static async updateApproval(
        requestBody: UpdateRequestApprovalDto,
        ): Promise<any> {
        const result = await __request({
            method: 'PUT',
            path: `/web/api/approval/update`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param oid 
     * @returns any 
     * @throws ApiError
     */
    public static async getFile(
        oid: string,
        ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/approval/file/${oid}`,
        });
        return result.body;
    }

    /**
     * @returns FilterOptionsResponseDto 
     * @throws ApiError
     */
    public static async getFilterOptions(): Promise<FilterOptionsResponseDto> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/approval/filter-options`,
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns any 
     * @throws ApiError
     */
    public static async productCsvExport(
        requestBody: CsvExportDto,
        ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/approval/csv-export`,
            body: requestBody,
        });
        return result.body;
    }

    public static async approveForm(
        requestBody: any,
        headers: any,
        ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/efnaApproval/sendForApprovalProcess`,
            body: requestBody,
            headers,
        });
        return result.body;
    }

}