import { IValidation } from '@master/interface/IValidation'
import { ProductMetaList } from '@master/model/common/ProductMetaList'
import { Module, VuexModule, Mutation, Action, MutationAction } from 'vuex-module-decorators'
import axios from 'axios'
import { Assumption } from '@master/model/common/Assumption'
import { infAdjReturn, round } from '@core/model/kyc-form/need-analysis/math'
import { cid, container } from 'inversify-props'
import API from '@core/services/API'
import { FormOptions } from '@master/model/kyc-form/FormOptions'
import '@/DIContainer'
import { ProductHeaders } from '@master/model/common/ProductMeta'
import { IDateService } from '@master/services/IDateService'
import { ProductList } from '@core/pages/adminProductList/model/ProductList'
import { ProductService } from '@core/pages/adminProductList/api'
import { ProductModel } from '@core/pages/adminProductList/model/ProductModel'
import KYCForm from '@company-common/model/kyc-form/KYCForm'
import { IProjectSettings } from '@master/config/IProjectSettings'
import { IRiskRatingService } from '@master/services/IRiskRatingService'
import { KycFormService } from '@core/pages/kyc-form/api/services/KycFormService'
import { CompanyDto, CompanyService } from '@core/pages/companyDetails/api'
import { AccountService } from '@core/openapi/services/user-account/api'
import { AdviserLicenceService } from '@core/openapi/services/adviser-licence/api'
import { ProductLicenceService } from '@core/openapi/services/product-licence/api'
import { MochaConfigService } from '@core/pages/account/api'
import { ProductValidationError } from '@master/model/kyc-form/ILPPortfolioArray'
import { TenantConfigurationResponseDTO } from '@core/openapi/services/tenant-configuration/api/models/TenantConfigurationResponseDTO'
import { DEFAULT_TENANT_CONFIGS } from '@/config/defaultTenantConfigs'

const $api = container.get<API>('$api')
const dateService = container.get<IDateService>(cid.IDateService)
const projectSettings = container.get<IProjectSettings>(cid.IProjectSettings)
const riskRatingService = container.get<IRiskRatingService>(cid.IRiskRatingService)

@Module({
  namespaced: true,
  name: 'KYCFormStore'
})
export default class KYCFormStore extends VuexModule {
  kycForm: KYCForm = new KYCForm(dateService, projectSettings, riskRatingService)
  formOptions: FormOptions
  assumption: Assumption = new Assumption()
  loadingState: 'loading' | 'loaded' | 'saving' = 'loading'
  computedValidationResult: IValidation = {}
  kycFormErrors = {}
  productErrors : Map<string, ProductValidationError> = new Map<string, ProductValidationError>()
  myInfoStatus = {
    haveNewInfoClient1: false,
    haveNewInfoClient2: false
  }
  tenatConfigs = DEFAULT_TENANT_CONFIGS
  accumulatedForms: {}

  selectedClient: 'client1' | 'client2' = 'client1'
  kycFormId: string = ''
  kycFormUtm: number = null;

  isFirstLoad = {
    c1: {
      'Personal Details': false,
      'Trusted Individual': false,
      'Dependants': false,
      'Financial Objectives': false,
      'Inflow & Outflow': false,
      'Assets & Liabilities': false,
      'Existing Insurance': false,
      'Existing Investments': false,
      'Investment Risk Profile': false,
      'Affordability': false,
      // more page names will come here
    },
    c2: {
      'Personal Details': false,
      'Trusted Individual': false,
      'Dependants': false,
      'Financial Objectives': false,
      'Inflow & Outflow': false,
      'Assets & Liabilities': false,
      'Existing Insurance': false,
      'Existing Investments': false,
      'Investment Risk Profile': false,
      'Affordability': false,
    }
  }
  productMetaList: ProductMetaList = new ProductMetaList([])
  productList: ProductList = new ProductList([])
  cdMetaList: CompanyDto[] = []

  // State changes
  showCompletionModalState = false
  showEcddForm = false
  disignPdfUrl = null

  @Mutation
  setKYCForm (kycForm) {
    this.kycForm.setData(kycForm)
    this.formOptions = new FormOptions(this.kycForm)
  }

  @Mutation
  setCashFlow(cashFlowData) {
    this.kycForm.fromCashFlowJSON(cashFlowData)
  }

  @Action
  setAccumulatedForms(forms) {
    this.context.commit('SET_ACCUMULATED_FORMS', forms);
  }

  @Action
  updateAccumulatedForms(formData) {
    this.context.commit('UPDATE_ACCUMULATED_FORMS', formData);
  }

  @Mutation
  SET_ACCUMULATED_FORMS( forms) {
    this.accumulatedForms = forms;
  }

  @Mutation
  UPDATE_ACCUMULATED_FORMS( formData) {
    this.accumulatedForms = {
      ...this.accumulatedForms,
      ...formData,
    };
  }

  get getAccumulatedForms() {
    return this.accumulatedForms
  }

  @Mutation
  setTenantConfig(configs) {
    this.tenatConfigs = configs
  }

  @Mutation
  setSelectedClient(client) {
    this.selectedClient = client
  }

  @Mutation
  setKycFormId(id) {
    this.kycFormId = id
  }

  @Mutation
  setKycFormUtm(value) {
    this.kycFormUtm = value;
  }

  @Mutation
  resetCashFlow(cashFlowData) {
    this.kycForm.resetCashFlow(cashFlowData)
  }

  @Mutation
  setAssets (assetData) {
    this.kycForm.fromAssetLiabilityJSON(assetData)
  }

  @Mutation
  resetAsset (assetData) {
    this.kycForm.resetAssetLiability(assetData)
  }

  @Mutation
  setExistingInvestment(ei) {
    if (!ei) {
      return;
    }
    this.kycForm.client1.existingInvestment.setData(ei.client1)
    this.kycForm.client2.existingInvestment.setData(ei.client2)
  }

  @Mutation
  setFirstLoad(data) {
    this.isFirstLoad = {
      ...this.isFirstLoad,
      ...data
    }
  }

  @Mutation
  setDefaultAssumptions (assumptions) {
    this.kycForm.setupDefaultRate(assumptions)
  }

  @Mutation
  setKYCFormErrors (errors: {}) {
    this.kycFormErrors = errors
  }

  @Mutation
  setProductErrors (obj: ProductValidationError) {
    this.productErrors.set(obj.type, obj)
  }

  @Mutation
  removeProductErrors(type: string) {
    if (this.productErrors.has(type)) {
        this.productErrors.delete(type);
    } else {
        // console.log(`No object found with type ${type}`);
    }
  }

  @Mutation
  setAssumption (assumption: {
    assumptions_annualinflation;
    assumptions_annualinvestmentreturn;
    assumptions_increaseineducationcosts;
    assumptions_annualsalaryincrement;
    assumptions_retirementincome;
  }) {
    const {
      assumptions_annualinflation, // eslint-disable-line @typescript-eslint/camelcase
      assumptions_annualinvestmentreturn, // eslint-disable-line @typescript-eslint/camelcase
      assumptions_increaseineducationcosts, // eslint-disable-line @typescript-eslint/camelcase
      assumptions_annualsalaryincrement, // eslint-disable-line @typescript-eslint/camelcase
      assumptions_retirementincome // eslint-disable-line @typescript-eslint/camelcase
    } = assumption
    const inflation = parseFloat(assumptions_annualinflation)
    const investmentReturn = parseFloat(assumptions_annualinvestmentreturn)
    this.assumption = {
      inflation,
      investmentReturn,
      educationInflationRate: parseFloat(assumptions_increaseineducationcosts),
      salaryIncrementRate: parseFloat(assumptions_annualsalaryincrement),
      retirementIncome: parseFloat(assumptions_retirementincome),
      inflationAdjustedReturn: round(infAdjReturn(inflation / 100, investmentReturn / 100) * 100, 2)
    }
    this.kycForm.setupDefaultRate(this.assumption)
  }

  @Mutation
  setLoadingState (state: 'loading' | 'loaded' | 'saving') {
    this.loadingState = state
  }

  @Mutation
  setShowCompletionModalState (state: boolean) {
    this.showCompletionModalState = state
  }

  @Mutation
  setShowEcddForm (state: boolean) {
    this.showEcddForm = state
  }

  @Mutation
  setValidationResult (result: IValidation) {
    this.computedValidationResult = result
  }

  @Mutation
  myInfoAssign({data, index}: { data: any, index: number }) {
    // Myinfo possible options: https://public.cloud.myinfo.gov.sg/myinfo/api/myinfo-kyc-v3.2.html#operation/getperson
    const genderCodeMapping = {
      'M': 'male',
      'F': 'female',
      'U': '',
    };
    const residentialStatusCodeMapping = {
      'A': 'others', // 'A' === 'alien' => 'others'
      'C': 'singaporean', // 'C' === 'citizen' => 'singaporean'
      'P': 'pr',
      'U': 'others', // 'U' === 'unknown' => 'others'
      'N': 'others', // 'N' === 'not_applicable' => 'others'
    };
    const maritalCode = {
      '1': 'single',
      '2': 'married',
      '3': 'widowed',
      '5': 'divorced',
    };
    const raceCodeMapping = {
      'CHINESE': 'chinese',
      'MALAY': 'malay',
      'INDIAN': 'indian',
      'EURASIAN': 'eurasian',
    };

    const identifyType = (value) => {
      if (/^[ST]/.test(value)) {
        return "nric";
      } else if (/^[A-Z]/.test(value)) {
        return "fin";
      } else {
        return "";
      }
    }

    if(index === 1) {
      this.kycForm.client1.personalDetails.identityType = identifyType(data.uinfin?.value);
      this.kycForm.client1.personalDetails.nric = data.uinfin?.value;
      this.kycForm.client1.personalDetails.name = data.name?.value;
      this.kycForm.client1.personalDetails.gender = data.sex?.code ? genderCodeMapping[data.sex?.code] : '';
      this.kycForm.client1.personalDetails.race = data.race?.desc ? raceCodeMapping[data.race?.desc] ?? 'others' : '';
      this.kycForm.client1.personalDetails.citizenship = data.residentialstatus?.code ? residentialStatusCodeMapping[data.residentialstatus?.code] : '';
      this.kycForm.client1.personalDetails.citizenshipSpecified = data.nationality?.code;
      
      const date = new Date(data.dob?.value);      
      this.kycForm.client1.personalDetails.dob = date.getTime();

      this.kycForm.client1.personalDetails.countryOfBirth = data.birthcountry?.code;
      this.kycForm.client1.personalDetails.residentialCountry = data.regadd?.country?.code;
      this.kycForm.client1.personalDetails.blockNumber = data.regadd?.block?.value;
      this.kycForm.client1.personalDetails.streetName = data.regadd?.street?.value;
      this.kycForm.client1.personalDetails.postalCode = data.regadd?.postal?.value;
      this.kycForm.client1.personalDetails.unitNumber = data.regadd?.floor?.value ? `${data.regadd?.floor?.value} - ${data.regadd?.unit?.value}` : data.regadd?.unit?.value;
      this.kycForm.client1.personalDetails.buildingName = data.regadd?.building?.value;
      this.kycForm.client1.personalDetails.isLandedProperty = !!data.regadd?.unit?.value ? 'no' : 'yes';
      this.kycForm.client1.personalDetails.maritalStatus = data.marital?.code ? maritalCode[data.marital?.code] : '';
      this.kycForm.client1.personalDetails.companyName = data.employment?.value;
      this.kycForm.client1.personalDetails.employmentStatus = data.employment?.validationResult;
      this.kycForm.client1.personalDetails.contactHomeCountryCode = data.mobileno?.areacode?.value ? `+${data.mobileno?.areacode?.value}` : '';
      this.kycForm.client1.personalDetails.contactMobile = data.mobileno?.nbr?.value;
      this.kycForm.client1.personalDetails.contactEmail = data.email?.value;

      this.kycForm.is_client_one_my_info_linked = true;
      this.kycForm.client1.myInfo.infoLinked = true;
    } else if (index === 2) {
      this.kycForm.client2.personalDetails.identityType = identifyType(data.uinfin?.value);
      this.kycForm.client2.personalDetails.nric = data.uinfin?.value;
      this.kycForm.client2.personalDetails.name = data.name?.value;
      this.kycForm.client2.personalDetails.gender = data.sex?.code ? genderCodeMapping[data.sex?.code] : '';
      this.kycForm.client2.personalDetails.race = data.race?.desc ? raceCodeMapping[data.race?.desc] ?? 'others' : '';
      this.kycForm.client2.personalDetails.citizenship = data.residentialstatus?.code ? residentialStatusCodeMapping[data.residentialstatus?.code] : '';
      this.kycForm.client2.personalDetails.citizenshipSpecified = data.nationality?.code;
      const date = new Date(data.dob?.value);  
      this.kycForm.client2.personalDetails.dob = date.getTime();
      this.kycForm.client2.personalDetails.countryOfBirth = data.birthcountry?.code;
      this.kycForm.client2.personalDetails.residentialCountry = data.regadd?.country?.code;
      this.kycForm.client2.personalDetails.blockNumber = data.regadd?.block?.value;
      this.kycForm.client2.personalDetails.streetName = data.regadd?.street?.value;
      this.kycForm.client2.personalDetails.postalCode = data.regadd?.postal?.value;
      this.kycForm.client2.personalDetails.unitNumber = data.regadd?.floor?.value ? `${data.regadd?.floor?.value} - ${data.regadd?.unit?.value}` : data.regadd?.unit?.value;
      this.kycForm.client2.personalDetails.buildingName = data.regadd?.building?.value;
      this.kycForm.client2.personalDetails.isLandedProperty = !!data.regadd?.unit?.value ? 'no' : 'yes';
      this.kycForm.client2.personalDetails.maritalStatus = data.marital?.code ? maritalCode[data.marital?.code] : '';
      this.kycForm.client2.personalDetails.companyName = data.employment?.value;
      this.kycForm.client2.personalDetails.employmentStatus = data.employment?.validationResult;
      this.kycForm.client2.personalDetails.contactHomeCountryCode = data.mobileno?.areacode?.value ? `+${data.mobileno?.areacode?.value}` : '';
      this.kycForm.client2.personalDetails.contactMobile = data.mobileno?.nbr?.value;
      this.kycForm.client2.personalDetails.contactEmail = data.email?.value;

      this.kycForm.is_client_two_my_info_linked = true
      this.kycForm.client2.myInfo.infoLinked = true
    }
  }

  @Mutation
  setProductMetaList (data: (string | number)[][]) {
    const productMetaList = new ProductMetaList(data)
    // Override filter when needed
    // e.g. productMetaList.filter.ilp.type = ['abc']
    this.productMetaList = productMetaList
  }

  @Mutation
  setProductList (data: ProductModel[]) {
    const productList = new ProductList(data)
    // Override filter when needed
    // e.g. productMetaList.filter.ilp.type = ['abc']
    this.productList = productList
  }

  get products() {
    return this.productList
  }

  @Mutation
  setCompanyDetailList (data: CompanyDto[]) {
    this.cdMetaList = data
  }

  @Mutation
  setExistingInsurance(insuranceData) {
    this.kycForm.client1.existingInsurence.setData(insuranceData.client1)
    this.kycForm.client2.existingInsurence.setData(insuranceData.client2)
  }

  @Mutation
  setDisignPdfUrl(value) {
    this.disignPdfUrl = value;
  }

  get metaCDList () {
    return this.cdMetaList
  }

  @Action
  updateFirstLoad (routeName) {
    const currentClient = localStorage.getItem('selectedClient') || 'client1'
    let value
      switch (routeName) {
        case 'Personal Details':
          // if myinfo is linked then false else if manual reason value is undefined then true
            const isLinked = currentClient === 'client1' ? this.kycForm.is_client_one_my_info_linked : this.kycForm.is_client_two_my_info_linked;
            const manualReason = currentClient === 'client1' ? this.kycForm.manual_reason_c_1 : this.kycForm.manual_reason_c_2;

            value = isLinked !== undefined ? false : manualReason === undefined;
          // if(currentClient === 'client1'){
          //   if (this.kycForm.is_client_one_my_info_linked !== undefined){
          //     value = false
          //   } else if (this.kycForm.manual_reason_c_1 === undefined) {
          //     value = true
          //   }
          // } else if (currentClient === 'client2') {
          //   if (this.kycForm.is_client_two_my_info_linked !== undefined) {
          //     value = false
          //   } else if (this.kycForm.manual_reason_c_2 === undefined) {
          //     value = true
          //   }
          // }
      }


    const finalOb = {
      c1: {
        ...this.isFirstLoad.c1,
        ...(currentClient === 'client1' && { [routeName]: value })
      },
      c2: {
        ...this.isFirstLoad.c2,
        ...(currentClient === 'client2' && { [routeName]: value })
      }
    };
    this.context.commit('setFirstLoad', finalOb)
  }

  @Action
  updateShowCompletionModalState (state: boolean) {
    this.context.commit('setShowCompletionModalState', state)
  }

  @Action
  updateSelectedClient(client: 'client1' | 'client2') {
    this.context.commit('setSelectedClient', client)
  }

  @Action
  updateShowEcddForm (state: boolean) {
    this.context.commit('setShowEcddForm', state)
  }

  @Action
  updateTenantConfigs (configs: TenantConfigurationResponseDTO) {
    this.context.commit('setTenantConfig', configs)
  }

  @Action
  updateCashFlow (cashflow: any) {
    this.context.commit('setCashFlow', cashflow)
  }

  @Action
  resetCashFlows (assets: any) {
    this.context.commit('resetCashFlow', assets)
  }

  @Action
  updateAssets (assets: any) {
    this.context.commit('setAssets', assets)
  }

  @Action
  resetAssets (assets: any) {
    this.context.commit('resetAsset', assets)
  }

  @Action
  updateExistingInsurance (data: any) {
    this.context.commit('setExistingInsurance', data)
  }

  @Action
  updateExistingInvestment (ei: any) {
    this.context.commit('setExistingInvestment', ei)
  }

  @Action
  addInvestments (ei: any) {
    this.context.commit('addInvestments', ei)
  }

  @Action
  updateInvestments (ei: any) {
    this.context.commit('setInvestment', ei)
  }

  @Action
  deleteInvestments (ei: any) {
    this.context.commit('deleteInvestment', ei)
  }

  @Action
  updatedValidationResult (result: {}) {
    this.context.commit('setValidationResult', result)
  }

  @Action
  updateMyinfoFields(payload: { data: any, index: number }) {
    this.context.commit('myInfoAssign', payload)
  }

  // add rawError to get the raw error during this action to get better knowing where the issue come from
  @Action({ rawError: true })
  async loadKYCForm (formOid: string) {
    this.context.commit('setLoadingState', 'loading')
    const { meta: kycForm, error } = await $api.kycForm.metaGet({ _oid: formOid })
    // const res = await KycFormService.getKycFormList(formOid)

    // To ensure Important Notice product list always has latest data
    const adviserData = await $api.adviserDetails.getAccountMeta({ _oid: kycForm.adviser_oid })
    // const adviserData = await $api.adviserDetails.getAccountMeta({ _oid: res.adviser_oid })
      .then((response) => {
        return response.meta
      })
      .catch((error) => {
        alert('KYCForm Store: Fail to get adviser detail' + error)
      })

    kycForm.important_notice_list = adviserData?.adviser_products // eslint-disable-line @typescript-eslint/camelcase
    kycForm.important_notice_others = adviserData?.other_adviser_products // eslint-disable-line @typescript-eslint/camelcase
    kycForm.adviser_mas_rep_no = adviserData?.mas_repno // eslint-disable-line @typescript-eslint/camelcase

    if (error) {
      this.context.commit('setKYCFormErrors', error)
      this.context.commit('setLoadingState', 'loaded')
      return kycForm
    }
    //
    // pre processor that will change all the ma to the standardize format
    //

    this.context.commit('setKYCForm', kycForm)
    // this.context.commit('setKYCForm', res)
    this.context.commit('setLoadingState', 'loaded')
  }

  @Action({ rawError: true })
  async loadKYCFormV2 (formOid: string) {
    this.context.commit('setLoadingState', 'loading')
    const { meta: kycForm, error, case_no, utm } = await KycFormService.getKycForm(formOid)
    // To ensure Important Notice product list always has latest data
    const adviserProducts = await AdviserLicenceService.retrieveSpecificUserAdviserLicences(kycForm.adviser_oid)
    const systemLicences = await ProductLicenceService.listSystemAdviserLicences()
    const adviserInfo = await AccountService.retrieveUserAccountInformation(kycForm.adviser_oid ||kycForm._oid)
    // const adviserData = await $api.adviserDetails.getAccountMeta({ _oid: kycForm.adviser_oid })
    //   .then((response) => {
    //     return response.meta
    //   })
    //   .catch((error) => {
    //     console.error('KYCForm Store: Fail to get adviser detail', error)
    //     alert('KYCForm Store: Fail to get adviser detail' + error)
    //   })
    const products = adviserProducts.filter(product => product.status === 'active').map(product => product.productLicence)
    const names = systemLicences.filter(sysLicence => products.includes(sysLicence.oid)).map(licence => licence.licenceName)
    // const res =  await KycFormService.getFinancialObjective(formOid)
    // if(res?.client1)kycForm.client1FinancialObjectives=res.client1
    // if(res?.client2)kycForm.client2FinancialObjectives=res.client2
    kycForm.important_notice_list = names // eslint-disable-line @typescript-eslint/camelcase
    // kycForm.important_notice_others = adviserData?.other_adviser_products // eslint-disable-line @typescript-eslint/camelcase
    kycForm.adviser_mas_rep_no = adviserInfo?.userCompanyInfo?.masRepNo // eslint-disable-line @typescript-eslint/camelcase

    if (error) {
      this.context.commit('setKYCFormErrors', error)
      this.context.commit('setLoadingState', 'loaded')
      return kycForm
    }
    //
    // pre processor that will change all the ma to the standardize format
    //

    this.context.commit('setKYCForm', kycForm)
    this.context.commit('setKycFormId', case_no)
    this.context.commit('setKycFormUtm', utm)
    this.context.commit('setLoadingState', 'loaded')
  }

  @Action
  async loadAssumption () {
    const assumptionNames = [
      'assumptions_annualinflation',
      'assumptions_annualinvestmentreturn',
      'assumptions_increaseineducationcosts',
      'assumptions_annualsalaryincrement',
      'assumptions_retirementincome'
    ]

    try {
      const assumptionsKeys = {}
      assumptionNames.forEach(async key => {
        const response = await MochaConfigService.retrieveConfigValue(key)
        assumptionsKeys[response.kid] = response.kvl
        this.context.commit('setAssumption', assumptionsKeys)
      })
    } finally {
      this.context.commit('setDefaultAssumptions', this.assumption)
    }
    // const { data: { values: assumption } } = await axios.get(`/web/api/kvmApi/getValues?keys=${encodeURIComponent(JSON.stringify(assumptionNames))}`)
    // this.context.commit('setAssumption', assumption)
  }

  @MutationAction({ mutate: ['myInfoStatus'] })
  async checkMyInfoUpdate (kycFormOid) {
    /* eslint-disable @typescript-eslint/camelcase */
    const { data } = await $api.myInfo.newPersonInfoAvailable({ kycFormOid })

    const client0Available = data?.client_0?.new_person_info_available || false
    const client1Available = data?.client_1?.new_person_info_available || false

    const myInfoStatus = {
      haveNewInfoClient1: client0Available,
      haveNewInfoClient2: client1Available
    }
    /* eslint-enable @typescript-eslint/camelcase */
    return { myInfoStatus }
  }

  @Action({ rawError: true })
  async fetchRecommendedProducts () {
    if (this.productMetaList.getFullList()?.length > 0) return
    this.context.commit('setLoadingState', 'saving')

    const res = await ProductService.retrieveAllProducts({
      status: [1]
    })

    const { data } = res

    // const { data } = await $api.relProductList.list({
    //   headers: JSON.stringify(ProductHeaders),
    //   // only return active products
    //   query: 'status = ?',
    //   queryArgs: JSON.stringify([1])
    // })

    this.context.commit('setLoadingState', 'loaded')
    this.context.commit('setProductMetaList', data)
  }

  @Action({ rawError: true })
  async fetchEfnaRecommendedProducts () {
    if (this.productMetaList.getFullList()?.length > 0) return
    this.context.commit('setLoadingState', 'saving')

    const res = await ProductService.efnaRetrieveAllProducts({
      status: [1]
    })

    const { data } = res

    this.context.commit('setLoadingState', 'loaded')
    this.context.commit('setProductMetaList', data)
  }

  // @Action({ rawError: true })
  // async fetchRecommendedAllProducts () {
  //   if (this.productList.originalList.length > 0) return
  //   const products = await ProductService.retrieveAllProducts({
  //     status: [1]
  //   })

  //   const { data } = products // hanges service file and  added any data type

  //   const updatedProducts = data.map(product => {
  //     return Object.assign(new ProductModel(), product) //{data: [{}], totalRecord:sd}
  //   })

  //   this.context.commit('setProductList', updatedProducts)
  // }

  @Action
    async fetchRecommendedAllProducts({status = [1], limit = 10, retrieveAll = false} = {}) {
    if (this.productList.originalList?.length > 0) return
    const res: any = (await ProductService.retrieveAllProducts({
      status,
      limit,
      retrieveAll
    }))
    const products = res.data.map(product => {
      return Object.assign(new ProductModel(), product)
    })
    this.context.commit('setProductList', products)
  }

  @Action({ rawError: true })
  async fetchCompanyDetails () {
    if(!this.metaCDList?.length){
      const data = await CompanyService.retrieveCompanyList()
      this.context.commit('setCompanyDetailList', data)
    }
  }

  @Action
  updateProdctErrors (error: ProductValidationError) {
    this.context.commit('setProductErrors', error)
  }

  @Action
  updateDisignPdfUrl (url: string) {
    this.context.commit('setDisignPdfUrl', url)
  }

  get productAllErrors() : ProductValidationError[] {
    return Array.from(this.productErrors.values());
  }

  get updateLoadingState () {
    return this.loadingState
  }

  get issuerOptions () {
    const uniqueObjArray = [...new Map(this.cdMetaList.map((item) => [item.oid, { label: item.shortName, value: item.oid }])).values()]
    uniqueObjArray.unshift({
      label: '',
      value: ''
    })
    return uniqueObjArray
  }

  get isSectionErrorsExists() {
    const currentClient = this.selectedClient || 'client1'
    const validationErrors = this.computedValidationResult;
    const sectionNames = [
      'Important Notices', 
      'Personal Details',
      'Trusted Individual',
      'Dependants',
      'Financial Objectives',
      'Inflow & Outflow',
      'Assets & Liabilities',
      'Existing Investments',
      'Existing Insurance',
      'Investment Risk Profile',
      'Customer Knowledge Assessment',
      'Needs Analysis',
      'Affordability',
      'AdditionalForms'
      ];
    return sectionNames.some(key => key in validationErrors && currentClient in validationErrors[key]);
  }
}
