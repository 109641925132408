import { CreateGroupDto } from "@core/openapi/services/efna-configuration/api/models/CreateGroupDto";
import { EfnaConfigurationService } from "@core/openapi/services/efna-configuration/api/services/EfnaConfigurationService";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { GroupDetailsDTO } from "../dtos/GroupDetailsDTO";

@Module({
  namespaced: true,
  name: "GroupStore",
})
export default class GroupStore extends VuexModule {
  groupDetails: CreateGroupDto = new GroupDetailsDTO();
  allUserList = [];
  disabledCreateButton?: boolean = true
  accessWarning?: any = null
  allAccessViews?: any = []
  userSelectedData?: any = []
  oldAccessViewOids?: string[] = []
  backendCheckerOptions?: any = []
  selectedBackendChecker?: string

  @Mutation
  clearGroupStore() {
    this.groupDetails = new GroupDetailsDTO();
    this.allUserList = [];
    this.disabledCreateButton = true
    this.accessWarning = null
    this.allAccessViews = []
    this.userSelectedData = []
    this.oldAccessViewOids = []
    this.backendCheckerOptions = []
  }

  @Mutation
  setGroupDetails(result) {
    this.groupDetails = result;
  }

  @Mutation
  setAccessWarning(result) {
    this.disabledCreateButton = result;
  }

  @Mutation
  setDisabledCreateButton() {
    if (this.groupDetails.accessViews?.length > 0 && 
    !this.accessWarning && this.groupDetails.name && 
    this.groupDetails.userOids.length > 0) {
      this.disabledCreateButton = false;
    } else {
      this.disabledCreateButton = true;
    } 
  }

  @Action
  async getAllUsers(params) {
    // For future use
    // const {fullname, systemRole } = params;
    try {
      const result = await EfnaConfigurationService.getUsersWithFilters();
      this.context.commit("setAllUserList", result);
    } catch (error) {
      alert(error.message);
    }
  }

  @Mutation
  setAllUserList(result) {
    this.allUserList = result?.data;
  }

  @Action
  async checkAccessViewsCombination(params) {
    const {accessIds, groupId } = params;
    if (JSON.stringify(accessIds) === JSON.stringify(this.oldAccessViewOids) && this.accessWarning) {
      this.context.commit("setCheckAccessViewsCombination", null);
      return false
    }
    try {
      const result = await EfnaConfigurationService.checkAccessViewsCombination({accessIds, groupId});
      this.context.commit("setCheckAccessViewsCombination", result?.group);
    } catch (error) {
      console.log(error, "error")      
    }
  }

  @Mutation
  setCheckAccessViewsCombination(result) {
    if (result) {
      const warn = {
        title: `This access already exists in ${result.groupName}.`,
        msg: "Please edit the selected access to proceed with your group creation."
      }
      this.accessWarning = warn;
    } else {
      this.accessWarning = null;
    }
    
  }

  @Action
  async getAllAccessViews() {
    try {
      const result = await EfnaConfigurationService.getAllAccessViews();
      this.context.commit("setAllAccessViews", result);
    } catch (error) {
      alert(error.message);      
    }
  }

  @Mutation
  setAllAccessViews(result) {
    console.log(result)
    this.allAccessViews = result;
  }

  @Action
  async getGroupByOid(oid) {
    try {
      const result = await EfnaConfigurationService.getGroupById(oid);
      this.context.commit("setEditGroupDetails", result);
      return true
    } catch (error) {
      console.log(error.message)    
    }
  }

  @Mutation
  setEditGroupDetails(result) {
    const {name, accesses, users} = result
    const accessOids = accesses.map(({oid})=>oid)
    const newdata: CreateGroupDto = {
      name,
      accessViews: accesses.map(({oid})=>oid),
      userOids: users.map(({oid})=>oid)
    }

    const mapedUsers = users.map(user=>{
      return {
        fullname: user.userPersonalInfo.fullname,
        companyCode: user.companyCode,
        role: user.systemRole,
        ctm: user.joinedAt,
        userId: user.oid
      }
    })

    this.groupDetails = newdata;
    this.userSelectedData = mapedUsers;
    this.oldAccessViewOids = accessOids
  }

  @Action
  async deleteGroup(oid) {
    try {
      const result = await EfnaConfigurationService.deleteGroup(oid);
      return true
    } catch (error) {
      alert(error.message);  
      return false    
    }
  }

  @Action
  async getBackendCheckerOptions() {
    try {
      const result = await EfnaConfigurationService.getBackendCheckerOptions();
      this.context.commit("setBackendCheckerOptions", result);
    } catch (error) {
      alert(error.message);      
    }
  }

  @Mutation
  setBackendCheckerOptions(result) {
    this.backendCheckerOptions = result;
  }

  @Action
  async getSelectedBackendChecker() {
    try {
      const result = await EfnaConfigurationService.getSelectedBackendChecker();
      this.context.commit("setSelectedBackendChecker", result);
    } catch (error) {
      alert(error.message);      
    }
  }

  @Mutation
  setSelectedBackendChecker(result) {
    this.selectedBackendChecker = result;
  }

}
