import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  style: {"z-index":"999"},
  class: "change_color"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppDynamicHeader = _resolveComponent("AppDynamicHeader")!
  const _component_AppDynamicIconHeader = _resolveComponent("AppDynamicIconHeader")!

  return (_ctx.isShowHeader)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (!_ctx.isModernTheme)
          ? (_openBlock(), _createBlock(_component_AppDynamicHeader, {
              key: 0,
              onLogout: _ctx.logout,
              header: _ctx.headerStructure
            }, null, 8, ["onLogout", "header"]))
          : (_openBlock(), _createBlock(_component_AppDynamicIconHeader, {
              key: 1,
              mini: true,
              onLogout: _ctx.logout,
              header: _ctx.headerStructure
            }, null, 8, ["onLogout", "header"]))
      ]))
    : _createCommentVNode("", true)
}