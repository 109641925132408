import { pv, sum, valueIsUndefined,infAdjReturn, difference} from '@master/model/kyc-form/need-analysis/math'
import { IProjectSettings } from '@master/config/IProjectSettings'
import { isUndefined } from 'lodash/fp'
import { Ignore } from '@master/annotation/Ignore'
import NeedsBase from '@master/model/kyc-form/need-analysis/NeedsBase'
import { Global, NA_ERROR_MSG } from '../utils/constants'
import KYCClient from '@company-common/model/kyc-form/KYCClient'

export default class EventOfDisabilityModel extends NeedsBase {
  @Ignore()
  projectSettings: IProjectSettings

  @Ignore()
  selectedClient?:  KYCClient = undefined

  isProtection = true
  medicalExpenses?: number = undefined
  // Expenses section
  expensesOthersDesc: string = undefined
  expensesOthersAmt?: number = undefined
  exTotalPermanentDisability?: number = undefined
  cachedExTotalPermanentDisability?: number = undefined
  income?: number = undefined
  // Income section
  incomeMonthly?: number = undefined
  cachedIncomeMonthly?: number = undefined
  incomeNoOfYears?: number = undefined
  cachedIncomeNoOfYears?: number = undefined
  incomeInvRate?: number = undefined
  cachedIncomeInvRate?: number = undefined
  incomeAssumedInfRate?: number = undefined
  cachedIncomeAssumedInfRate?: number = undefined
  // Existing Resources Allocated section
  exResourceAllocatedDesc?: string = undefined
  exResourceAllocatedAmt?: number = undefined

  // additional information
  additionalNotes: string = ''
  annualIncome: number = undefined
  fundsRequired: number = undefined
  sumRequired: number = undefined
  totalRequired: number = undefined
  sumAvailable: number = undefined
  surplusShortfall: number = undefined

  // Reasons
  incomeInvRateReason?: string = undefined
  incomeAssumedInfRateReason?: string = undefined
  exTotalPermanentDisabilityReason?: string = undefined
  incomeNoOfYearsReason?: string = undefined
  incomeMonthlyReason?: string = undefined

  yearsRequired?: number = undefined
  infAdjReturn?: number = undefined

  cachedIncomeInfAdjRate?: number = undefined
  incomeInfAdjRateReason?: string = undefined

  // confirm review changes
  incomeInvRateCR?:boolean = undefined
  incomeAssumedInfRateCR?:boolean = undefined
  exTotalPermanentDisabilityCR?:boolean = undefined
  incomeNoOfYearsCR?:boolean = undefined
  incomeMonthlyCR?:boolean = undefined

  constructor (type: 'client_0' | 'client_1' = 'client_0', projectSettings?: IProjectSettings, id = 'p1') {
    super(`${type}_ed_${id}`)
    this.projectSettings = projectSettings
  }

  get incomeAnnual () {
    if (valueIsUndefined(this.incomeMonthly)) return undefined
    return (12 * this.incomeMonthly) || 0
  }

  get incomeInfAdjRate(){
    if (valueIsUndefined(this.incomeInvRate) && valueIsUndefined(this.incomeAssumedInfRate)) return undefined
    return parseFloat(infAdjReturn(this.incomeInvRate ,this.incomeAssumedInfRate)?.toFixed(2)) || 0;
  }

  get incomeInfAdjRateReasonStatus() {
    let isNeedReason = false
    if (this.incomeInfAdjRate < -2 || this.incomeInfAdjRate > 9 ) {
      isNeedReason = true
      if (this.cachedIncomeInfAdjRate !== this.incomeInfAdjRate) {
        this.incomeInfAdjRateReason = undefined
        this.cachedIncomeInfAdjRate = this.incomeInfAdjRate
      }
    } else {
      this.incomeInfAdjRateReason = undefined
      this.cachedIncomeInfAdjRate = this.incomeInfAdjRate
    }

    return isNeedReason
  }

  // PV(B10(incomeInfAdjRate),B7(incomeNoOfYears),B6(incomeAnnual),0,1)
  get incomeTotal() {
    if (valueIsUndefined(this.incomeAnnual) && valueIsUndefined(this.incomeNoOfYears)) return null
    const total = pv(this.incomeAnnual, this.incomeNoOfYears, this.incomeInfAdjRate)
    return parseFloat(total.toFixed(2)) 
  }

  // sum of (B23(LIC) + B24(cash assets) + B25 (cpf)+ B26(investment) + B28 (others))
  get exResourcesTotal() {
    const total = sum(this.exTotalPermanentDisability, this.exResourceAllocatedAmt) || 0
    return parseFloat(total.toFixed(2))
  }

  get grandTotal () {
    // const total = ((sum(this.incomeTotal, this.exResourcesTotal) || 0) - this.expensesTotal) || 0
    const totalPv = (sum(this.incomeTotal, this.expensesTotal));
    const total = (difference(this.exResourcesTotal, totalPv)) || 0
    //const total = (this.exResourcesTotal - sum(this.incomeTotal, this.expensesTotal)) || 0

    return parseFloat(total.toFixed(2))
  }

  get expensesTotal() {
    const total = sum(this.medicalExpenses, this.expensesOthersAmt) || 0
    return parseFloat(total.toFixed(2))
  }

  get incomeInvRateReasonStatus () {
    let isNeedReason = false

    if (this.incomeInvRate < 0 || this.incomeInvRate > Global.MAX_INTEREST_RATE) {
      isNeedReason = true
      if (this.cachedIncomeInvRate !== this.incomeInvRate) {
        this.cachedIncomeInvRate = this.incomeInvRate
        this.incomeInvRateCR = false
      }
    } else {
      this.incomeInvRateReason = undefined
      this.cachedIncomeInvRate = this.incomeInvRate
      this.incomeInvRateCR = false
    }
    return isNeedReason
  }

  get incomeAssumedInfRateReasonStatus(){
    let isNeedReason = false
    if (this.incomeAssumedInfRate < 0 || this.incomeAssumedInfRate > Global.MAX_INTEREST_RATE) {
      isNeedReason = true
      if (this.cachedIncomeAssumedInfRate !== this.incomeAssumedInfRate) {
        this.cachedIncomeAssumedInfRate = this.incomeAssumedInfRate
        this.incomeAssumedInfRateCR = false
      }
    } else {
      this.incomeAssumedInfRateReason = undefined
      this.cachedIncomeAssumedInfRate = this.incomeAssumedInfRate
      this.incomeAssumedInfRateCR = false
    }
    return isNeedReason
  }

  get incomeNoOfYearsReasonStatus(){
    if (!this.selectedClient) return
    let isNeedReason = false
    let yearsRequired = null
    const selectedClientByPersonalDetails= this.selectedClient?.personalDetails

    // Recalculate no years required whenever life expectancy changes
    const lifeExpectancy = selectedClientByPersonalDetails?.gender === 'male' ? Global.LIFE_EXPECT_MALE : Global.LIFE_EXPECT_FEMALE;
    if (selectedClientByPersonalDetails?.dob && lifeExpectancy) {
      // Extract the year from the date of birth
      const birthYear = new Date(selectedClientByPersonalDetails?.dob).getFullYear();
      // Get the current year
      const currentYear = new Date().getFullYear();
      // Calculate the client's age
      const age = currentYear - birthYear;
      // Calculate the number of years required
      yearsRequired = lifeExpectancy - age;
    }

    if (this.incomeNoOfYears > yearsRequired){
      isNeedReason = true
      if (this.incomeNoOfYears !== this.cachedIncomeNoOfYears) {
        this.cachedIncomeNoOfYears = this.incomeNoOfYears
        this.incomeNoOfYearsCR = false
      }
    } else {
      this.incomeNoOfYearsReason = undefined
      this.cachedIncomeNoOfYears = this.incomeNoOfYears
      this.incomeNoOfYearsCR = false
    }
    return isNeedReason
  }

  get totalExInsuranceForTPD(){
    if (!this.selectedClient) return
    const selectedClientByExInsurance = this.selectedClient?.existingInsurence;
    let calcTotal = 0;

    if (selectedClientByExInsurance?.insuranceRecords) {
      calcTotal += selectedClientByExInsurance.insuranceRecords.reduce((sum, record) => sum + (record.sumAssuredTPD || 0), 0);
    }
    return calcTotal
  }

  get exTotalPermanentDisabilityReasonStatus(){
    if (!this.selectedClient) return
    let isNeedReason = false
    // Sum sumAssuredTPD in client's insuranceRecords
    let calcTotal = this.totalExInsuranceForTPD

    if (this.exTotalPermanentDisability < calcTotal) {
      isNeedReason = true
      if (this.exTotalPermanentDisability !== this.cachedExTotalPermanentDisability) {
        this.exTotalPermanentDisabilityCR = false
        this.cachedExTotalPermanentDisability = this.exTotalPermanentDisability
      }
    } else {
      this.exTotalPermanentDisabilityReason = undefined
      this.cachedExTotalPermanentDisability = this.exTotalPermanentDisability
      this.exTotalPermanentDisabilityCR = false
    }
    return isNeedReason
  }

  get exTotalPermanentDisabilityError(){
    if (!this.selectedClient) return
    // Sum sumAssuredTPD in client's insuranceRecords
    let calcTotal = this.totalExInsuranceForTPD

    if (this.exTotalPermanentDisability > calcTotal) {
      if (this.exTotalPermanentDisability !== this.cachedExTotalPermanentDisability) {
        this.cachedExTotalPermanentDisability = this.exTotalPermanentDisability
      }
      return NA_ERROR_MSG.TB
    } else {
      return null
    }
  }

  get incomeMonthlyReasonStatus(){
    if (!this.selectedClient) return
    let isNeedReason = false
    const selectedClientByCashFlow = this.selectedClient?.cashFlow;
    let monthly_amount = 0
    if (selectedClientByCashFlow?.isSimplifiedMode) {
      // Simplified mode
      // Check 'Total Monthly Inflow' field and check 'Total Monthly Outflow' and take the field that has higher amount.
      monthly_amount = Math.max(selectedClientByCashFlow?.simplifiedTotalInflow||0, selectedClientByCashFlow?.simplifiedTotalOutflow||0)
    } else {
      // Comprehensive mode
      // Check 'Net Employment Income' field and check 'Total Monthly Outflow' and take the field that has higher amount
      const totalMonthlyOutflow = selectedClientByCashFlow?.totalMonthlyOutflow || 0
      monthly_amount = Math.max(selectedClientByCashFlow?.netEmploymentIncome||0, totalMonthlyOutflow)
    }

    if (this.incomeMonthly > monthly_amount) {
      isNeedReason = true
      if (this.incomeMonthly !== this.cachedIncomeMonthly) {
        this.cachedIncomeMonthly = this.incomeMonthly
        this.incomeMonthlyCR = false
      }
    } else {
      this.incomeMonthlyReason = undefined
      this.cachedIncomeMonthly = this.incomeMonthly
      this.incomeMonthlyCR = false
    }
    return isNeedReason
  }

  get isCompleted () {
    let clearIncomeMandatory = this.incomeMonthly != null || this.incomeNoOfYears != null || this.incomeInvRate != null
    if (clearIncomeMandatory) {
      const aif = this.incomeMonthly != null && this.incomeNoOfYears != null && this.incomeInvRate != null && this.incomeAssumedInfRate != null
      clearIncomeMandatory = aif != null
    }
    if (this.expensesOthersDesc) {
      clearIncomeMandatory = this.expensesOthersAmt != null
    }
    if (this.exResourceAllocatedDesc) {
      clearIncomeMandatory = this.exResourceAllocatedAmt != null
    }
    return clearIncomeMandatory
  }


  set setSelectedClient (client) {
    this.selectedClient = client
  }

  get isDefaultRate () {
    return this.defaultRate === this.infAdjReturn
  }

  resetAllFields (): void {
    super.resetAllFields()
    this.isProtection = true
  }
}
