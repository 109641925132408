import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { KycPdfV2Service } from '@core/openapi/services/kyc-pdf-v2/api'

@Module({
    namespaced: true,
    name: "ESuppStore"
})
export default class ESuppStore extends VuexModule {
    eSuppForms = [];

    @Action
    async retrieveESuppForms(caseId) {
        try {
            const result = await KycPdfV2Service.getESupplimentaryForm(caseId);
            this.context.commit('setESuppForms', result)
        } catch (error) {
            alert(error.message)
        }
    }

    @Mutation
    setESuppForms(result) {
        this.eSuppForms = result;
    }

    @Action
    async createESuppForm(formData) {
        try {
            const result = await KycPdfV2Service.createESupplimentaryForm(formData);
            this.context.commit('setESuppForms', result)
        } catch (error) {
            alert(error.message)
        }
    }

    @Action
    async removeESuppForm(eSupOid) {
        try {
            await KycPdfV2Service.deleteESupplimentaryForm(eSupOid);
            const updatedData = this.eSuppForms.filter(item => item.oid !== eSupOid);
            this.context.commit('setESuppForms', updatedData)
        } catch (error) {
            alert(error.message)
        }
    }

    @Action
    async updateESuppForm(suppData) {
        const {eSuppOid, formData} = suppData
        try {
            // console.log('eSupOid ++++++++++++++', eSuppOid);
            // console.log('formData ++++++++++++++', formData);
            const result = await KycPdfV2Service.updateESupplimentaryForm(formData);
        } catch (error) {
            alert(error.message)
        }
    }
}
