import NeedsBase from '@master/model/kyc-form/need-analysis/NeedsBase';

export default class DependantsProtectionModel extends NeedsBase {
  // List dependant (Maximum 4 dependants per eFNA case)
  dependantsNA: DependantModel[] = [];

  constructor (type: 'client_0' | 'client_1' = 'client_0') {
    super(type+'_dependants');
  }

  resetAllFields(): void {
    super.resetAllFields();
    if (this.dependantsNA && Array.isArray(this.dependantsNA)) {
      for (const dependant of this.dependantsNA) {
        dependant.resetAllFields();
      }
    }
  }
}

/**
 * Model of each Dependant
 */
export class DependantModel {
  // Dependant oid
  oid: string = '';

  // Dependant name
  name: string = '';

  // List protection
  protections: DependantProtectionModel[] = [];

  constructor (params: Partial<DependantModel>) {
    Object.assign(this, params);
  }

  resetAllFields(): void {
    this.protections = [];
  }
}

/**
 * Model of each Dependant Protection
 */
export class DependantProtectionModel {
  // Protection timestamp
  timestamp: number;

  // Potection type
  type?: string;

  constructor (params: Partial<DependantProtectionModel>) {
    Object.assign(this, params);
  }
}

// Protection: Hospitalisation 
export class DependantProtectionHospitalisationModel extends DependantProtectionModel {
  // Preferred Hospital Type and Ward?
  preferredHospital?: string = '';

  // Provision for Deductible?
  deductible?: string = '';

  // Provision for Co-Insurance?
  coInsurance?: string = '';

  // Existing Type of Hospital Plan Cover? (Insurer Name and Plan Type)
  hospitalPlanCover?: string = '';
}

// Protection: Critical Illness 
export class DependantProtectionCriticalIllnessModel extends DependantProtectionModel {
  // Total Funds Required
  totalFundsRequiredCI?: number;

  // Existing Resources (if any)
  existingResourcesCI?: number;

  // Existing Resources CI Reason Status
  existingResourcesCIReasonStatus?: boolean;

  // Existing Resources CI Reason
  existingResourcesCIReason?: string;

  // Surplus / (Shortfall)
  total?: number;
}

// Protection: Disability 
export class DependantProtectionDisabilityModel extends DependantProtectionModel {
  // Total Funds Required
  totalFundsRequiredDisability?: number;

  // Existing Resources (if any)
  existingResourcesDisability?: number;

  // Existing Resources Disability Reason Status
  existingResourcesDisabilityReasonStatus?: boolean;

  // Existing Resources Disability Reason
  existingResourcesDisabilityReason?: string;

  // Surplus / (Shortfall)
  total?: number;
}

// Protection: Death 
export class DependantProtectionDeathModel extends DependantProtectionModel {
  // Final Expenses
  finalExpensesDeath?: number;

  // Loans (e.g. education loan etc)
  loansDeath?: number;

  // Existing Resources (if any)
  existingResourcesDeath?: number;

  // Existing Resources Death Reason Status
  existingResourcesDeathReasonStatus?: boolean;

  // Existing Resources Death Reason
  existingResourcesDeathReason?: string;

  // Others (Description)
  othersDescriptionDeath?: string = '';

  // Others (Amount)
  othersAmountDeath?: number;

  // Total Cash Needs
  totalCashNeedsDeath?: number;

  // Surplus / (Shortfall)
  total?: number;
}

// Protection: Personal Accident 
export class DependantProtectionPersonalAccidentModel extends DependantProtectionModel {
  // Personal Accidental Death Needs
  personalAccidentalDeathNeedsPA?: number;

  // Existing Resources (if any)
  existingResourcesPA?: number;

  // Existing Resources PA Reason Status
  existingResourcesPAReasonStatus?: boolean;

  // Existing Resources PA Reason
  existingResourcesPAReason?: string;

  // Surplus / (Shortfall)
  total?: number;
}

export const DependantProtectionTypeOptions = [
  { label: 'In the Event of Hospitalisation', value: 'hospitalisation' },
  { label: 'In the Event of Critical Illness', value: 'criticalIllness' },
  { label: 'In the Event of Disability', value: 'disability' },
  { label: 'In the Event of Death', value: 'death' },
  { label: 'In the Event of Personal Accident', value: 'personalAccident' },
];

export const PreferredHospitalOptions = [
  { label: 'Private', value: 'private' },
  { label: 'Government Restructured - A', value: 'governmentRestructuredA' },
  { label: 'Government Restructured - B1', value: 'governmentRestructuredBI' },
  { label: 'Government Restructured - B2/C', value: 'governmentRestructuredB2C' },
];

export const YesNoOptions = [
  { label: 'Yes', value: 'yes' },
  { label: 'No', value: 'no' },
];