export const RaceOptions = [
  { label: 'Anglo Burmese',	value: 'ANGLO BURMESE' },
  { label: 'Anglo Chinese',	value: 'ANGLO CHINESE' },
  { label: 'Amerindian', value: 'AMERINDIAN' },
  { label: 'African',	value: 'AFRICAN' },
  { label: 'Afghan', value: 'AFGHAN' },
  { label: 'Anglo Thai', value: 'ANGLO THAI' },
  { label: 'Anglo Indian', value: 'ANGLO INDIAN' },
  { label: 'Achehnese',	value: 'ACHEHNESE' },
  { label: 'Albanian', value: 'ALBANIAN' },
  { label: 'Armenian', value: 'ARMENIAN' },
  { label: 'Annamite', value: 'ANNAMITE' },
  { label: 'Ambonese', value: 'AMBONESE' },
  { label: 'Anglo Filipino', value: 'ANGLO FILIPINO' },
  { label: 'Arab', value: 'ARAB' },
  { label: 'Assami', value: 'ASSAMI' },
  { label: 'Austrian', value: 'AUSTRIAN' },
  { label: 'Australian', value: 'AUSTRALIAN' },
  { label: 'Anglo Saxon', value: 'ANGLO SAXON' },
  { label: 'Aryan', value: 'ARYAN' },
  { label: 'Azeri', value: 'AZERI' },
  { label: 'Batak', value: 'BATAK' },
  { label: 'Bulgarian', value: 'BULGARIAN' },
  { label: 'Butonese', value: 'BUTONESE' },
  { label: 'Bangladeshi', value: 'BANGLADESHI' },
  { label: 'Belgian', value: 'BELGIAN' },
  { label: 'Bajau', value: 'BAJAU' },
  { label: 'Bugis', value: 'BUGIS' },
  { label: 'Burgher', value: 'BURGHER' },
  { label: 'Bengali', value: 'BENGALI' },
  { label: 'Banjarese', value: 'BANJARESE' },
  { label: 'Bamar', value: 'BAMAR' },
  { label: 'Bangala', value: 'BANGALA' },
  { label: 'Balinese', value: 'BALINESE' },
  { label: 'Bhutanese', value: 'BHUTANESE' },
  { label: 'Banten', value: 'BANTEN' },
  { label: 'Basque', value: 'BASQUE' },
  { label: 'Brahmin', value: 'BRAHMIN' },
  { label: 'Bisaya', value: 'BISAYA' },
  { label: 'British', value: 'BRITISH' },
  { label: 'Burmese', value: 'BURMESE' },
  { label: 'Bosniak', value: 'BOSNIAK' },
  { label: 'Betawi', value: 'BETAWI' },
  { label: 'Boyanese', value: 'BOYANESE' },
  { label: 'Brazilian', value: 'BRAZILIAN' },
  { label: 'Caucasian', value: 'CAUCASIAN' },
  { label: 'Canadian', value: 'CANADIAN' },
  { label: 'Cape Coloured', value: 'CAPE COLOURED' },
  { label: 'Cambodian', value: 'CAMBODIAN' },
  { label: 'Ceylonese', value: 'CEYLONESE' },
  { label: 'Cornish', value: 'CORNISH' },
  { label: 'Creole', value: 'CREOLE' },
  { label: 'Swiss', value: 'SWISS' },
  { label: 'Croat', value: 'CROAT' },
  { label: 'Chamorro', value: 'CHAMORRO' },
  { label: 'Ceylon Moor', value: 'CEYLON MOOR' },
  { label: 'Chinese', value: 'CHINESE' },
  { label: 'Cocos', value: 'COCOS' },
  { label: 'Caribbean', value: 'CARIBBEAN' },
  { label: 'Czechoslovak', value: 'CZECHOSLOVAK' },
  { label: 'Czech', value: 'CZECH' },
  { label: 'Dane', value: 'DANE' },
  { label: 'Dutch Burgher', value: 'DUTCH BURGHER' },
  { label: 'Bidayuh', value: 'BIDAYUH' },
  { label: 'Dusun', value: 'DUSUN' },
  { label: 'Dutch', value: 'DUTCH' },
  { label: 'Dayak', value: 'DAYAK' },
  { label: 'English', value: 'ENGLISH' },
  { label: 'European', value: 'EUROPEAN' },
  { label: 'Spanish', value: 'SPANISH' },
  { label: 'Ethiopian', value: 'ETHIOPIAN' },
  { label: 'Eurasian', value: 'EURASIAN' },
  { label: 'Egyptian', value: 'EGYPTIAN' },
  { label: 'Finn', value: 'FINN' },
  { label: 'Fijian', value: 'FIJIAN' },
  { label: 'Flemish', value: 'FLEMISH' },
  { label: 'French', value: 'FRENCH' },
  { label: 'Goan', value: 'GOAN' },
  { label: 'Gujarati', value: 'GUJARATI' },
  { label: 'Ghanaian', value: 'GHANAIAN' },
  { label: 'Gurkha', value: 'GURKHA' },
  { label: 'German', value: 'GERMAN' },
  { label: 'Goanese', value: 'GOANESE' },
  { label: 'Greek', value: 'GREEK' },
  { label: 'Haitian', value: 'HAITIAN' },
  { label: 'Hispanic', value: 'HISPANIC' },
  { label: 'Hollander', value: 'HOLLANDER' },
  { label: 'Hindustani', value: 'HINDUSTANI' },
  { label: 'Hungarian', value: 'HUNGARIAN' },
  { label: 'Hawaiian', value: 'HAWAIIAN' },
  { label: 'Iranian', value: 'IRANIAN' },
  { label: 'Iban', value: 'IBAN' },
  { label: 'Indonesian', value: 'INDONESIAN' },
  { label: 'Isoko', value: 'ISOKO' },
  { label: 'Israeli', value: 'ISRAELI' },
  { label: 'Indian', value: 'INDIAN' },
  { label: 'Iraqi', value: 'IRAQI' },
  { label: 'Irish', value: 'IRISH' },
  { label: 'Icelander', value: 'ICELANDER' },
  { label: 'Italian', value: 'ITALIAN' },
  { label: 'Inuit', value: 'INUIT' },
  { label: 'Javanese', value: 'JAVANESE' },
  { label: 'Jakun', value: 'JAKUN' },
  { label: 'Jamaican', value: 'JAMAICAN' },
  { label: 'Jordanian', value: 'JORDANIAN' },
  { label: 'Japanese', value: 'JAPANESE' },
  { label: 'Jew', value: 'JEW' },
  { label: 'Kachin', value: 'KACHIN' },
  { label: 'Khasi', value: 'KHASI' },
  { label: 'Kayah', value: 'KAYAH' },
  { label: 'Kayin', value: 'KAYIN' },
  { label: 'Kenyan', value: 'KENYAN' },
  { label: 'Kinh', value: 'KINH' },
  { label: 'Kyrgyz', value: 'KYRGYZ' },
  { label: 'Khmer', value: 'KHMER' },
  { label: 'Kenyah', value: 'KENYAH' },
  { label: 'Kazakh', value: 'KAZAKH' },
  { label: 'Kelabit', value: 'KELABIT' },
  { label: 'Kampuchean', value: 'KAMPUCHEAN' },
  { label: 'Karen', value: 'KAREN' },
  { label: 'Korean', value: 'KOREAN' },
  { label: 'Kayan', value: 'KAYAN' },
  { label: 'Kadazan', value: 'KADAZAN' },
  { label: 'Lao', value: 'LAO' },
  { label: 'Lebanese', value: 'LEBANESE' },
  { label: 'Lithuanian', value: 'LITHUANIAN' },
  { label: 'Sri Lankan', value: 'SRI LANKAN' },
  { label: 'Lisu', value: 'LISU' },
  { label: 'Latin', value: 'LATIN' },
  { label: 'Latvian', value: 'LATVIAN' },
  { label: 'Luxembourger', value: 'LUXEMBOURGER' },
  { label: 'Libyan', value: 'LIBYAN' },
  { label: 'Madurese', value: 'MADURESE' },
  { label: 'Malabari', value: 'MALABARI' },
  { label: 'Magyars', value: 'MAGYARS' },
  { label: 'Moldavian', value: 'MOLDAVIAN' },
  { label: 'Minangkabau', value: 'MINANGKABAU' },
  { label: 'Manx', value: 'MANX' },
  { label: 'Malagasy', value: 'MALAGASY' },
  { label: 'MAHRATTA', value: 'MAHRATTA' },
  { label: 'MAORI', value: 'MAORI' },
  { label: 'MURUT', value: 'MURUT' },
  { label: 'Makasarese', value: 'MAKASARESE' },
  { label: 'Maldivian', value: 'MALDIVIAN' },
  { label: 'Malayalee', value: 'MALAYALEE' },
  { label: 'Melanesian', value: 'MELANESIAN' },
  { label: 'Mongolian', value: 'MONGOLIAN' },
  { label: 'Manipuri', value: 'MANIPURI' },
  { label: 'Mestizo', value: 'MESTIZO' },
  { label: 'Marathi', value: 'MARATHI' },
  { label: 'Metis', value: 'METIS' },
  { label: 'Maltese', value: 'MALTESE' },
  { label: 'Mauritian', value: 'MAURITIAN' },
  { label: 'Mon', value: 'MON' },
  { label: 'Moroccan', value: 'MOROCCAN' },
  { label: 'Mexican', value: 'MEXICAN' },
  { label: 'Malay', value: 'MALAY' },
  { label: 'Melanau', value: 'MELANAU' },
  { label: 'Naga', value: 'NAGA' },
  { label: 'Negro', value: 'NEGRO' },
  { label: 'Nigerian', value: 'NIGERIAN' },
  { label: 'Netherlander', value: 'NETHERLANDER' },
  { label: 'Norwegian', value: 'NORWEGIAN' },
  { label: 'Nepalese', value: 'NEPALESE' },
  { label: 'Nauruan', value: 'NAURUAN' },
  { label: 'Newar', value: 'NEWAR' },
  { label: 'New Zealander', value: 'NEW ZEALANDER' },
  { label: 'Penan', value: 'PENAN' },
  { label: 'Palestine', value: 'PALESTINE' },
  { label: 'Polish', value: 'POLISH' },
  { label: 'Persian', value: 'PERSIAN' },
  { label: 'Filipino', value: 'FILIPINO' },
  { label: 'Punjabi', value: 'PUNJABI' },
  { label: 'Pakistani', value: 'PAKISTANI' },
  { label: 'Pole', value: 'POLE' },
  { label: 'Pathan', value: 'PATHAN' },
  { label: 'Portuguese', value: 'PORTUGUESE' },
  { label: 'Peruvian', value: 'PERUVIAN' },
  { label: 'Parsee', value: 'PARSEE' },
  { label: 'Punan', value: 'PUNAN' },
  { label: 'Polynesian', value: 'POLYNESIAN' },
  { label: 'Rajput', value: 'RAJPUT' },
  { label: 'Rakhine', value: 'RAKHINE' },
  { label: 'Romanian', value: 'ROMANIAN' },
  { label: 'Russian', value: 'RUSSIAN' },
  { label: 'Sino Kadazan', value: 'SINO KADAZAN' },
  { label: 'Sammarinese', value: 'SAMMARINESE' },
  { label: 'Scot', value: 'SCOT' },
  { label: 'Sindhi', value: 'SINDHI' },
  { label: 'Swede', value: 'SWEDE' },
  { label: 'Serbia/Montengerin', value: 'SERBIA/MONTENGERIN' },
  { label: 'Samoan', value: 'SAMOAN' },
  { label: 'Shan', value: 'SHAN' },
  { label: 'Sinhalese', value: 'SINHALESE' },
  { label: 'Sino Japanese', value: 'SINO JAPANESE' },
  { label: 'Sikh', value: 'SIKH' },
  { label: 'Slovak', value: 'SLOVAK' },
  { label: 'Sumatran', value: 'SUMATRAN' },
  { label: 'Sino Indian', value: 'SINO INDIAN' },
  { label: 'Somali', value: 'SOMALI' },
  { label: 'Slavic', value: 'SLAVIC' },
  { label: 'Serani', value: 'SERANI' },
  { label: 'Sundanese', value: 'SUNDANESE' },
  { label: 'Sudanese', value: 'SUDANESE' },
  { label: 'Swedish', value: 'SWEDISH' },
  { label: 'Seychellois', value: 'SEYCHELLOIS' },
  { label: 'Telugu', value: 'TELUGU' },
  { label: 'Thai', value: 'THAI' },
  { label: 'Tibetan', value: 'TIBETAN' },
  { label: 'Tajik', value: 'TAJIK' },
  { label: 'Tamil', value: 'TAMIL' },
  { label: 'Turkmen', value: 'TURKMEN' },
  { label: 'Tongan', value: 'TONGAN' },
  { label: 'Timor', value: 'TIMOR' },
  { label: 'Turk', value: 'TURK' },
  { label: 'Unknown', value: 'UNKNOWN' },
  { label: 'Ukrainian', value: 'UKRAINIAN' },
  { label: 'American', value: 'AMERICAN' },
  { label: 'Uyghur', value: 'UYGHUR' },
  { label: 'Uzbek', value: 'UZBEK' },
  { label: 'Venezuelan', value: 'VENEZUELAN' },
  { label: 'Vietnamese', value: 'VIETNAMESE' },
  { label: 'Welsh', value: 'WELSH' },
  { label: 'Other Indonesian', value: 'OTHER INDONESIAN' },
  { label: 'Other Eurasian', value: 'OTHER EURASIAN' },
  { label: 'Other Indian', value: 'OTHER INDIAN' },
  { label: 'Others', value: 'OTHERS' },
  { label: 'Yugoslav', value: 'YUGOSLAV' },
  { label: 'Zimbabwean', value: 'ZIMBABWEAN' },
]

export const getRaceOptionLabel = RaceOptions.map(item => item.label)
export const getRaceOptionValues = RaceOptions.map(item => item.value)
export const getRaceByLabelOrValue = (arg : string) =>   RaceOptions.find(item => item.label.toLowerCase() === arg.toLowerCase() || item.value.toLowerCase() === arg.toLowerCase())