
import NeedsBase from '@master/model/kyc-form/need-analysis/NeedsBase'
import {FinancialObjectivesType} from '../utils/constants'

/**
 * Needs analysis common details
 */
export default class NeedsAnalysisModel extends NeedsBase {
  listObjects: NeedsAnalysisObjective[] = []
  additionalNotes: string = ''
  needsJustification = []  
  highPriorityObjectives = {}
  mediumPriorityObjectives = {}
  lowPriorityObjectives = {}
}

export interface NeedsAnalysisSummary {
  title?: string;
  isCurrency?: boolean;
  isCompleted?: boolean;
  items?: { label: string, value: string | number }[];
}

export interface NeedsAnalysisSummaryDependant {
  name?: string;
  protections?: NeedsAnalysisSummary[];
}

export interface NeedsAnalysisObjective {
  financialType: FinancialObjectivesType;
  priority: string; // 'highpriority' | 'mediumpriority' | 'lowpriority'
  title?: string;
  isIncompleted?: boolean;
  summary?: NeedsAnalysisSummary | NeedsAnalysisSummaryDependant[]; // Default case (NeedsAnalysisSummary) vs Dependants case (NeedsAnalysisSummaryDependant[])
}