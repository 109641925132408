<template>
  <transition name="modal">
    <div class="modal-mask" ref="dialogContent">
      <div class="modal-wrapper">
        <div class="modal-container" :class="sizeClass" role="dialog" :style="{width: customWidth }">
          <div class="modal-header">
            <slot name="header"></slot>
            <button class="btn-close" @click="$emit('close')">
              <img v-svg-inline src="../assets/svg/cancel.svg"/>
            </button>
          </div>
          <div class="modal-body">
            <slot name="body"></slot>
            <div v-if="!hideFooter && !isNewTheme" class="modal-footer">
              <button v-if="showCancel" @click="$emit('close')" class="small-cta-cancel">
                Cancel
              </button>
              <slot name="footer"></slot>
            </div>
            <div v-if="isNewTheme" class="modal-footer">
              <button v-if="showCancel" @click="$emit('close')" class="small-cta-cancel-new">
                Cancel
              </button>
              <slot name="footer"></slot>
            </div>
            <div v-if="needDelete" class="modal-footer">
              <div class="delete" @click="$emit('delete')">
                <img src="@core/assets/svg/delete_icon.svg" width="20" class="color-del"/>
                <span>
                  Delete record
                </span>
              </div>
            </div>
          </div>
          <!-- <div v-if="!hideFooter" class="modal-footer">
            <button v-if="showCancel" @click="$emit('close')" class="small-cta-cancel">
              Cancel
            </button>
            <slot name="footer"></slot>
          </div> -->
        </div>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'

@Component
export default class AppDialog extends Vue {
  @Prop() readonly size!: 'xsmall' | 'small' | 'medium' | 'large' | 'flexible' // default size is medium
  @Prop() readonly hideCancel!: boolean // hide or show Cancel button, default is false
  @Prop() readonly hideFooter!: boolean // hide or show footer slot, default is false
  @Prop() readonly customWidth?: string // to define width of the dialog body
  @Prop() readonly isNewTheme?: boolean
  @Prop() readonly needDelete?: boolean
  @Prop({ default: true }) readonly isFullHeight?: boolean

  get showCancel () {
    return this.hideCancel ? !this.hideCancel : true
  }

  get showFooter () {
    return this.hideFooter ? !this.hideFooter : true
  }

  get sizeClass () {
    let modalClass = []
    modalClass.push(this.size ?? 'medium')
    modalClass.push(this.isFullHeight ? 'full-height' : '')
    return modalClass.join(' ')
  }

  created () {
    this.$emit('open')
  }
}
</script>

<style lang="scss" scoped>
@import '@/design';
@import "@core/design/appdatatable.scss";

$padding: 15px 30px;

.modal-mask {
  @include overlay;

  .modal-wrapper {
    width: 100%;
    height: 100%;
    // margin-top: 5vh;

    .modal-container {
      position: absolute;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      width: 50%; // default size is medium
      min-width: 400px;
      margin: 0px auto;
      background-color: #fff;
      border-radius: 2px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
      transition: all 0.3s ease;
      display: flex;
      flex-direction: column;
      gap: 40px;

      &.xsmall {
        width: 30%;
        max-width: 792px;
      }
      &.small {
        width: 40%;
        max-width: 792px;
      }
      &.medium {
        width: 50%;
        max-width: 792px;
      }
      &.large {
        width: 80%;
        max-width: 792px;
      }
      &.flexible {
        width: max-content;
      }
      &.full-height {
        height: 100vh;
        position: relative;
        left: unset;
        top: unset;
        transform: none;
      }

      .modal-header {
        padding: 16px 16px 16px 40px;
        font-weight: 600;
        font-size: 1.3rem;
        line-height: 1.7rem;
        color: $form-font;
        border-bottom: 1px solid #EAEAEA;
        //margin-bottom: 40px;

        .btn-close {
          position: absolute;
          top: 20px;
          right: 20px;
          padding: 0;
          background: transparent;
          border: none;
          outline: none;
          cursor: pointer;
          font-size: 16px;
          color: #909399;
        }
      }

      :deep(.modal-body) {
        padding: 40px;
        color: #606266;
        // max-height: 65vh;
        max-height: 90%;
        overflow-y: auto;
        padding-top: 0px;

        p, li {
          text-align: left;
        }

        .el-pager {
          li {
            text-align: center;
          }
        }
      }

      .modal-footer {
        padding: $padding;
        text-align: center;
        box-sizing: border-box;
        margin-bottom: 15px;

        :deep(button) {
          display: flex;
            height: 52px;
            min-width: 180px;
            padding: 16px 40px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            line-height: 1;
            margin: 0px !important;
          &:not(:last-child) {
            margin-right: 10px !important;
          }
        }
      }
    }
  }
}
/*
 * Modal Transition
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.delete {
  font-size: 14px;
  font-weight: 600;
  color: var(--el-color-primary);
  margin-left: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap:5px;
}

 .small-cta-cancel-new{
    color: var(--el-color-primary);
    border: 1px solid var(--el-color-primary);
    padding: 12px 40px;
    font-size: 16px;
    font-weight: 600;
    line-height: 145%;
    cursor: pointer;
    border-radius: 100px;
    text-align: center;
    border: 1px solid;
    text-decoration: none;
    transition: all 0.1s ease-in-out;
    background: transparent;
    margin-left: auto;
 }

 .small-cta-cancel-new:hover {
  background-color: var(--el-color-primary);
  color: white;
   border: 1px solid var(--el-color-primary);
 }
 .modal-footer {
  display: flex;
  justify-content: center;
  margin-top: 10px;
       :deep(.small-cta-save) {
          &:disabled{
            background: #721989;
            border-color: #721989;
            opacity:0.3;
            color:#fff;
          }
    }
      }
 .color-del {
  color: var(--el-color-primary);
 }

</style>
