import { IProjectSettings } from "@master/config/IProjectSettings";
import { Ignore } from "@master/annotation/Ignore";
import NeedsBase from "@master/model/kyc-form/need-analysis/NeedsBase";
import KYCClient from '@company-common/model/kyc-form/KYCClient'
import {
  sum,
  valueIsUndefined,
  calculateROI,
  difference
} from "@master/model/kyc-form/need-analysis/math";
import { isEmpty } from "class-validator";

export default class SavingForOtherPurposeModel extends NeedsBase {
  @Ignore()
  projectSettings: IProjectSettings;

  @Ignore()
  selectedClient?:  KYCClient = undefined

  name = "Saving For Other Purpose";
  needs = "SavingForOtherPurpose";
  isProtection = true;

  purpose?: string = "";
  targetAmount?: number = undefined;
  // currentSavingsInvestmentsTotal?: number = undefined
  yearsToTarget?: number = undefined;
  cashAssets?: number = undefined;
  cachedCashAssets?: number = undefined;
  roiCashAssets?: number = undefined;
  roiInvestments?: number = undefined;
  investments?: number = undefined;
  cachedInvestments?: number = undefined;
  //Surplus?: number = undefined;

  exResourcesCAReason?:string = undefined
  exResourcesInvesReason?:string = undefined

  get grandTotal() {
    return this.surplusShortfall;
  }

  get currentSavingsInvestmentsTotal() {
    const cashAssetROI = this.roiCashAssets || 0;
    const investmentsROI = this.roiInvestments || 0;
    const cashAssets = this.cashAssets || 0;
    const investments = this.investments || 0;
    const n = this.yearsToTarget || 0;

    if (
      valueIsUndefined(this.cashAssets) &&
      valueIsUndefined(this.roiCashAssets) &&
      valueIsUndefined(this.roiInvestments) &&
      valueIsUndefined(this.investments)
    ) {
      return null;
    }

    let total = 0;

    if (
      (this.roiInvestments === 0 && this.roiCashAssets === 0) ||
      (valueIsUndefined(this.roiCashAssets) &&
        valueIsUndefined(this.roiInvestments))
    ) {
      total = sum(this.cashAssets, this.investments) || 0;
    } else {
      // Calculate cash assets ROI total if ROI is defined
      let cashAssetsROITotal =
        cashAssets > 0 ? calculateROI(cashAssets, cashAssetROI, n) : cashAssets;

      // Calculate investment ROI total if ROI is defined
      let investmentROITotal =
        investments > 0
          ? calculateROI(investments, investmentsROI, n)
          : investments;

      // Sum up the total considering the calculated ROI totals
      total = sum(cashAssetsROITotal, investmentROITotal);
    }

    return parseFloat(total.toFixed(2));
  }


  get surplusShortfall () {
    const savingsInvestment = this.currentSavingsInvestmentsTotal;
    if (valueIsUndefined(savingsInvestment) && valueIsUndefined(this.targetAmount)) return null
   return difference(savingsInvestment, this.targetAmount)
  }

  get isCompleted() {
    const yTT = this.yearsToTarget > 0;
    return this.purpose != null && this.targetAmount != null && yTT;
  }

  get exResourcesCAReasonStatus() {
    if (!this.selectedClient) return
    let isNeedReason = false
    const selectedClientByNetWorth= this.selectedClient?.netWorth

    const calcTotal = selectedClientByNetWorth?.isSimplifiedMode ? selectedClientByNetWorth?.simplifiedTotalCash : sum(selectedClientByNetWorth?.savings,
      selectedClientByNetWorth?.fixedDeposits,
      selectedClientByNetWorth?.cashNearCashOthersAmount
    ) || 0

    if (this.cashAssets !== calcTotal && (!isEmpty(this.cashAssets) &&!isEmpty(calcTotal))){
      isNeedReason = true
    } else {
      this.exResourcesCAReason = undefined
    }

    return isNeedReason
  }

  set setSelectedClient (client) {
    this.selectedClient = client
  }

  get exResourcesInvesReasonStatus() {
    if (!this.selectedClient) return
    let isNeedReason = false
    const selectedClientByNetWorth= this.selectedClient?.netWorth

    if(selectedClientByNetWorth?.isSimplifiedMode) {
      this.exResourcesInvesReason = undefined
      return false
    }

    const calcTotal = sum(selectedClientByNetWorth?.unitTrusts,
      selectedClientByNetWorth?.stocks,
      selectedClientByNetWorth?.bonds,
    ) || 0

    if (this.investments !== calcTotal && (!isEmpty(this.investments) && !isEmpty(calcTotal)) ){
      isNeedReason = true
    } else {
      this.exResourcesInvesReason = undefined
    }

    return isNeedReason
  }



  constructor (type: 'client_0' | 'client_1' = 'client_0', projectSettings: IProjectSettings, id = 'p1') {
    super(`${type}_other_${id}`)
    this.projectSettings = projectSettings
  }
}
