//import store from '../store/UserStoreV2'
interface IRouteBasic {
    name: string;
    path: string;
    pathToRegexpOptions?: { strict: boolean };
    component: () => void;
    meta?: object;
    redirect?: {name: string; path?: string};
    children?: IChildrenRouteStructure [];
  }
  
  export interface IChildrenRouteStructure extends IRouteBasic{
    shouldDisabled?: boolean | ((T) => boolean);
    shouldHide?: boolean| ((T) => boolean);
  }
  
  export function bscReviewerRestriction (systemRole: string) {
    return systemRole === 'bsc-reviewer'
  }
  
  export function nonAdminRestriction (systemRole: string) {
    return systemRole !== 'ops-admin' && systemRole !== 'it-super-admin'
  }
  export const RouteStructure: IRouteBasic = {
    name: 'User Profile Main',
    path: '/user/:userOid/',
    component: () => import('../components/UserProfileV2.vue'),
    redirect: { name: 'User Profile' },
    children:[
      {
        name: 'User Profile',
        path: 'profile-summary',
        shouldDisabled: bscReviewerRestriction,
        component: () => import('../components/UserProfileSummary.vue'),
        meta: {
          subject : ['adviserDetail', "userAccount", "userCompanyInfo", "userPersonalInfo"],

          ability: [
            // {
            //   subject : "adviserDetail", 
            //   action : "read"
            // },
            // {
            //   subject : "adviserDetail", 
            //   action : "write"
            // },
            {
              subject : "userAccount", 
              action : "manage"
            },
            {
              subject : "userAccount", 
              action : "read"
            },
            {
              subject : "userAccount", 
              action : "write"
            }
            // {
            //   subject : "userCompanyInfo", 
            //   action : "manage"
            // },
            // {
            //   subject : "userPersonalInfo", 
            //   action : "manage"
            // }
          ]
        }
      },
      {
        path: 'adviser-details',
        name: 'Personal Details',
        shouldDisabled: bscReviewerRestriction,
        component: () => import('../components/UserAdviserDetailsV2.vue'),
        meta: {
          ability: [
            {
              subject : "adviserDetail", 
              action : "read"
            },
            {
              subject : "adviserDetail", 
              action : "write"
            },
            {
              subject : "adviserDetail", 
              action : "manage"
            }
          ]
        }
      },
      {
        path: 'bsc-records',
        name: 'BSC Records',
        shouldDisabled: bscReviewerRestriction,
        component: () => import('../components/UserBscRecordsV2.vue')
      },
    //   {
    //     path: 'bsc-records',
    //     name: 'User BSC Records',
    //     component: () => import('@core/pages/user-profile/components/UserBscRecordsV2.vue'),
    //     // redirect: { name: 'BSC Records' },
    //     children: [
    //     //   {
    //     //     name: 'BSC Records',
    //     //     path: 'records',
    //     //     meta: {
    //     //       parent: 'User BSC Records'
    //     //     },
    //     //     shouldDisabled: bscReviewerRestriction,
    //     //     component: () => import('@core/pages/user-profile/components/UserBscRecordsV2.vue')
    //     //   },
    //     //   {
    //     //     name: 'BSC Record',
    //     //     path: 'record/:bscOid',
    //     //     meta: {
    //     //       parent: 'User BSC Records'
    //     //     },
    //     //     shouldDisabled: bscReviewerRestriction,
    //     //     shouldHide: true,
    //     //     component: () => import('@core/pages/bsc/UserBscRecord.vue')
    //     //   }
    //     ]
    //   },
      // {
      //   path: 'bsc-records',
      //   name: 'BSC Records',
      //   component: () => import('../components/UserBscRecordsV2.vue'),
      //   children: [
      //     {
      //       name: 'BSC Record',
      //       path: 'record/:bscOid',
      //       meta: {
      //         parent: 'User BSC Records'
      //       },
      //       shouldDisabled: bscReviewerRestriction,
      //       shouldHide: true,
      //       component: () => import('@core/pages/bsc/UserBscRecord.vue')
      //     }
      //   ]
      // },
      {
        path: 'cpd-records',
        name: 'CPD Records',
        shouldDisabled: bscReviewerRestriction,
        component: () => import('../components/UserCpdRecordsV2.vue')
      },
      {
        path: 'commission-structure',
        name: 'Commission Statements',
        shouldDisabled: bscReviewerRestriction,
        component: () => import('../components/UserCommissionStructure.vue')
      }
    ]
}
