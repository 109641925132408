import { classToPlain } from 'class-transformer'
import { unparse } from 'papaparse'

export class CSVModelInterface {
  error = false
  filename = null
  staticTemplate: {downloadedFileName: string; fileType: string; templateCategory: string} = null
  static example (name?: string) {
    const model = new this()
    const filename = name || model.filename || this.name
    const template = classToPlain(new this(), { strategy: 'excludeAll' })
    const csvData = unparse([template])
    // const blob = new Blob([csvData], { type: 'text/csv' })
    // download(blob, filename)

    // Create a data URI for the CSV data
    const dataUri = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvData);
    // Create an anchor element for download
    const link = document.createElement('a');
    link.setAttribute('href', dataUri);
    link.setAttribute('download', filename);
    // Simulate a click on the anchor element to trigger the download
    link.click();
  }

  static usedStaticTemplate () {
    const model = new this()
    return model.staticTemplate
  }
}
