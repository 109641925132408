/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export interface CISDTO {
    oid?: string;
    // indicator: CISDTO.indicator;
    indicator: string;
    productCode?: string;
    productName: string;
    providerName: string;
    providerOid?: string;
    productTypeOid?: string;
    productTypeCode?: string;
    productTypeName?: string;
    productCategoryOid?: string;
    productCategory: string;
    // mdrtCategory: CISDTO.mdrtCategory;
    mdrtCategory: string;
    trainingMandatory: boolean;
    bscClassification: boolean;
    fundHouse?: string;
    fundType?: string;
    fundTypeOid?: string;
    // fundRiskClassification?: CISDTO.fundRiskClassification;
    fundRiskClassification: string;
    fundRiskRating?: string;
    // fundCurrency?: CISDTO.fundCurrency;
    fundCurrency: string;
    // fundAssetClass?: CISDTO.fundAssetClass;
    fundAssetClass: string;
    fundGeographicalFocus?: string;
    fundSector?: string;
    fundIncomePayout?: boolean;
    fundCash?: boolean;
    fundCpfOa?: boolean;
    fundCpfSa?: boolean;
    fundCpfSrs?: boolean;
    fundIsin?: string;
    // status: CISDTO.status;
    status: string;
    approvalOid?: string;
}

export namespace CISDTO {

    export enum indicator {
        CIS = 'CIS',
    }

    export enum mdrtCategory {
        NOT_APPLICABLE = 'Not Applicable',
        RISK_PROTECTION = 'Risk Protection',
        OTHERS = 'Others',
    }

    export enum fundRiskClassification {
        LOW = 'Low',
        MODERATELY_LOW = 'Moderately Low',
        MODERATE = 'Moderate',
        MODERATELY_HIGH = 'Moderately High',
        HIGH = 'High',
    }

    export enum fundCurrency {
        AUD = 'AUD',
        CNH = 'CNH',
        EUR = 'EUR',
        GBP = 'GBP',
        HKD = 'HKD',
        NZD = 'NZD',
        RMB = 'RMB',
        SGD = 'SGD',
        USD = 'USD',
        JPY = 'JPY',
    }

    export enum fundAssetClass {
        ALTERNATIVES = 'Alternatives',
        EQUITIES = 'Equities',
        FIXED_INCOME = 'Fixed Income',
        MIXED_ASSETS = 'Mixed Assets',
        MONEY_MARKET = 'Money Market',
        SHORT_DURATION_FIXED_INCOME = 'Short Duration Fixed Income',
    }

    export enum status {
        ACTIVE = 'Active',
        INACTIVE = 'Inactive',
        HOLD = 'Hold',
    }


}
