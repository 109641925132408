import { ClientBase } from '../../ClientBase'
import { sum, valueIsUndefined } from '@master/model/kyc-form/need-analysis/math'
import { Default } from '@master/annotation/Default'
import { Ignore } from '@master/annotation/Ignore'
import { MaxChar } from '@master/annotation/MaxChar'
import { InputMaxChar } from '@master/enum/MaxCharsEnum'

/**
 * By default netWorthPlanning and noPlanningReason
 * is used by both client 1 and 2. All other_*_text
 * are shared as well.
 */
export default class NetWorth extends ClientBase {
  isUserDeclinedAssetsFlow = false
  declineRemark = ''

  isSimplifiedMode

  netWorthPlanning = ''

  @MaxChar(InputMaxChar.MEDIUM)
  noPlanningReason = ''

  // Assets - Savings
  savings

  // Assets - Fixed Deposits
  fixedDeposits

  // Assets - Others Deposits Description
  @MaxChar(InputMaxChar.XXXLARGE)
  cashNearCashOthersDescription = ''

  // Assets - Others Deposits Amount
  cashNearCashOthersAmount

  // Assets - Property - Residence
  propertyResidence

  // Assets - Motor Vehicle(s)
  motorVehicles

  // Assets - Others (e.g. Valuables/Collections)
  personalUseAssetsOthersAmount

  // Assets - Unit Trusts
  unitTrusts

  // Assets - Stocks
  stocks

  // Assets - Bonds
  bonds

  // Assets - ILPs
  ilps

  // Assets - Business Interests
  businessInterests

  // Assets - Insurance Endowments
  insuranceEndowments

  // Assets - CPF Balance (OA)
  cpfBalanceOA

  // Assets - CPF Balance (SA)
  cpfBalanceSA

  // Assets - CPF Balance (MA)
  cpfBalanceMA

  @Default(71500)
  cpfMaBal // global config param value 

  // Assets - SRS
  srs

  // Assets - Property Investment 1
  propertyInvestment1

  // Assets - Property Investment 2
  propertyInvestment2

  // Assets - Property Investment 3
  propertyInvestment3

  // Assets - Others Invested 1 Amount
  othersInvestedAssetsAmount1

  // Assets - Others Invested 2 Amount
  othersInvestedAssetsAmount2

  // Assets - Others Invested 3 Amount
  othersInvestedAssetsAmount3

  // Assets - Others Invested 1 Description
  @MaxChar(InputMaxChar.XXSMALL)
  othersInvestedAssetsDescription1 = ''
  
  // Assets - Others Invested 2 Description
  @MaxChar(InputMaxChar.XXSMALL)
  othersInvestedAssetsDescription2 = ''

  // Assets - Others Invested 3 Description
  @MaxChar(InputMaxChar.XXSMALL)
  othersInvestedAssetsDescription3 = ''
  
  // Liabilities - Short Term - Credit Card
  creditCard

  // Liabilities - Short Term - Bank Overdraft
  bankOverDraft

  // Liabilities - Short Term - Outstanding Tax
  outstandingTax

  // Liabilities - Short Term - Others Short Term 1 Description
  @MaxChar(InputMaxChar.XXSMALL)
  shortTermOtherDescription1 = ''

  // Liabilities - Short Term - Others Short Term 1 Description
  shortTermOtherAmount1

  // Liabilities - Short Term - Others Short Term 2 Description
  @MaxChar(InputMaxChar.XXSMALL)
  shortTermOtherDescription2 = ''

  // Liabilities - Short Term - Others Short Term 2 Description
  shortTermOtherAmount2

  // Liabilities - Short Term - Others Short Term 3 Description
  @MaxChar(InputMaxChar.XXSMALL)
  shortTermOtherDescription3 = ''

  // Liabilities - Short Term - Others Short Term 3 Description
  shortTermOtherAmount3

  // Liabilities - Long Term - Mortgage Loan on Residential Property
  mlResidentialProperty
  
  // Liabilities - Long Term - Mortgage Loan on Investment Property 1
  mlInvestmentProperty01

  // Liabilities - Long Term - Mortgage Loan on Investment Property 2
  mlInvestmentProperty02
  
  // Liabilities - Long Term - Mortgage Loan on Investment Property 3
  mlInvestmentProperty03

  // Liabilities - Long Term - Car Loan
  carLoan

  // Liabilities - Long Term - Others Long Term 1 Description
  @MaxChar(InputMaxChar.XXSMALL)
  longTermOtherDescription1 = ''

  // Liabilities - Long Term - Others Long Term 1 Description
  longTermOtherAmount1

  // Liabilities - Long Term - Others Long Term 2 Description
  @MaxChar(InputMaxChar.XXSMALL)
  longTermOtherDescription2 = ''

  // Liabilities - Long Term - Others Long Term 2 Description
  longTermOtherAmount2

  // Liabilities - Long Term - Others Long Term 3 Description
  @MaxChar(InputMaxChar.XXSMALL)
  longTermOtherDescription3 = ''

  // Liabilities - Long Term - Others Long Term 3 Description
  longTermOtherAmount3

  // Summary - Any changes to your income and expenditure
  significantFactorsNext12Months: boolean | null = null

  // Summary - Any changes to your income and expenditure - Please provide details
  @MaxChar(InputMaxChar.XXXLARGE)
  significantFactorsDetails = ''

  @MaxChar(InputMaxChar.XXXLARGE)
  additionalNotes = '' // previously was your_finances_additional_notes
  
  // Summary - I do not wish to fully disclose my cash flow information
  discloseAssetsLiabilities: boolean | null = null

  // Summary - Justification - No mortgage loan value
  @MaxChar(InputMaxChar.XXXLARGE)
  justificationNoMortgageLoanValue = ''

  // Summary - Justification - No cash/OA mortgage value
  @MaxChar(InputMaxChar.XXXLARGE)
  justificationNoCashOAMortgageValue = ''

  // Summary - Justification - No [property_type] value
  @MaxChar(InputMaxChar.XXXLARGE)
  justificationNoPropertyTypeValue = ''

  // Summary - Justification - Not disclosing property value
  @MaxChar(InputMaxChar.XXXLARGE)
  justificationNotDisclosingPropertyValue = ''

  // Summary - Justification - Not entering value to all CPF Balance Fields
  @MaxChar(InputMaxChar.XXXLARGE)
  justificationNotEnteringCPFBalanceFields = ''

  // Summary - Justification - CPF Medisave Disclosed Exceeds Stipulated CPF Guidelines
  @MaxChar(InputMaxChar.XXXLARGE)
  cpfMedisaveBalanceJustification = ''

  // Simplified - Total Assets
  simplifiedTotalAssets
  
  // Simplified - Total Cash/Near Cash
  simplifiedTotalCash
  
  // Simplified - Total CPF
  simplifiedTotalCPF
  
  // Simplified - Others (e.g. investment, fixed assets, etc)
  summaryAssetsOthers
  
  // Simplified - Total Liabilities
  simplifiedTotalLiabilities
  
  // Simplified - Total Short Term Liabilities
  simplifiedTotalShortTermLiabilities
  
  // Simplified - Total Long Term Liabilities 
  simplifiedTotalLongTermLiabilities

  @Ignore()
  @Default(0)
  others1 = ''
  @Ignore()
  @Default(0)
  cpfOrdinary = ''
  @Ignore()
  @Default(0)
  cpfSpecial = ''

  get simplifiedSummaryAssets() {
    return sum(this.simplifiedTotalCash, this.simplifiedTotalCPF, this.summaryAssetsOthers)
  }

  get simplifiedSummaryLiabilities() {
    return sum(this.simplifiedTotalShortTermLiabilities, this.simplifiedTotalLongTermLiabilities)
  }

  get simplifiedSummaryNetWorth() {
    if (valueIsUndefined(this.simplifiedTotalAssets) && valueIsUndefined(this.simplifiedTotalLiabilities)) return null
    return sum(this.simplifiedTotalAssets, -1 * this.simplifiedTotalLiabilities)
  }

  constructor (type: 'client_0' | 'client_1' = 'client_0') {
    super(type)
  }

  // B101 - Total Cash/Near Cash: Savings + Fixed Deposits + Others Amount
  get totalCashAssets() {
    return sum(this.fixedDeposits, this.savings, this.cashNearCashOthersAmount) || 0
  }

  // B106 - (Property - Residence) + Motor Vehicle(s) + Others e.g. (Valuables / Collections)
  get totalUsedAssets() {
    return sum(this.propertyResidence, this.motorVehicles, this.personalUseAssetsOthersAmount) || 0
  }

  get totalCashAssetsForSummary() {
    return sum(this.fixedDeposits, this.savings, this.cashNearCashOthersAmount)
  }

  get totalUsedAssetsForSummary() {
    return sum(this.propertyResidence, this.motorVehicles, this.personalUseAssetsOthersAmount)
  }

  // B127 - Unit Trusts + Stock + ILP + Bonds + Business Interests + Insurance Endowments + CPF Balance (OA) + CPF Balance (SA) + CPF Medisave (MA) + SRS + Property - Investment 1 + Property - Investment 2 + Property - Investment 3 + Others Amount + Others Amount + Others Amount
  get totalInvestedAssets() {
    return sum(
      this.unitTrusts, 
      this.stocks, 
      this.bonds, 
      this.ilps, 
      this.businessInterests, 
      this.insuranceEndowments, 
      this.cpfBalanceOA, 
      this.cpfBalanceSA, 
      this.cpfBalanceMA, 
      this.srs, 
      this.propertyInvestment1, 
      this.propertyInvestment2, 
      this.propertyInvestment3,
      this.othersInvestedAssetsAmount1,
      this.othersInvestedAssetsAmount2,
      this.othersInvestedAssetsAmount3,
    ) || 0
  }
  
  get totalInvestedAssetsForSummary() {
    return sum(
      this.unitTrusts, 
      this.stocks, 
      this.bonds, 
      this.ilps, 
      this.businessInterests, 
      this.insuranceEndowments, 
      this.cpfBalanceOA, 
      this.cpfBalanceSA, 
      this.cpfBalanceMA, 
      this.srs, 
      this.propertyInvestment1, 
      this.propertyInvestment2, 
      this.propertyInvestment3,
      this.othersInvestedAssetsAmount1,
      this.othersInvestedAssetsAmount2,
      this.othersInvestedAssetsAmount3,
    )
  }

  // B142 - Credit Card(s) + Bank Overdraft + Outstanding Tax + Others Amount + Others Amount + Others Amount
  get totalShortTermLiabilities() {
    return sum(
      this.creditCard,
      this.bankOverDraft,
      this.outstandingTax,
      this.shortTermOtherAmount1,
      this.shortTermOtherAmount2,
      this.shortTermOtherAmount3,
    ) || 0
  }

  get totalShortTermLiabilitiesForSummary() {
    return sum(
      this.creditCard,
      this.bankOverDraft,
      this.outstandingTax,
      this.shortTermOtherAmount1,
      this.shortTermOtherAmount2,
      this.shortTermOtherAmount3,
    )
  }

  // B155 - Mortgage Loan on Residential Property + Mortgage Loan on Investment Property 1 + Mortgage Loan on Investment Property 2 + Mortgage Loan on Investment Property 3 + Car Loan + Others Amount + Others Amount  + Others Amount
  get totalLongTermLiabilities() {
    return sum(
      this.mlResidentialProperty,
      this.mlInvestmentProperty01,
      this.mlInvestmentProperty02,
      this.mlInvestmentProperty03,
      this.carLoan,
      this.longTermOtherAmount1,
      this.longTermOtherAmount2,
      this.longTermOtherAmount3,
    ) || 0
  }

  get totalLongTermLiabilitiesForSummary() {
    return sum(
      this.mlResidentialProperty,
      this.mlInvestmentProperty01,
      this.mlInvestmentProperty02,
      this.mlInvestmentProperty03,
      this.carLoan,
      this.longTermOtherAmount1,
      this.longTermOtherAmount2,
      this.longTermOtherAmount3,
    )
  }

  get totalMortgage() {
    return sum(
      this.mlResidentialProperty,
      this.mlInvestmentProperty01,
      this.mlInvestmentProperty02,
      this.mlInvestmentProperty03,
    ) || 0
  }
  

  // B129 - Total Cash/Near Cash + Total Personal Use Assets + Total Invested Assets
  get totalAssets() {
    return sum(this.totalCashAssets, this.totalUsedAssets, this.totalInvestedAssets)
  }

  get totalAssetsForSummary() {
    return sum(this.totalCashAssetsForSummary, this.totalUsedAssetsForSummary, this.totalInvestedAssetsForSummary)
  }

  // B157 - B142 + B155 (Total Short Term Liabilities + Total Long Term Liabilities)
  get totalLiabilities() {
    return sum(this.totalShortTermLiabilities, this.totalLongTermLiabilities)
  }

  get totalLiabilitiesForSummary() {
    return sum(this.totalShortTermLiabilitiesForSummary, this.totalLongTermLiabilitiesForSummary)
  }

  get summaryNetWorth() {
    if (valueIsUndefined(this.totalAssets) && valueIsUndefined(this.totalLiabilities)) return null
    return sum(this.totalAssets, -1 * this.totalLiabilities)
  }

  get summaryNetWorthForSummary() {
    if (valueIsUndefined(this.totalAssetsForSummary) && valueIsUndefined(this.totalLiabilitiesForSummary)) return null
    return sum(this.totalAssets, -1 * this.totalLiabilities)
  }

  setData(newData) {
    for (const key in newData) {
      if (this.hasOwnProperty(key)) { 
        this[key] = newData[key];
      }
    }
  }

  setDataSimplified(newData: { assets?: any, liability?: any, summary?: any }) {
    if (newData?.assets) {
      const simplfiedAssetsMapper = {
        'totalAssets': 'simplifiedTotalAssets',
        'totalCashNearCash': 'simplifiedTotalCash',
        'totalCPF': 'simplifiedTotalCPF',
        'summaryAssetsOthers': 'summaryAssetsOthers',
      };
      for (const key in newData.assets) {
        const localKey = simplfiedAssetsMapper[key];
        if (this.hasOwnProperty(localKey)) { 
          this[localKey] = newData.assets[key];
        }
      }
    }
    if (newData?.liability) {
      const simplfiedLiabilitiesMapper = {
        'totalShortTermLiabilities': 'simplifiedTotalShortTermLiabilities',
        'totalLongTermLiabilities': 'simplifiedTotalLongTermLiabilities',
        'totalLiabilities': 'simplifiedTotalLiabilities',
      }
      for (const key in newData.liability) {
        const localKey = simplfiedLiabilitiesMapper[key];
        if (this.hasOwnProperty(localKey)) { 
          this[localKey] = newData.liability[key];
        }
      }
    }
    if (newData?.summary) {
      const simplfiedSummaryMapper = {
        'discloseAssetsLiabilitiesInfo': 'discloseAssetsLiabilities',
        'significantFactorsNext12Months': 'significantFactorsNext12Months',
        'significantFactorsDetails': 'significantFactorsDetails',
        'additionalNotes': 'additionalNotes',
      }
      for (const key in newData.summary) {
        const localKey = simplfiedSummaryMapper[key];
        if (this.hasOwnProperty(localKey)) { 
          this[localKey] = newData.summary[key];
        }
      }
    }
  }

  toCashNearCashJson() {
    return {
      savings: this.savings,
      fixedDeposits: this.fixedDeposits,
      cashNearCashOthersAmount: this.cashNearCashOthersAmount,
      cashNearCashOthersDescription: this.cashNearCashOthersDescription,
      totalCashNearCash: this.totalCashAssets
    }
  }

  toPersonalUseAssetsJson() {
    return {
      propertyResidence: this.propertyResidence,
      motorVehicles: this.motorVehicles,
      personalUseAssetsOthersAmount: this.personalUseAssetsOthersAmount,
      totalPersonalUseAssets: this.totalUsedAssets
    }
  }

  toInvestedAssetsJson() {
    return {
      unitTrusts: this.unitTrusts,
      bonds: this.bonds,
      ilps: this.ilps,
      businessInterests: this.businessInterests,
      insuranceEndowments: this.insuranceEndowments,
      cpfBalanceOA: this.cpfBalanceOA,
      cpfBalanceSA: this.cpfBalanceSA,
      cpfBalanceMA: this.cpfBalanceMA,
      srs: this.srs,
      stocks: this.stocks,
      propertyInvestment1: this.propertyInvestment1,
      propertyInvestment2: this.propertyInvestment2,
      propertyInvestment3: this.propertyInvestment3,
      othersInvestedAssetsDescription1: this.othersInvestedAssetsDescription1,
      othersInvestedAssetsDescription2: this.othersInvestedAssetsDescription2,
      othersInvestedAssetsDescription3: this.othersInvestedAssetsDescription3,
      totalInvestedAssets: this.totalInvestedAssets,
      othersInvestedAssetsAmount1:this.othersInvestedAssetsAmount1,
      othersInvestedAssetsAmount2:this.othersInvestedAssetsAmount2,
      othersInvestedAssetsAmount3:this.othersInvestedAssetsAmount3,
    }
  }

  toAssetsJson() {
    return {
      cashNearCash: this.toCashNearCashJson(),
      personalUseAssets : this.toPersonalUseAssetsJson(),
      investedAssets : this.toInvestedAssetsJson(),
      totalAssets: this.totalAssets
    }
  }

  toshortTermOthers() {
    return   {
      shortTermOtherDescription1: this.shortTermOtherDescription1,
      shortTermOtherAmount1: this.shortTermOtherAmount1,
      shortTermOtherDescription2: this.shortTermOtherDescription2,
      shortTermOtherAmount2: this.shortTermOtherAmount2,
      shortTermOtherDescription3: this.shortTermOtherDescription3,
      shortTermOtherAmount3: this.shortTermOtherAmount3
    }
  }

  toShortTermDtoJson() {
    return {
      creditCard: this.creditCard,
      bankOverDraft: this.bankOverDraft,
      outstandingTax: this.outstandingTax,
      shortTermOthers: this.toshortTermOthers(),
      totalShortTermLiabilities: this.totalShortTermLiabilities
    }
  }

  toLongTermOthers() {
    return   {
      longTermOtherDescription1: this.longTermOtherDescription1,
      longTermOtherAmount1: this.longTermOtherAmount1,
      longTermOtherDescription2: this.longTermOtherDescription2,
      longTermOtherAmount2: this.longTermOtherAmount2,
      longTermOtherDescription3: this.longTermOtherDescription3,
      longTermOtherAmount3: this.longTermOtherAmount3,
    }
  }

  toLongTermDtoJson() {
    return {
      mlResidentialProperty: this.mlResidentialProperty,
      mlInvestmentProperty01: this.mlInvestmentProperty01,
      mlInvestmentProperty02: this.mlInvestmentProperty02,
      mlInvestmentProperty03: this.mlInvestmentProperty03,
      carLoan: this.carLoan,
      longTermOthers: this.toLongTermOthers(),
      totalLongTermLiabilities: this.totalLongTermLiabilities
    }
  }

  toLiabiltyJson() {
    return {
      shortTermDto : this.toShortTermDtoJson(),
      longTermDto : this.toLongTermDtoJson(),
      totalLiabilities : this.totalLiabilities
    }
  }

  toSummaryJson() {
    return {
      discloseAssetsLiabilities: this.discloseAssetsLiabilities,
      significantFactorsNext12Months: this.significantFactorsNext12Months,
      significantFactorsDetails: this.significantFactorsDetails,
      justificationNoMortgageLoanValue: this.justificationNoMortgageLoanValue,
      justificationNoCashOAMortgageValue: this.justificationNoCashOAMortgageValue,
      justificationNoPropertyTypeValue: this.justificationNoPropertyTypeValue,
      justificationNotDisclosingPropertyValue: this.justificationNotDisclosingPropertyValue,
      justificationNotEnteringCPFBalanceFields: this.justificationNotEnteringCPFBalanceFields,
      cpfMedisaveBalance: this.cpfBalanceMA,
      cpfMABalanceParameter: this.cpfMaBal,
      cpfMedisaveBalanceJustification: this.cpfMedisaveBalanceJustification,
      additionalNotes: this.additionalNotes,
    }
  }

  toAssetsSimplifiedJson() {
    return {
      totalAssets: this.simplifiedTotalAssets,
      totalCashNearCash: this.simplifiedTotalCash,
      totalCPF: this.simplifiedTotalCPF,
      summaryAssetsOthers: this.summaryAssetsOthers,
    }
  }

  toLiabiltySimplifiedJson() {
    return {
      totalShortTermLiabilities: this.simplifiedTotalShortTermLiabilities,
      totalLongTermLiabilities: this.simplifiedTotalLongTermLiabilities,
      totalLiabilities: this.simplifiedTotalLiabilities,
    }
  }

  toSummarySimplifiedJson() {
    return {
      discloseAssetsLiabilitiesInfo: this.discloseAssetsLiabilities,
      significantFactorsNext12Months: this.significantFactorsNext12Months,
      significantFactorsDetails: this.significantFactorsDetails,
      additionalNotes: this.additionalNotes,
    }
  }

  toJSON() {
    return {
      assets: this.toAssetsJson(),
      liability: this.toLiabiltyJson(),
      summary: this.toSummaryJson(),
      isUserDeclinedAssetsFlow: this.isUserDeclinedAssetsFlow,
      declineRemark: this.declineRemark,
      isSimplifiedMode: this.isSimplifiedMode
    };
  }
  
  toSimplifiedJSON() {
    return {
      assets: this.toAssetsSimplifiedJson(),
      liability: this.toLiabiltySimplifiedJson(),
      summary: this.toSummarySimplifiedJson(),
      isUserDeclinedAssetsFlow: this.isUserDeclinedAssetsFlow,
      declineRemark: this.declineRemark,
      isSimplifiedMode: this.isSimplifiedMode
    };
  }
}
