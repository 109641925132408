import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { KycPdfV2Service } from '@core/openapi/services/kyc-pdf-v2/api'

@Module({
    namespaced: true,
    name: "FileUploads"
})
export default class FileUploads extends VuexModule {
    uploadedFiles = [];

    @Action
    async retrieveUploadedFiles(data) {
        try {
            const result = await KycPdfV2Service.listFile(data);
            this.context.commit('setUploadedFiles', result)
        } catch (error) {
            alert(error.message)
        }
    }

    @Mutation
    setUploadedFiles(result) {
        this.uploadedFiles = result;
    }

    @Action
    async uploadFile(formData) {
        try {
            const result = await KycPdfV2Service.uploadFile(formData);
        } catch (error) {
            // alert(error.message)
            console.log('uploadFile Error', error.message)
        }
    }

    @Action
    async deleteUploadFile(fileOid) {
        try {
            await KycPdfV2Service.deleteKycPdfFile(fileOid);
        } catch (error) {
            alert(error.message)
        }
    }

    @Action
    async reUploadFile(formData) {
        try {
            const result = await KycPdfV2Service.reuploadFile(formData);
        } catch (error) {
            // alert(error.message)
            console.log('reuploadFile Error', error.message)
        }
    }

    @Action
    async downloadFile(fileOid) {
        try {
            return await KycPdfV2Service.downloadKycPdfFile(fileOid);
        } catch (error) {
            alert(error.message)
        }
    }
}
