import { IProjectSettings } from '@master/config/IProjectSettings'
import { IDateService } from '@master/services/IDateService'
import AIOI from '@master/model/kyc-form/AIOI'
import CashFlow from '@master/model/kyc-form/finances/CashFlow'
import Budget from '@master/model/kyc-form/finances/Budget'
import CKA from '@master/model/kyc-form/CKA'
import RPA from '@master/model/kyc-form/RPA'
import HospitalizationNeeds from '@master/model/kyc-form/need-analysis/health/HospitalizationNeeds'
import PersonalAccidentNeeds from '@master/model/kyc-form/need-analysis/health/PersonalAccidentNeeds'
import OtherNeeds from '@master/model/kyc-form/need-analysis/others/OtherNeeds'
import LossOfIncomeNeeds from '@master/model/kyc-form/need-analysis/health/LossOfIncomeNeeds'
import OutpatientExpenses from '@master/model/kyc-form/need-analysis/health/OutpatientExpenses'
import { CriticalIllnessProtectionClient } from '@master/model/kyc-form/need-analysis/protection/CriticalIllnessProtectionClient'
import DeathProtectionClient from '@master/model/kyc-form/need-analysis/protection/DeathProtectionClient'
import DisabilityProtectionClient from '@master/model/kyc-form/need-analysis/protection/DisabilityProtectionClient'
import DisabilityIncomeProtectionClient from '@master/model/kyc-form/need-analysis/protection/DisabilityIncomeProtectionClient'
import EducationNeeds from '@master/model/kyc-form/need-analysis/savings/EducationNeeds'
import InvestmentNeeds from '@master/model/kyc-form/need-analysis/savings/InvestmentNeeds'
import RetirementNeeds from '@master/model/kyc-form/need-analysis/savings/RetirementNeeds'
import SavingsNeeds from '@master/model/kyc-form/need-analysis/savings/SavingsNeeds'
import SpecificGoalsNeeds from '@master/model/kyc-form/need-analysis/savings/SpecificGoalsNeeds'
import InvestmentOption from '@master/model/kyc-form/InvestmentOption'
import RecommendationBasis from '@master/model/kyc-form/RecommendationBasis'
import Switching from '@master/model/kyc-form/Switching'
import ECDD from '@master/model/kyc-form/ECDD'
import MyInfo from '@master/model/kyc-form/MyInfo'
import CoreBOP from '@master/model/kyc-form/external/BeneficialOwnerParticulars'
import AMLDoc from '@master/model/kyc-form/AMLDoc'
import BeneficialOwner from '@master/model/kyc-form/BeneficialOwner'
import NeedsBase from '@master/model/kyc-form/need-analysis/NeedsBase'
import PropertyFinancing from '@master/model/kyc-form/PropertyFinancing'
import FullPersonalDetails from '@master/model/kyc-form/PersonalDetails'
import LongTermProtectionClient from '@master/model/kyc-form/need-analysis/protection/LongTermProtectionClient'
import NetWorth from '@master/model/kyc-form/finances/NetWorth'
import AI from '@master/model/kyc-form/AI'
import PoliticalExposedPersonDeclaration from '@master/model/kyc-form/external/PoliticalExposedPersonDeclaration'
import DependantsProtection from '@master/model/kyc-form/need-analysis/protection/DependantsProtection'
import ThirdPartyPayor from './ThirdPartyPayor'
import SwitchingWithPolicies from './SwitchingWithPolicies'
import SignatureInfo from './SignatureInfo'
import AML from './AML'
import DeathProtectionModel from 'src/views/kyc-form/component/needsAnalysis/models/DeathProtectionModel'
import LongTermCareDisabilityIncomeModel from '@/views/kyc-form/component/needsAnalysis/models/LongTermCareDisabilityIncomeModel'
import HospitalizationModel from 'src/views/kyc-form/component/needsAnalysis/models/HospitalizationModel'
import CriticalIllnessModel from 'src/views/kyc-form/component/needsAnalysis/models/CriticalIllnessModel'
import PregnancyProtectionModel from 'src/views/kyc-form/component/needsAnalysis/models/PregnancyProtectionModel'
import SavingForOtherPurposeModel from 'src/views/kyc-form/component/needsAnalysis/models/SavingForOtherPurposeModel'
import EventOfDisabilityModel from 'src/views/kyc-form/component/needsAnalysis/models/EventOfDisablityModel'
import DependantsProtectionModel from 'src/views/kyc-form/component/needsAnalysis/models/DependantsProtectionModel'
import PersonalAccidentModel from 'src/views/kyc-form/component/needsAnalysis/models/PersonalAccidentModel'
import RetirementModel from 'src/views/kyc-form/component/needsAnalysis/models/RetirementModel'
import PlanningForChildrenEducationModel from '@/views/kyc-form/component/needsAnalysis/models/PlanningForChildrenEducationModel'
import AffordabilityModal from './Affordability'
import NeedsAnalysisModel from '@/views/kyc-form/component/needsAnalysis/models/NeedsAnalysisModel'
import RequestSupervisorCallbackStatus from '@master/model/kyc-form/request-supervisor-callback'

export default class KYCClient {
  personalDetails: FullPersonalDetails
  aioi: AIOI
  budget: Budget
  cashFlow: CashFlow
  netWorth: NetWorth
  cka: CKA
  rpa: RPA
  personalAccidentNeeds: PersonalAccidentNeeds
  personalAccident: PersonalAccidentModel
  hospitalizationNeeds: HospitalizationNeeds
  pregnancyProtection: PregnancyProtectionModel
  other1Needs: OtherNeeds
  other2Needs: OtherNeeds
  lossOfIncome: LossOfIncomeNeeds
  outpatientExpenses: OutpatientExpenses
  callback: RequestSupervisorCallbackStatus
  criticalIllnessNeeds: CriticalIllnessModel
  deathProtectionNeeds: DeathProtectionModel
  // Needs Analysis - Hospitalization
  hospitalizationsNeeds: HospitalizationModel
  // Needs Analysis - Dependants Protection
  tempDepentantsProtectionNeeds: DependantsProtectionModel
  depentantsProtectionNeeds: DependantsProtectionModel
  // Needs Analysis - Retirement Needs
  retirementNeedsNew: RetirementModel

  disabilityIncomeNeeds: LongTermCareDisabilityIncomeModel
  disabilityProtectionNeeds: EventOfDisabilityModel
  longTermProtectionClient: LongTermProtectionClient
  dependantsProtection: DependantsProtection
  investmentNeeds: InvestmentNeeds
  retirementNeeds: RetirementNeeds
  savingsNeeds: SavingsNeeds
  specificGoalsNeeds: SpecificGoalsNeeds
  educationNeeds: PlanningForChildrenEducationModel
  investmentOption: InvestmentOption
  recommendationBasis: RecommendationBasis
  //switching: Switching
  switchingArray: SwitchingWithPolicies
  ecdd: ECDD
  beneficialOwner: BeneficialOwner
  myInfo: MyInfo
  amldoc: AMLDoc
  ai: AI
  pepDecl: PoliticalExposedPersonDeclaration
  thirdPartyPayor: ThirdPartyPayor

  propertyFinancing: PropertyFinancing

  savingForOtherPurpose: SavingForOtherPurposeModel

  // This is just for KYC PDF, it is not in use for KYC Form
  signatureInfo: SignatureInfo
  aml: AML
  needsAnalysis: NeedsAnalysisModel

  affordability: AffordabilityModal

  constructor (client: 'client_0' | 'client_1' = 'client_0', dateService: IDateService, projectSettings: IProjectSettings) {
    this.personalDetails = new FullPersonalDetails(client, dateService)
    this.aioi = new AIOI(client)
    this.budget = new Budget(client)
    this.cashFlow = new CashFlow(client)
    this.netWorth = new NetWorth(client)
    this.cka = new CKA(client)
    this.rpa = new RPA(client)
    this.personalAccidentNeeds = new PersonalAccidentNeeds(client)
    this.personalAccident = new PersonalAccidentModel(client)
    this.hospitalizationNeeds = new HospitalizationNeeds(client)
    this.pregnancyProtection = new PregnancyProtectionModel(client)
    this.lossOfIncome = new LossOfIncomeNeeds(client)
    this.outpatientExpenses = new OutpatientExpenses(client)
    this.callback = new RequestSupervisorCallbackStatus(client)
    this.other1Needs = new OtherNeeds(client, 'm1', 'Others 1')
    this.other2Needs = new OtherNeeds(client, 'm2', 'Others 2')
    this.criticalIllnessNeeds = new CriticalIllnessModel(client, projectSettings)
    this.deathProtectionNeeds = new DeathProtectionModel(client, projectSettings)
    this.hospitalizationsNeeds = new HospitalizationModel(client)
    this.tempDepentantsProtectionNeeds = new DependantsProtectionModel(client)
    this.depentantsProtectionNeeds = new DependantsProtectionModel(client)

    this.disabilityIncomeNeeds = new LongTermCareDisabilityIncomeModel(client)
    this.disabilityProtectionNeeds = new EventOfDisabilityModel(client)
    this.longTermProtectionClient = new LongTermProtectionClient(client)
    this.dependantsProtection = new DependantsProtection(client)
    this.investmentNeeds = new InvestmentNeeds(client)
    this.retirementNeeds = new RetirementNeeds(client)
    this.retirementNeedsNew = new RetirementModel(client)
    this.savingsNeeds = new SavingsNeeds(client)
    this.specificGoalsNeeds = new SpecificGoalsNeeds(client)
    this.educationNeeds = new PlanningForChildrenEducationModel(client)
    this.investmentOption = new InvestmentOption(client)
    this.recommendationBasis = new RecommendationBasis(client)
    //this.switching = new Switching(client)
    this.switchingArray = new SwitchingWithPolicies(client)
    this.ecdd = new ECDD(client)
    this.beneficialOwner = new BeneficialOwner(client, new CoreBOP())
    this.pepDecl = new PoliticalExposedPersonDeclaration(client)
    this.myInfo = new MyInfo(client)
    if (client === 'client_0') {
      this.propertyFinancing = new PropertyFinancing(client)
    }
    this.amldoc = new AMLDoc(client)
    this.ai = new AI(client)
    this.thirdPartyPayor = new ThirdPartyPayor(client)
    this.signatureInfo = new SignatureInfo(client)
    this.aml = new AML(client)
    this.needsAnalysis = new NeedsAnalysisModel(client)

    this.savingForOtherPurpose = new SavingForOtherPurposeModel(client, projectSettings)
    this.affordability = new AffordabilityModal(client)
  }

  get hasNeedToPlanFor () {
    return Object.keys(this).some((info) => {
      if (!(this[info] instanceof NeedsBase)) return false
      return this[info].plan === true
    })
  }
}
