<template>
  <div id="app" :theme="theme">
    <TheHeader v-if="currentUser && currentUser.isLogin" :customHeaders="customHeaders"/>
    <router-view/>
    <portal-target name="main-page"/>
    <!-- <TheFooter /> -->
    <AppLoader v-if="this.uiService.loading.hasLoadingTasks()" />
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-facing-decorator'
import TheHeader from '@core/components/header/TheHeader.vue'
import TheFooter from '@core/components/TheFooter.vue'
import nbrIcon from '@core/assets/svg/nbr-no-fill.svg'
import { IProjectInfo } from '@master/config/IProjectInfo'
import AppLoader from '@core/components/AppLoader.vue'
import { inject } from 'inversify-props'
import { UIService } from '@core/services/UIService'
import { SharedVariable } from '@core/model/SharedVariable'
import API from '@core/services/API'
import { IProjectSettings, Theme } from '@master/config/IProjectSettings'
import { UIOptions } from '@core/model/UIOptions'
import recruitIcon from '@core/assets/svg/recruit-no-fill.svg'
import ActivityIcon from '@/assets/header/activity.svg'
import { OktaService } from '@core/openapi/services/okta/api'
import { SystemService } from '@core/openapi/services/default/api/services/SystemService'

@Component({
  components: {
    TheHeader,
    TheFooter,
    AppLoader
  }
})
export default class App extends Vue {
  uiService = new UIService()
  uIOptions = new UIOptions()

  @inject()
  private sharedVariable: SharedVariable

  @inject()
  private $api: API

  @inject()
  private projectInfo: IProjectInfo

  @inject()
  private projectSettings: IProjectSettings

  private theme: Theme = 'Classic'

  async created () {
    console.log('redirectHome');
    // inject uiService here and set to sharedVariable to gain reactivity,
    // else the loading spinner wont show when added loading tasks
    this.sharedVariable.uiService = this.uiService
    const sysInfo = await SystemService.getSystemInformation()
    this.sharedVariable.sysInfo = sysInfo
    this.sharedVariable.options = this.uIOptions
    this.theme = this.projectSettings?.theme || this.theme // if theme is not set, default to 'classic'

    // @TODO: To implement it as a timeout interval
    const idToken = this.getCookie('idToken')
    if (idToken) {
      const jwtPayload = this.parseJwt(idToken)
      if (jwtPayload.exp < Date.now() / 1000) {
        const refreshToken = this.getCookie('refreshToken')
        const tokenResponse = await OktaService.refreshOktaToken(refreshToken)
        const itExpiryDate = new Date()
        itExpiryDate.setTime(tokenResponse.eit)
        const atExpiryDate = new Date()
        atExpiryDate.setTime(tokenResponse.eat)
        if (tokenResponse.idToken) {
          document.cookie = 'idToken=' + tokenResponse.idToken + ';expires=' + itExpiryDate.toUTCString() + ';path=/'
        }

        if (tokenResponse.refreshToken) {
          document.cookie = 'refreshToken=' + tokenResponse.refreshToken + ';expires=' + atExpiryDate.toUTCString() + ';path=/'
        }
      }
    }
  }

  get currentUser () {
    return this.$store.getters.currentUser
  }

  get customHeaders () {
    return [
      {
        disable: !['supervisor', 'director'].includes(this.currentUser.systemRole) && !this.currentUser.isSuperUser,
        icon: recruitIcon,
        customStyle: '--i-width: 26px',
        href: '/web/home-recruiter',
        label: 'Recruit'
      },
      {
        disable: !['recruit'].includes(this.currentUser.systemRole),
        icon: ActivityIcon,
        customStyle: '--i-width: 25px',
        href: `/web/recruit/${this.currentUser.accountID}`,
        label: 'My Activity Board'
      },
      {
        disable: !this.currentUser.isSuperUser,
        icon: nbrIcon,
        href: '/web/policy-list',
        label: 'NBR'
      }
    ]
  }

  @Watch('$route')
  updateTitle (to) {
    document.title = to.meta.title || this.projectInfo.brandName
  }

  parseJwt(token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    // Backup function - replaced 'buffer' by 'atob'
    // const jsonPayload = decodeURIComponent(Buffer.from(base64, 'base64').toString('ascii').split('').map(function (c) {
    //   return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
    // }).join(''))
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload);
  }

  getCookie (cname) {
    const name = cname + '='
    const decodedCookie = decodeURIComponent(document.cookie)
    const ca = decodedCookie.split(';')
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i]
      while (c.charAt(0) === ' ') {
        c = c.substring(1)
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length)
      }
    }
    return ''
  }
}
</script>

<style lang="scss">
@import '@/design';
@import '@/design/custom';
*,
*::before,
*::after {
  box-sizing: border-box;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-family: $font-family-default;
    font-size: $font-size-default;
}

input, select, textarea {
  font-family: $font-family-default;
  font-size: $font-size-default;
}

textarea {
  padding: 5px;
}

// fix calendar picker take up too many space and trucate date issue on chorome
::-webkit-calendar-picker-indicator {
  padding: 0;
  margin: 0;
}

#app {
  display: flex;
  flex-direction: column;
  // height: 100vh;
  // background-color: #F4F6F9;
  overflow-x: hidden;
}

// util
.red, .red-text {
  color: red;
}

.page {
  // position: absolute;
  width: 100%;
  top: 65px;
  bottom: 30px;
  padding-top: 33px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 150px;
  // overflow: auto;
 // background-color: #F4F6F9;
}

header .logo {
  background-image: url('./assets/propel-logo.svg');
}

:focus-visible {
    outline: none;
}

:root {
  --el-color-primary-light-5:#F0E8F3 ;
  --el-color-primary-dark-2 :#863999;
}

</style>
