import { ClientBase } from '@master/model/ClientBase';
import { ArrayBase } from '../ArrayBase'
import { Ignore } from '@master/annotation/Ignore'
import { IDateService } from '@master/services/IDateService'

export default class Affordability extends ClientBase {

  // @Ignore()
  // dateService: IDateService


  constructor (type: 'client_0' | 'client_1' = 'client_0') {
    super(type)

  }

  supplementaryRetirementError: boolean | null = false;

  annualSurplus?: number = undefined
  totalCash?: number = undefined

  cpfSRSubstantial?: string = ''
  cpfSRSubstantialError?: boolean = false

  cpfCashSubstantial?: string = ''
  cpfSOSubstantial?: string = ''
  cpfSASubstantial?: string = ''
  cpfMASubstantial?: string = ''

  reasonForDeviation?: string = ''
  affSelectedOption?: string = ''
  affSelectedOptionError?: string = ''
  isCashEqual?: string = ''
  isCashSectionError?: boolean = false
  // annualSurplus?: number = undefined
  // totalCash?: number = undefined

  cpfAnnualAmount?: number = undefined
  cpfSingleAmount?: number = undefined

  cpfSpecialAnnualAmount?: number = undefined
  cpfSpecialSingleAmount?: number = undefined


  cpfMaAnnualAmount?: number = undefined
  cpfMaSingleAmount?: number = undefined

  sRSAnnualAmount?: number = undefined
  sRsSingleAmount?: number = undefined


  cashAnnualAmount?: number = undefined
  cashSingleAmount?: number = undefined

  budgetTakenFrom?: string = ''
  cashSubstantial?: string = ''



}


// export class Affordability extends ArrayBase {

//   type = 'Affordability'

//   @Ignore()
//   dateService: IDateService

//   constructor(dateService: IDateService) {
//     super()
//   }

//   // cpfSOSubstantial?: string = ''
//   // cpfSASubstantial?: string = ''
//   // cpfMASubstantial?: string = ''






// }
