import { IProjectSettings } from '@master/config/IProjectSettings'
import CoreKYCClient from '@master/model/kyc-form/KYCClient'
import { IDateService } from '@master/services/IDateService'
import AML from './AML'
import BeneficialOwner from './BeneficialOwner'
import Budget from './finances/Budget'
import CashFlow from './finances/JParaCashFlow'
import NetWorth from './finances/NetWorth'
import PersonalDetails from './PersonalDetails'
import RecommendationBasis from './RecommendationBasis'
import PersonalAccidentNeeds from './needs-analysis/health/CustomPersonalAccidentNeeds'
import RPA from './CustomRPA'
import ExistingInsurenceDetails from '@master/model/kyc-form/ExistingInsurence'
import EIDetails, { Investments } from '@master/model/kyc-form/current-plans/ExistingInvestment'
import Affordability from '@master/model/kyc-form/Affordability'
import ClientSummary from '@master/model/kyc-form/ClientSummary'


export default class KYCClient extends CoreKYCClient {
  declare personalDetails: PersonalDetails
  declare budget: Budget
  declare cashFlow: CashFlow
  declare netWorth: NetWorth
  declare recommendationBasis: RecommendationBasis
  declare aml: AML
  declare beneficialOwner: BeneficialOwner
  declare personalAccidentNeeds: PersonalAccidentNeeds
  declare rpa: RPA
  declare existingInsurence: ExistingInsurenceDetails
  declare existingInvestment: EIDetails
  declare affordability: Affordability
  declare summary: ClientSummary

  constructor (client: 'client_0' | 'client_1' = 'client_0', dateService: IDateService, projectSettings: IProjectSettings) {
    super(client, dateService, projectSettings)
    this.aml = new AML(client)
    this.personalDetails = new PersonalDetails(client, dateService)
    this.budget = new Budget(client)
    this.cashFlow = new CashFlow(client)
    this.netWorth = new NetWorth(client)
    this.recommendationBasis = new RecommendationBasis(client)
    this.beneficialOwner = new BeneficialOwner(client)
    this.personalAccidentNeeds = new PersonalAccidentNeeds(client)
    this.rpa = new RPA(client)
    this.existingInsurence = new ExistingInsurenceDetails(client)
    this.existingInvestment = new EIDetails(client)
    this.affordability = new Affordability(client)
    this.summary = new ClientSummary(client)
  }
}
