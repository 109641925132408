/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type StampBeneficialOwnerPdfSignatureDTO = {
    kycPdfOid: string;
    template: StampBeneficialOwnerPdfSignatureDTO.template;
}

export namespace StampBeneficialOwnerPdfSignatureDTO {

    export enum template {
        BO1 = 'BO1',
        BO2 = 'BO2',
    }


}
