import { IProjectSettings } from '@master/config/IProjectSettings'
import { Ignore } from '@master/annotation/Ignore'
import NeedsBase from '@master/model/kyc-form/need-analysis/NeedsBase'
import KYCClient from '@company-common/model/kyc-form/KYCClient'
import { NA_ERROR_MSG } from '../utils/constants'

export default class PregnancyProtectionModel extends NeedsBase {
  @Ignore()
  projectSettings: IProjectSettings

  @Ignore()
  selectedClient?:  KYCClient = undefined

  noOfFeotus?: number = undefined
  pregnancyThroughIVF?: string = ""
  coverageAmount?: number = undefined
  existingResources?: number = undefined
  existingResourcesReason?: string = undefined
  totalAssuredMaternity?: number = undefined

  existingResourcesCR?:boolean = undefined

  get isCompleted() {
    // return !!this.noOfFeotus && !!this.pregnancyThroughIVF && !!this.coverageAmount && !!this.existingResources && !this.exResourcesMaternityError;
    // return !!this.noOfFeotus && !!this.pregnancyThroughIVF && !!this.coverageAmount;

    const reasonCheck =
    (this.existingResourcesReasonStatus && !this.existingResourcesReason);
    return this.noOfFeotus != null && !!this.pregnancyThroughIVF && this.coverageAmount != null && !this.exResourcesMaternityError && !reasonCheck;
  }

  get grandTotal() {    
    // return (this.existingResources - this.coverageAmount) || 0;
    if(this.coverageAmount === undefined || this.coverageAmount === 0){
      return this.existingResources
    } else {
      return (this.existingResources || 0) - (this.coverageAmount || 0);
    }
  }

  get existingResourcesReasonStatus() {
    let needJustification = false;
    if(this.existingResources < this.totalAssuredMaternity) 
      needJustification = true;
    else {
      this.existingResourcesReason = undefined;
      this.existingResourcesCR = false;
    }

    return needJustification;
  }

  get exResourcesMaternityError () {
    if (this.existingResources > this.totalAssuredMaternity){
      return NA_ERROR_MSG.TB
    } else {
      return null
    }
  }
}
