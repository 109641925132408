/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ESupplimentaryFormDto = {
    oid?: string;
    kycPdfOid: string;
    clientName: string;
    spouseName?: string;
    formName: string;
    formStatus: ESupplimentaryFormDto.formStatus;
    clientIdentificationNumber: string;
    clientIdentificationType: string;
    spouseIdentificationNumber?: string;
    spouseIdentificationType?: string;
    adviserName: string;
    adviserCode: string;
    adviserBranch: string;
    caseNo: string;
    products: Array<any>;
    productName: string;
    investmentAmount: number;
    dateOfInvestment: number;
    clientObjective: string;
    basisOfRecommendation1: string;
    basisOfRecommendation2: string;
    basisOfRecommendation3: string;
    otherClarifications?: string;
    languageUsed: string;
    signingMethod: string;
    status?: ESupplimentaryFormDto.EsuppStatus;
}

export namespace ESupplimentaryFormDto {

    export enum formStatus {
        INCOMPLETE = 'incomplete',
        COMPLETE = 'complete',
        PENDING = 'pending',
    }

    export enum EsuppStatus {
        readonly = "readonly",
        editable = "editable"
    }


}
