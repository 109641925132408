import { SharedVariable } from '@core/model/SharedVariable'
import { UserMeta } from '@core/model/common/UserMeta'
import { AdviserLicenceDto, AdviserLicenceService } from '@core/openapi/services/adviser-licence/api'
import { LicenceProductMappingDto, LicenceProductMappingService, ProductLicenceDto, ProductLicenceService } from '@core/openapi/services/product-licence/api'
import { CountryService } from '@core/openapi/services/default/api'
import { AccountService, FullMochaUserInfo, UserAccountCreationDTO, UserAccountImportDto, UserAccountListResponseDTO, UserAccountUpdateDTO, CountryDetailDTO } from '@core/openapi/services/user-account/api'
import { AccountListDTO } from '@core/openapi/services/user-account/dto/AccountListDTO'
import { AdviserSalesCodesDto, AdviserSalesCodesService, FilterAdviserSalesCodesDto, UpdateAdviserSalesCodesDto } from '@core/pages/account/api'
import { UIService } from '@core/services/UIService'
import { cid, container } from 'inversify-props'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { Getter } from "vuex-facing-decorator";
import SpouseDetailsMeta from '@core/pages/user-profile/sections/user-adviser-details-components/model/SpouseDetailsMeta'
import { NextOfKinDetailsMeta } from '@core/pages/user-profile/sections/user-adviser-details-components/model/NextOfKinDetailsMeta'
import moment from 'moment'
import { MemberStructureDTO } from '@core/openapi/services/user-account/api/models/AdviserProfileResponseDTO'
const sharedVariable = container.get<SharedVariable>(cid.SharedVariable)
const uiService = sharedVariable.uiService ? sharedVariable.uiService : new UIService()

@Module({ namespaced: true, name: 'UserStoreV2' })
export default class UserStoreV2 extends VuexModule {
  loading = false
  user: FullMochaUserInfo = null
  proxyInformation = {  
            proxyManagerOid: null,
            proxyManagerFromDate: null,
            proxyManagerToDate: null
          }  
  userListResponse: UserAccountListResponseDTO = null
  productPrincipals: AdviserSalesCodesDto[] = []
  currentUserInView: FullMochaUserInfo = null
  currentUserLicenses: AdviserLicenceDto[] = []
  availableLicences: ProductLicenceDto[] = []
  formSubmitState: boolean = false;
  countryList: CountryDetailDTO[] = [];
  filteredCountryList:CountryDetailDTO[] = [];
  filteredCountryCount=0;
  defaultCountry: CountryDetailDTO = null;
  adviserLOAList: AdviserLicenceDto[] = []
  saveClicked: boolean = false;
  currentUserinViewRawResponse: FullMochaUserInfo = null
  representativeBranch: any
  reportingDirectorOid: any
  branchGroupName: any
  managerUnitName: any
  reportingManagerOid: any
  adviserSalesCodesList: UpdateAdviserSalesCodesDto[] = [];
  adviserSalesCodesCount = 0;
  exportCsvData: any
  activeAdvisers: any[] = []
  adviserHaveLicence:boolean = false
  adviserMemberStructureBranch: string[] = []
  adminStatus = []
  adviserStatus = []
  systemRoles = []
  adviserSalesCodes: AdviserSalesCodesDto[] = []
  userProfileLoaded: boolean = false
  structureOfAdviser = null

  @Action({ rawError: true })
  async fetchUser(oid: string) {
    try {
      const user = await AccountService.retrieveUserAccountInformation(oid)
      this.context.commit('setUser', user)
    } catch (error) {
      uiService.alert.show({
        type: 'error',
        message: error
      })
    }
  }

  @Action({})
  updateAdviserHaveLicence(payload) {
    this.context.commit('updateAdviserHaveLicenceMutn', payload)
  }

  @Mutation
  updateAdviserHaveLicenceMutn(data){
    this.adviserHaveLicence = data
  }

  getUser() {
    return this.currentUserInView
  }

  @Mutation
  setUser(user: FullMochaUserInfo) {
    this.user = user
  }
  @Mutation
  setProxyManager(proxyInfo) {
    this.proxyInformation = proxyInfo
  }

  @Mutation
  saveReportingManagerDate(data) {
    this.representativeBranch = data.representativeBranch
    this.reportingDirectorOid = data.reportingDirectorOid
    this.branchGroupName = data.branchGroupName
    this.managerUnitName = data.managerUnitName
    this.reportingManagerOid = data.reportingManagerOid
  }

  @Action({ rawError: true })
  async fetchUserList(requestDTO: AccountListDTO) {
    const { headers, representativeCodes, companyCodes, rnfNumbers, oids, page, limit, retrieveAll, search } = requestDTO
    try {
      const response = await AccountService.listUserAccounts(headers.join(','), representativeCodes.join(','), companyCodes.join(','), rnfNumbers.join(','), oids.join(','), page, limit, retrieveAll, search)
      // if any changes done here, kindly check 'User Administration' list in admin
      // const userList = response.data.map(_user => {
      //   const user = new UserMeta()
      //   Object.assign(user, _user)
      //   return user
      // })
      this.context.commit('setUserList', response)
    } catch (error) {
      uiService.alert.show({
        type: 'error',
        message: error
      })
    }
  }

  @Mutation
  setUserList(response: UserAccountListResponseDTO) {
    this.userListResponse = response
  }

  @Mutation
  setSaveClicked(data) {
    this.saveClicked = data
  }


  get userListData() {
    return this.userListResponse
  }

  @Action({ rawError: true })
  async createUser(createDTO: UserAccountCreationDTO) {
    try {
      const response = await AccountService.createUserAccount(createDTO)
      uiService.alert.show({
        type: 'success',
        message: 'User has been successfully created.'
      })
      return response
    } catch (error) {
      throw new Error(error)
    }
  }
  /* @Action({ rawError: true })
  async massImportUsers(importDTO: UserAccountImportDto[]) {
    try {
      const response = await AccountService.massImportUsersIntoSystemV3(importDTO)
      return response
      /* if (response?.imported) {
        return response
      } else {
        if (response?.errors && response?.errors.length > 0) {
          uiService.alert.show({
            type: 'error',
            message: 'Errors while trying to import users.'
          })
        }
      } 
    } catch (error) {
      uiService.alert.show({
        type: 'error',
        message: error
      })
    }
  } */

  @Action({ rawError: true })
  async massExportUsers(type) {
    try {
      const exportUsers = await AccountService.massExportUsersFromSystemV3(type)
      const BOM = '\ufeff' // add BOM as first char in the file to force using UTF-8 encoding
      const blob = new Blob([BOM + exportUsers], { type: 'text/csv;charset=utf-8' })
      const elem = window.document.createElement('a')
      elem.href = window.URL.createObjectURL(blob)
      elem.download = `UserAdmin_Export_${moment().format('DDMMYY_HHmmss')}.csv`
      document.body.appendChild(elem)
      elem.click()
      document.body.removeChild(elem)
    } catch (error) {
      uiService.alert.show({
        type: 'error',
        message: error
      })
    }
  }

  @Action({ rawError: true })
  async retrieveUserInView(accountOid: string) {
    try {
      const response = await AccountService.retrieveUserAccountInformation(accountOid)
      this.context.commit('updateUserInView', response)
      this.context.commit('updateUserInViewRawResponse', response)
    } catch (error) {
      uiService.alert.show({
        type: 'error',
        message: error
      })
    }
  }

  @Action({ rawError: true })
  async saveUser(requestBody: UserAccountUpdateDTO) {
    this.context.commit('setSaveClicked', true)
    try {
      if(!requestBody.hasOwnProperty('proxyManagerInformation')) {
        requestBody.proxyManagerInformation = {
            proxyManagerOid: '',
            proxyManagerFromDate: null,
            proxyManagerToDate: null
        }
      }
      const response = await AccountService.updateUserAccount(requestBody)
      this.context.commit('updateUserInView', response)
      uiService.alert.show({
        type: 'success',
        message: 'Successfully saved'
      })
    } catch (error) {
      uiService.alert.show({
        type: 'error',
        message: error
      })
    }
  }

  @Mutation
  setLoading(state: boolean) {
    this.loading = state
  }

  @Mutation
  updateUserInView(user: FullMochaUserInfo) {
    this.currentUserInView = user
    if (this.currentUserInView && this.currentUserInView.userPersonalInfo) {
      this.currentUserInView.userPersonalInfo.personalDetails = this.currentUserInView.userPersonalInfo.personalDetails === null ? {} : this.currentUserInView.userPersonalInfo.personalDetails
      this.currentUserInView.userPersonalInfo.spouseDetails = this.currentUserInView.userPersonalInfo.spouseDetails === null ? new SpouseDetailsMeta() : this.currentUserInView.userPersonalInfo.spouseDetails
      this.currentUserInView.userPersonalInfo.nextOfKinDetails = this.currentUserInView.userPersonalInfo.nextOfKinDetails === null ? new NextOfKinDetailsMeta() : this.currentUserInView.userPersonalInfo.nextOfKinDetails
    }

    if (this.currentUserInView && this.currentUserInView.userCompanyInfo) {
      this.currentUserInView.userCompanyInfo.paymentStatus = this.currentUserInView.userCompanyInfo?.paymentStatus || "commissionPaid"
    }
  }

  @Mutation
  updateUserInViewRawResponse(user: FullMochaUserInfo) {
    this.currentUserinViewRawResponse = user
  }

  @Action({ rawError: true })
  async retrieveAvailableLicences() {
    try {
      const response = await ProductLicenceService.listSystemAdviserLicences()
      this.context.commit('setAvailableLicences', response)
    } catch (error) {
      uiService.alert.show({
        type: 'error',
        message: error
      })
    }
  }

  @Mutation
  setAvailableLicences(response: ProductLicenceDto[]) {
    this.availableLicences = response
  }

  @Action({ rawError: true })
  async retrieveUserLicenses(userOid: string) {
    try {
      const response = await AdviserLicenceService.retrieveSpecificUserAdviserLicences(userOid)
      this.context.commit('setUserLicense', response)
    } catch (error) {
      uiService.alert.show({
        type: 'error',
        message: error
      })
    }
  }

  @Mutation
  setUserLicense(licences: AdviserLicenceDto[]) {
    console.log(licences)
    this.currentUserLicenses = licences
  }

  @Action({ rawError: true })
  async updateUserLicense(requestBody: AdviserLicenceDto) {
    try {
      const response = await AdviserLicenceService.updateLicence(requestBody)
      return response
    } catch (error) {
      uiService.alert.show({
        type: 'error',
        message: error
      })
    }
  }

  @Action({ rawError: true })
  async fetchPrincipalList(params) {
    const { retrieveAll, filters } = params;

    this.context.commit('setLoading', true)
    //const filter:any = { adviserOid:adviserOid} // TODO: "adviserOid" removed here search and apagination
    const data: any = await AdviserSalesCodesService.retrieveAdviserSalesCodesList(params)
    const res = data.hasOwnProperty('data') ? data.data : data
    this.context.commit('setProductPrincipals', res)
    this.context.commit('setLoading', false)
  }

  @Mutation
  setProductPrincipals(data: AdviserSalesCodesDto[]) {
    this.productPrincipals = data
  }

  @Action
  async saveProductPrincipal(principal: UpdateAdviserSalesCodesDto) {
    // this.context.commit('setLoading', true)
    await AdviserSalesCodesService.saveAdviserSalesCodes(principal)
    this.context.dispatch('fetchPrincipalList', principal.adviserOid)
    // this.context.commit('setProductPrincipals', data)
  }

  /**
   * // form submission handling 
   * @param status 
   */

  @Action({ rawError: true })
  updateFormSubmitState(status: boolean) {
    this.context.commit("updateForm", status);
  }
  @Mutation
  updateForm(status: boolean) {
    this.formSubmitState = status;
  }
  @Action
  getFormStatus() {
    return this.formSubmitState;
  }
  @Getter
  getFormState() {
    return this.formSubmitState;
  }

  @Action({ rawError: true })
  async fetchCountryList() {
    try {
      const response = await AccountService.getCountryList();
      this.context.commit('setCountryList', response);
    } catch (error) {
      uiService.alert.show({
        type: 'error',
        message: error
      });
    }
  }

  @Mutation
  setCountryList(data: CountryDetailDTO[]) {
    this.countryList = data;
    // Set default country based on priorit 
    if (data && data.length) {
      this.defaultCountry = data.find((item) => item.priority === 1);
    }
  }

  @Action({ rawError: true })
  async fetchFilteredCountryList(params) {
    const { limit, page, search } = params;
    try {
      const response = await CountryService.listCountriesV2({limit,page,search});
      this.context.commit('setFilteredCountryList', response);
    } catch (error) {
      uiService.alert.show({
        type: 'error',
        message: error
      });
    }
  }

  @Mutation
  setFilteredCountryList(data) {
    this.filteredCountryList = data.data;
    this.filteredCountryCount= data.recordsTotal
    // Set default country based on priorit 
    if (data && data.length) {
      this.defaultCountry = data.find((item) => item.priority === 1);
    }
  }

  @Action
  async fetchAdvisorDTO(userOid) {
    const response = await AdviserLicenceService.retrieveSpecificUserAdviserLicences(userOid)
    this.context.commit('setAdviserLOAList', response);
  }

  @Mutation
  setAdviserLOAList(adviserLOAList) {
    this.adviserLOAList = adviserLOAList
  }

  @Action
  async saveAdviserLOA(newLicense: AdviserLicenceDto) {
    try {
      await AdviserLicenceService.addAdviserLicence(newLicense)
      this.fetchAdvisorDTO(newLicense?.userOid)
      uiService.alert.show({
        type: 'success',
        message: 'Adviser License LOA has been successfully created.'
      })

    } catch (error) {
      uiService.alert.show({
        type: 'error',
        message: error
      })
    }
  }

  @Action
  async deleteAdviserLicense(license) {
    await AdviserLicenceService.deleteAdviserLicence(license.oid)
    uiService.alert.show({
      type: 'success',
      message: 'Adviser License LOA has been successfully deleted.'
    })
    this.fetchAdvisorDTO(license?.userOid)
  }

  @Action
  async editAdviserLOA(newLicense: AdviserLicenceDto) {
    try {
      await AdviserLicenceService.updateLicence(newLicense)
      this.fetchAdvisorDTO(newLicense?.userOid)
      uiService.alert.show({
        type: 'success',
        message: 'Adviser License LOA has been successfully updated.'
      })
    } catch (error) {
      uiService.alert.show({
        type: 'error',
        message: error
      })
    }
  }

  @Action({ rawError: true })
  async massImportAdviserPromotionDemotion(importDTO) {
    try {
      const response = await AccountService.massImportAdviserPromotionDemotion(importDTO)
      return response
    } catch (error) {
      uiService.alert.show({
        type: 'error',
        message: error
      })
    }
  }

  @Action({ rawError: true })
  async massImportAdvisers(file: any) {
    try {
      const response = await AccountService.massImportUsersIntoSystemV3(file, 'adviser')
      return response
    } catch (error) {
      uiService.alert.show({
        type: 'error',
        message: error
      })
    }
  }

  @Action({ rawError: true })
  async massImportAdmins(file: any) {
    try {
      const response = await AccountService.massImportUsersIntoSystemV3(file, 'admin')
      return response

    } catch (error) {
      uiService.alert.show({
        type: 'error',
        message: error
      })
    }
  }

  @Action({ rawError: true })
  async retrieveAdviserSalesCodes(params) {
    const { limit, page, search } = params;

    try {
      const result =
        await AdviserSalesCodesService.retrieveAdviserSalesCodesList({
          limit,
          page,
          search,
        });
      this.context.commit("setAdviserSalesCodes", result);
    } catch (error) {
      alert(error.message);
    }
  }
  @Mutation
  setAdviserSalesCodes(result) {
    this.adviserSalesCodesList = result.data;
    this.adviserSalesCodesCount = result.recordsTotal;
  }
  @Action
  async adviserSalesCodesCsvExport() {
    try {
      const result = await AdviserSalesCodesService.csvExport();
      this.context.commit("setAdviserSalesCodesCsv", result);
    } catch (error) {
      alert(error.message);
    }
  }

  @Mutation
  setAdviserSalesCodeCsv(response) {
    this.exportCsvData = response;
  }
  @Action({ rawError: true })
  async adviserSalesCodeExport() {
    try {
      const exportAdviserSalesCode = await AdviserSalesCodesService.csvExport()
      const BOM = '\ufeff' // add BOM as first char in the file to force using UTF-8 encoding
      const blob = new Blob([BOM + exportAdviserSalesCode], { type: 'text/csv;charset=utf-8' })
      const elem = window.document.createElement('a')
      elem.href = window.URL.createObjectURL(blob)
      elem.download = `AdviserSalesCodeExport_${moment().format('DDMMYYYY')}.csv`
      document.body.appendChild(elem)
      elem.click()
      document.body.removeChild(elem)
    } catch (error) {
      uiService.alert.show({
        type: 'error',
        message: error
      })
    }
  }
  @Mutation
  setActiveAdviserUserList(data: any[]) {
    this.activeAdvisers = data
  }
  @Action
  async fetchActiveAdviserUserList(additionalKeys?: boolean) {
    try {
      const activeAdviserUsers = await AccountService.getAdviserDropdown(null, null, null, additionalKeys)
      this.context.commit("setActiveAdviserUserList", activeAdviserUsers.data)

      return this.activeAdvisers
    } catch (error) {
      uiService.alert.show({
        type: 'error',
        message: error
      })
    }
  }
  @Action
  async fetchActiveAdviserUserDropdown(params) {
    const response = await AccountService.getAdviserDropdown(params?.search, params?.limit) as any

    return response.data.map((user: any) => {
      return {
        label: user.fullnameLabel,
        value: user.oid,
      }
    })
  }
  @Mutation
  setAdviserMemberStructure(response: MemberStructureDTO) {
    this.structureOfAdviser = response as MemberStructureDTO;
  }
  @Mutation
  setAdviserMemberBranchStructure(response: string[]) {
    this.adviserMemberStructureBranch = response;
  }

  @Mutation
  setAccountStatusAdviser(response) {
    this.adviserStatus = response;
  }
  @Mutation
  setAdminAccountStatus(response) {
    this.adminStatus = response;
  }
  @Mutation
  setSystemRoles(response) {
    this.systemRoles = response;
  }
  @Mutation
  setAdviserSalesCodesList(response) {
    this.adviserSalesCodes = response
  }
  @Mutation
  setUserProfileLoaded(status: boolean) {
    this.userProfileLoaded = status
  }
  @Action
  updateUserProfileLoadedStatus(status: boolean) {
    this.context.commit("setUserProfileLoaded", status)
  }
  @Mutation
  updateUserProfileData(response) {
    this.adviserSalesCodesList = response ? response.allSalesCodesOfAdvisers : null
    this.systemRoles = response && response.ACCOUNT_SYSTEM_ROLES.kvl ? JSON.parse(response.ACCOUNT_SYSTEM_ROLES.kvl) : null
    this.adminStatus = response && response.ACCOUNT_STATUS_ADMIN.kvl ? JSON.parse(response.ACCOUNT_STATUS_ADMIN.kvl) : null
    this.adviserStatus = response && response.ACCOUNT_STATUS_ADVISER ? JSON.parse(response.ACCOUNT_STATUS_ADVISER.kvl) : null
    this.adviserMemberStructureBranch = response ? response.memberBranches : null
    this.adviserLOAList = response ? response.adviserLicences : null
    this.structureOfAdviser = response ? response.memberStructure : null
    this.availableLicences = response ? response.productLicenseTypes : null
    this.currentUserLicenses = response ? response.adviserLicences : null
    this.adviserSalesCodes = response ? response.allSalesCodesOfAdvisers : null
  }

  @Action
  async getCompleteAdviserProfile(adviserOid: string) {
    try {
      const response = await AccountService.adviserProfile(adviserOid)
      this.context.commit('updateUserInView', response.userProfile)
      this.context.commit('updateUserInViewRawResponse', response.userProfile)
      this.context.commit("updateUserProfileData", response)
      this.context.commit('setUserProfileLoaded', true)
      return response
    } catch (error) {
      uiService.alert.show({
        type: 'error',
        message: error
      })
    }
  }
  @Action
  async resetProfileDetailInfo() {
    /* this store update will reset value in adviser/admin profile detail store once user navigate to user list page  so that update user information will be fetched to load  the Adviser/Admin Profile as intended
    */
    this.context.commit('updateUserInView', null)
    this.context.commit('updateUserInViewRawResponse', null)
    this.context.commit("updateUserProfileData", null)
    this.context.commit('setUserProfileLoaded', false)
  }
}
