import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/success.gif'
import _imports_1 from '@/assets/warning.gif'
import _imports_2 from '@/assets/info.gif'
import _imports_3 from '@/assets/cross_line.svg'


const _hoisted_1 = { class: "alert-popup-wrapper" }
const _hoisted_2 = { class: "alert-popup-wrapper__body" }
const _hoisted_3 = {
  key: 0,
  class: "success-error-icon",
  src: _imports_0
}
const _hoisted_4 = {
  key: 1,
  class: "warning-icon",
  src: _imports_1
}
const _hoisted_5 = {
  key: 2,
  class: "warning-icon",
  src: _imports_2
}
const _hoisted_6 = { class: "alert-popup-wrapper__body__text-box" }
const _hoisted_7 = {
  key: 0,
  class: "alert-popup-wrapper__body__text-box__title"
}
const _hoisted_8 = { class: "alert-popup-wrapper__body__text-box__msg" }
const _hoisted_9 = { class: "alert-popup-wrapper__body__text-box__msg" }
const _hoisted_10 = { class: "alert-popup-wrapper__actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    modelValue: _ctx.showPopup,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.showPopup) = $event)),
    center: "",
    "modal-class": "popup-overlay",
    class: "popup"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_ctx.type === 'success')
            ? (_openBlock(), _createElementBlock("img", _hoisted_3))
            : _createCommentVNode("", true),
          (_ctx.type === 'warning')
            ? (_openBlock(), _createElementBlock("img", _hoisted_4))
            : _createCommentVNode("", true),
          (_ctx.type === 'alert')
            ? (_openBlock(), _createElementBlock("img", _hoisted_5))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_6, [
            (_ctx.title)
              ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.title), 1))
              : _createCommentVNode("", true),
            _renderSlot(_ctx.$slots, "msg", {}, () => [
              _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.message), 1)
            ]),
            _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.secondmessage), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("button", {
            class: _normalizeClass([_ctx.showTwoButton ===undefined || !_ctx.showTwoButton ? 'btn-default' : 'btn-bordered']),
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
          }, _toDisplayString(_ctx.btnText), 3),
          (_ctx.showTwoButton)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                class: "btn-default",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('action')))
              }, _toDisplayString(_ctx.actionBtnText), 1))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", {
          class: "alert-popup-wrapper__close",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('close')))
        }, _cache[4] || (_cache[4] = [
          _createElementVNode("img", { src: _imports_3 }, null, -1)
        ]))
      ])
    ]),
    _: 3
  }, 8, ["modelValue"]))
}