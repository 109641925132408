import { CSVModelInterface } from '@core/services/csv/CSVModel.interface';
import { ProductDTO } from './ProductDTO';
import { Expose } from 'class-transformer';
import { IsNotEmpty, IsNotEmptyAndOneOfThese, IsOneOfThese } from '@core/services/csv/CSVCustomValidator';
import { IsOptional } from 'class-validator';

export class ProductCSVModel extends CSVModelInterface implements ProductDTO {
  @Expose()
  @IsOptional()
  oid=""
  
  @Expose()
  @IsNotEmpty()
  indicator = 'Insurance - ILP';
  
  @Expose()
  @IsNotEmpty()
  private 'product_name' = 'Singlife Savvy Invest';
  get productName() {
    return this['product_name'];
  }

  @Expose()
  @IsOptional()
  private 'product_code' = 'LF3';
  get productCode() {
    return this['product_code'];
  }
  
  @Expose()
  @IsNotEmpty()
  private 'provider_name' = 'Singlife';
  get providerName() {
    return this['provider_name'];
  }

  @Expose()
  @IsOptional()
  private 'product_type_name' = 'Investment-linked plans (RP)';
  get productTypeName() {
    return this['product_type_name'];
  }
  
  @Expose()
  @IsOptional()
  private 'product_type_code' = 'ILPRP';
  get productTypeCode() {
    return this['product_type_code'];
  }

  @Expose()
  @IsNotEmpty()
  private 'mdrt_category' = 'Risk Protection';
  get mdrtCategory() {
    return this['mdrt_category'];
  }

  @Expose()
  @IsNotEmpty()
  private 'product_category' = 'ILP';
  get productCategory() {
    return this['product_category'];
  }

  @Expose()
  @IsOptional()
  private 'product_family_tag' = 'Product_Family_Tag';
  get productFamilyTag() {
    return this['product_family_tag'];
  }
  	
  @Expose()
  @IsOptional()
  private 'plan_name' = 'Singlife Savvy Invest';
  get planName() {
    return this['plan_name'];
  }

  @Expose()
  @IsOptional()
  private 'plan_code' = 'LF3';
  get planCode() {
    return this['plan_code'];
  }
  
  @Expose()
  @IsOptional()
  private 'product_group' = 'ILP';
  get productGroup() {
    return this['product_group'];
  }
  
  @Expose()
  @IsOptional()
  private 'effective_from' = '14/03/2022  12:00:00 AM';
  get effectiveFrom() {
    return this['effective_from'];
  }
  
  @Expose()
  @IsOptional()
  private 'effective_to' = '14/03/2022  12:00:00 AM';
  get effectiveTo() {
    return this['effective_to'];
  }

  @Expose()
  @IsNotEmpty()
  @IsNotEmptyAndOneOfThese(['Yes', 'No'])
  private 'training_mandatory' = 'No';
  get trainingMandatory() {
    return this['training_mandatory'] === 'Yes' ? true : false;
  }

  @Expose()
  @IsOptional()
  private 'product_needs' = 'Child Education, Retirement, Savings';
  get productNeeds() {
    return this['product_needs'];
  }

  @Expose()
  @IsOptional()
  private 'product_feature' = `- Whole life, regular premium investment-linked plan that provides investment opportunities as well as protection against death and Terminal Illness.
  - 100% of the premium amount paid for this plan will be invested into the ILP sub-funds chosen by the policyholder.
  - Minimum Investment Period (MIP) available under this plan: 3 years (Fixed), 5 years (Fixed), 5 years (Flexible), 10 years (Fixed), 10 years (Flexible) and 20 years (Flexible).
  - MIP can only be chosen at point of application and it cannot be changed after the policy is incepted.
  - MIP refers to a period chosen by the policyholder starting from the policy effective date whereby:
  (a) a premium shortfall charge (where applicable) will be applied if the policyholder misses any basic regular premium;
  (b) a partial withdrawal charge will be applied if the policyholder makes a partial withdrawal from his/her policy (except for withdrawal under the Life Stage Benefit); and/or
  (c) a surrender charge will be applied if the policyholder fully surrender his/her policy.
  - Welcome Bonus is payable upon receipt of each basic regular premium paid for the first policy year, in the form of additional units. Welcome bonus = applicable welcome bonus rate (%) x basic regular premium paid for the first policy year.
  - Loyalty Bonus (as a % of account value) is payable starting from the policy anniversary immediately after the end of the MIP and every policy anniversary thereafter, in the form of additional units, provided that the policy is in force and there is no withdrawal on the policy in the last 12 months from the point when the Loyalty Bonus is payable, except for withdrawals under the Life Stage Benefit.
  - Upon occurrence of a life stage event, the policyholder can make a penalty free withdrawal of up to 10% of the account value under the Life Stage Benefit subject to the terms and conditions of this benefit, up to a maximum of 2 times during the policy term. The life stage events covered under this benefit include the life assured changes the marital status, becomes a parent, purchases a property, enrols into tertiary education, reaches retirement age of 65 and is hospitalised.
  - Death Benefit payable under this plan (less any amount owing to Singlife) is the higher of 101% of (total basic regular premiums paid + any single premium top-up - any withdrawal made) or the account value.
  - Terminal Illness Benefit is an early payout of the Death Benefit.
  - Flexibility to change the life assured after the first policy year, up to 2 times during the policy term.
  - Flexibility to make a single premium top-up, premium re-direction and fund switches.
  - Guaranteed Issuance Offer
  - Available in SGD only.`;
  get productFeature() {
    return this['product_feature'];
  }

  @Expose()
  @IsOptional()
  private 'product_risk' = `=- Policy may lapse if there are insufficient units to pay for the applicable fees and charges.
  - An early termination of the Policy before the end of Minimum investment Period usually involves high costs and the surrender value, if any, may be less than the total premiums paid for the plan.`;
  get productRisk() {
    return this['product_risk'];
  }

  @Expose()
  @IsOptional()
  private 'product_limitation' = `- Backdating is not allowed for this plan
  - Provides minimal protection cover (i.e. the higher of 101% of (total basic regular premiums paid + any single premium top-up - any withdrawal made) or the account value) and there is no option to choose the coverage amount for this plan
  - There is no option to choose for the premium payment term and policy term. 
  - Change of Life Assured will not be allowed once (i) the Life Assured or the Assured dies; (ii) a claim on Terminal Illness Benefit is admitted; or (iii) the premiums of basic plan is waived.
  - Back-payment of missed basic regular premium is not allowed.
  - There are fees and charges that are non-guaranteed for this plan (such as the Administrative Charge, Supplementary Charge and etc) and subject to change with a prior written notice of at least 30 days.`;
  get productLimitation() {
    return this['product_limitation'];
  }

  @Expose()
  @IsOptional()
  private 'product_premium_term' = '';
  get productPremiumTerm() {
    return this['product_premium_term'];
  }

  @Expose()
  @IsOptional()
  private 'product_policy_term' = '';
  get productPolicyTerm() {
    return this['product_policy_term'];
  }

  @Expose()
  @IsOptional()
  private 'product_frequency' = '';
  get productFrequency() {
    return this['product_frequency'];
  }

  @Expose()
  private 'premium_mode' = '';
  get premiumMode() {
    return this['premium_mode'];
  }

  @Expose()
  @IsOptional()
  private 'payment_method' = '';
  get paymentMethod() {
    return this['payment_method'];
  }

  @Expose()
  @IsNotEmpty()
  @IsNotEmptyAndOneOfThese(['Yes', 'No'])
  private 'bsc_classification' = 'Yes';
  get bscClassification() {
    return this['bsc_classification'] === 'Yes' ? true : false;
  }
  
  @Expose()
  @IsOptional()
  private 'product_fee_charges' = `a) Administrative Charge
  - 0.65% per annum of the account value
  - Deducted monthly via cancellation of units throughout the policy term
  - This charge will continue to be payable during premium holiday. 
  - This charge is not guaranteed and Singapore Life Ltd. reserves the right to increase this charge by giving the policyholder at least 30 days written notice.
  
  b) Supplementary Charge
  - 1.85% per annum of the account value
  - Deducted monthly via cancellation of units for the first 10 policy years
  - This charge will continue to be payable during premium holiday.
  - This charge is not guaranteed and Singapore Life Ltd. reserves the right to increase this charge by giving the policyholder at least 30 days written notice.
  
  c) Cost of Insurance (COI)
  - Payable monthly via cancellation of units, based on the attained age next birthday, gender and smoking status of the life assured as well as the Sum at risk (SAR) of the policy.
  - SAR equals to 101% of [total basic regular premiums paid plus any single premium top-up less off any withdrawal made] less of account value.
  - If SAR of the policy is less than or equals to zero, no COI will be charged. 
  - COI (if applicable) will continue to be payable during premium holiday.
  - COI rates for Death Benefit and Terminal Illness Benefit are guaranteed. 
  
  d) Premium Shortfall Charge
  - This charge (where applicable) will apply if any basic regular premium is not paid during the minimum investment period
  - Deducted via cancellation of units
  - This charge will apply on the first working day after the end of grace period of the unpaid basic regular premium and every month thereafter until the basic regular premium payment is resumed or until the minimum investment period ends, whichever is earlier.
  
  e) Partial Withdrawal Charge
  - This charge will apply to any partial withdrawal during the minimum investment period, except for withdrawals under the Life Stage Benefit. It will be deducted from the partial withdrawn amount.
  
  f) Surrender Charge
  - This charge will apply to full surrender of the policy during the minimum investment period. It will be deducted from the account value payable to the policyholder.
  
  g) Premium Charge
  - Basic regular premium: Not applicable.
  - Single premium top-up: There is no premium charge for single premium top-up currently. However, Singapore Life Ltd. reserves the right to apply a premium charge for single premium top-up or vary the charge by giving the policyholder at least 30 days written notice.
  
  h) Fund Switch Fee
  - No fund switch fee for fund switching currently.
  - However, Singapore Life Ltd. reserves the right to impose a fund switch fee or limit on the number of free switches by giving the policyholder at least 30 days written notice.
  
  i) Policy Fee
  - Not applicable
  
  j) Annual Management Charge
  - Varies depending on the ILP sub-fund that the policyholder chooses. 
  - Applied at the underlying fund level and is not deducted from the policy via cancellation of units.
  - Please refer to the relevant fund summary of the ILP sub-funds on www.singlife.com for details of the annual management charge.
  
  (For more details on the fees and charges for Singlife Savvy Invest, please refer to the Product Summary.)
  `;
  get productFeeCharges() {
    return this['product_fee_charges'];
  }

  @Expose()
  @IsOptional()
  private 'fund_house' = 'Fund House';
  get fundHouse() {
    return this['fund_house'];
  }

  @Expose()
  @IsOptional()
  private 'fund_type' = 'HCL';
  get fundType() {
    return this['fund_type'];
  }

  @Expose()
  @IsOptional()
  private 'fund_risk_classification' = '-';
  get fundRiskClassification() {
    return this['fund_risk_classification'];
  }

  @Expose()
  @IsOptional()
  private 'fund_risk_rating' = '5';
  get fundRiskRating() {
    return this['fund_risk_rating'];
  }

  @Expose()
  @IsOptional()
  private 'fund_currency' = 'SGD';
  get fundCurrency() {
    return this['fund_currency'];
  }

  @Expose()
  @IsOptional()
  private 'fund_asset_class' = 'Money Market';
  get fundAssetClass() {
    return this['fund_asset_class'];
  }

  @Expose()
  @IsOptional()
  private 'fund_geographical_focus' = 'Geographical Focus';
  get fundGeographicalFocus() {
    return this['fund_geographical_focus'];
  }
  
  @Expose()
  @IsOptional()
  private 'fund_sector' = 'Fund Sector';
  get fundSector() {
    return this['fund_sector'];
  }
  
  @Expose()
  @IsOptional()
  @IsOneOfThese(['Yes', 'No', ''])
  private 'apl_fund' = 'Yes';
  get aplFund() {
    return this['apl_fund'] === 'Yes' ? true : false;
  }
  
  @Expose()
  @IsNotEmpty()
  status ='Active';
}