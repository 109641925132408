import { Base } from '../Base'
import ILPPortfolioV3 from './ILPPortfolioV3'
import { Default } from '@master/annotation/Default'

interface ErrorDetail {
  client: string;
  errorMsg: string;
  justification: string;
  modelKey: string;
  modelSubKey?: string;
}

export interface ProductValidationError {
  type: string;
  title: string;
  justificationMsg: string;
  navigationMsg?: string;
  navigationUrl?: string;
  errors: ErrorDetail[];
}

export default class ILPPortfolioArray extends Base {
  ilpPortfolios: ILPPortfolioV3[] = []

  afterExtractData (data: { ilpPortfolios: any[] }) {
    const ilpPortfolios = data.ilpPortfolios || []
    data.ilpPortfolios = ilpPortfolios.map(element => {
      const ilpPortfolio = new ILPPortfolioV3()
      Object.assign(ilpPortfolio, ilpPortfolio.extractData(element))
      return ilpPortfolio
    })
    return data
  }

  convertToRawData () {
    const rawArray = this.ilpPortfolios.map(ilpPortfolio => ilpPortfolio.convertToRawData())

    return {
      /* eslint-disable @typescript-eslint/camelcase */
      ilp_portfolios: rawArray
    }
  }
}
