import './DIContainer'
import { createApp } from 'vue'
import './design/element-variables.scss'
import App from './App.vue'
import router from './router'
import store from './store'
// import '@fortawesome/fontawesome-free/css/all.css'
// import '@fortawesome/fontawesome-free/js/all.js'
import CorePlugins from '@core/CorePlugins'
import { cid, container } from 'inversify-props'
import { SharedVariable } from '@core/model/SharedVariable'
import UUID from './util/UUID'
// import { initMainService } from '@core/services/MainService'
const $uuid = new UUID()
import axios from 'axios'
import Vue3EasyDataTable from "vue3-easy-data-table";
import 'vue3-easy-data-table/dist/style.css';
import ElementPlus from 'element-plus'
// import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import { abilitiesPlugin } from '@casl/vue';
import { ability } from '@core/services/CaslAbility';
// Vue.prototype.$uuid = $uuid
// Vue.prototype.$baseURL = __BASE_URL__

// CorePlugins.forEach(({ plugin, options }) => {
//   if (options) {
//     Vue.use(plugin, options)
//   } else {
//     Vue.use(plugin)
//   }
// })

// Vue.config.productionTip = false

// const vue = new Vue({
//   router,
//   store,
//   render: h => h(App)
// }).$mount('#app')

// const vueApp = createApp(App).use(router).use(store)
const vueApp = createApp(App)
const config = {
  productionTip: false,
  demoMode: {
    error: false
  }
}

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  vueApp.component(key, component)
}
vueApp.use(abilitiesPlugin, ability);


vueApp.use(router).use(store)
vueApp.use(ElementPlus)
vueApp.component("EasyDataTable", Vue3EasyDataTable)
vueApp.config.productionTip = config.productionTip;
vueApp.config.globalProperties.$config = config
vueApp.config.globalProperties.$uuid = $uuid
vueApp.config.globalProperties.$baseURL = __BASE_URL__

CorePlugins.forEach(({ plugin, options }) => {
  if (options) {
    vueApp.use(plugin, options)
  } else {
    vueApp.use(plugin)
  }
})
  //  <!-- error interceptor -->
  vueApp.config.errorHandler = function (error) {
    // error: error trace
    // vm: component in which error occured
    // info: Vue specific error information such as lifecycle hooks, events etc.
    console.error('Global Error:====', error)
    const sharedVariable = container.get<SharedVariable>(cid.SharedVariable)
    const { uiService } = sharedVariable
    if (uiService.demoMode === 'demo') {
      const element = document.getElementById('mochaLogo')
      element.classList.add('error')
      setTimeout(() => {
        element.classList.remove('error')
      }, 6000)
      return
    }
    if (error.toString() !== 'Error: Network Error') {
       uiService.alert.show({
        type: 'error',
        message: error.toString()
      })
    }
  }

vueApp.mount('#app')

axios.interceptors.response.use((response) => {
  // if missing user login, redirect to login page
  // Note: for user unauthenticated, backend should return status code 401 instate of 200
  // without frontend to check the error message, due to error message sometimes can be misspell
  if (response?.data?.error === 'Missing User Login') {
    if (vueApp.$route.name !== 'Login') {
      vueApp.$router.push({ name: 'Login', query: { redirect: vueApp.$route.fullPath } })
    }
  }
  return Promise.resolve(response)
})

const shareVariable = container.get<SharedVariable>('SharedVariable')
shareVariable.vue = vueApp
// initMainService()

export default vueApp
