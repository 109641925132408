import { ClientBase } from '@master/model/ClientBase';

export default class FinancialObjectivesDTO extends ClientBase {
  FoHaveMedicalCondition: 'yes' | 'no' | '' = '';
  FoChecked?: boolean;
  FoMedicalReason = '';
  FoAdditionalNotes = '';

  FoProtectingPriority: string = ''
  FoDisabilityPriority: string = ''
  FoCriticalIllenessPriority: string = ''
  FoDisabilityIncomePriority: string = ''
  FoMedicalCostPriority: string = ''
  FoPregnencyRelatedePriority: string = ''
  FoPersonalAccidentPriority: string = ''
  FoProtectionNeedsPriority: string = ''
  FoRetirementPriority: string = ''
  FoChildrenEducationPriority: string = ''
  FoOtherPurposePriority: string = ''
  FoRetirementSchemePriority: string = ''
  FoEstatePlanningPriority: string = ''

  constructor (type: 'client_0' | 'client_1' = 'client_0') {
    super(type)
  }
}
