import { DateService } from '@master/services/DateService'
import { registerDecorator, ValidationOptions, ValidationArguments } from 'class-validator'
import { isEmpty, isNumber } from 'lodash/fp'

const dateService = new DateService()

export function IsValidDate (dateFormat: string) {
  return function (object: object, propertyName: string) {
    registerDecorator({
      name: 'isValidDate',
      target: object.constructor,
      propertyName: propertyName,
      constraints: [dateFormat],
      options: {
        message: `$value in $property column does not follow ${dateFormat} format`
      },
      validator: {
        validate (value: any, args: ValidationArguments) {
          if (!value) return true
          const [dateFormatToFollow] = args.constraints
          return dateService.isValidDateFormat(value, dateFormatToFollow)
        }
      }
    })
  }
}

export function IsValidNumber () {
  return function (object: object, propertyName: string) {
    registerDecorator({
      name: 'isValidNumber',
      target: object.constructor,
      propertyName: propertyName,
      options: {
        message: '$value in $property column is not a valid number'
      },
      validator: {
        validate (value: string) {
          return parseFloat(value).toString() === value
        }
      }
    })
  }
}

export function IsFoundValue (listName: string) {
  return function (object: object, propertyName: string) {
    registerDecorator({
      name: 'isFoundValue',
      target: object.constructor,
      propertyName: propertyName,
      options: {
        message: `Value in $property column is not found in ${listName}`
      },
      validator: {
        validate (value: string) {
          if (!value) return true
          return value.toUpperCase() !== 'NOT FOUND'
        }
      }
    })
  }
}

export function IsOneOfThese (list: string[], convertStringToArray = false, caseSensitive= true) {
  return function (object: object, propertyName: string) {
    registerDecorator({
      name: 'isOneOfThese',
      target: object.constructor,
      propertyName: propertyName,
      options: {
        message: `$value in $property column is not in this list (${list.join(' / ')})`
      },
      validator: {
        validate (value: string) {
          const listItems =list.map(item => item.toLowerCase())
          if (convertStringToArray) {
            if(caseSensitive){
              return value.split(',').every(element => list.includes(element.trim()))
            }
            return value.split(',').every(element => listItems.includes(element?.trim()?.toLowerCase()))
          }
          if(caseSensitive){
            return list.includes(value)
          }
          return value.split(',').every(element => listItems.includes(element?.trim()?.toLowerCase()))
        }
      }
    })
  }
}

export function IsOneOfTheseCaseInsensitive (list: string[]) {
  return function (object: object, propertyName: string) {
    registerDecorator({
      name: 'isOneOfTheseCaseInsensitive',
      target: object.constructor,
      propertyName: propertyName,
      options: {
        message: `$value in $property column is not in this list (${list.join(' / ')})`
      },
      validator: {
        validate (value: string) {
          const lowerCaseList = list.map(src => {
            if (src) {
              return src.toLowerCase()
            }
            return src
          })

          return lowerCaseList.includes(value?.trim()?.toLowerCase())
        }
      }
    })
  }
}

export function IsNotEmpty () {
  return function (object: object, propertyName: string) {
    registerDecorator({
      name: 'isNotEmpty',
      target: object.constructor,
      propertyName: propertyName,
      options: {
        //message: '$property column cannot be empty'
        message: '$property is missing'
      },
      validator: {
        validate (value: string) {
          return !isEmpty(value.trim())
        }
      }
    })
  }
}

export function IsNumber () {
  return function (object: object, propertyName: string) {
    registerDecorator({
      name: 'isNumber',
      target: object.constructor,
      propertyName: propertyName,
      options: {
        message: '$value in $property column is not a valid number'
      },
      validator: {
        validate (value: any) {
          return isNumber(value) && !Number.isNaN(value)
        }
      }
    })
  }
}

export function IsValidNumberOrPercentage (colName: string) {
  return function (object: object, propertyName: string) {
    registerDecorator({
      name: 'isValidNumberOrPercentage',
      target: object.constructor,
      propertyName: propertyName,
      options: {
        message: `$value in ${colName} column can only be number or percentage (number followed by '%')`
      },
      validator: {
        validate (value: string) {
          let valid = false
          if (value.includes('%')) {
            const pct = parseFloat(value.replace('%', '')).toFixed(2)
            valid = `${parseFloat(value).toFixed(2)}` === pct
          } else {
            valid = `${parseFloat(value)}` === value
          }
          return valid
        }
      }
    })
  }
}

export function IsValidJsonStringArray () {
  return function (object: object, propertyName: string) {
    registerDecorator({
      name: 'isValidJsonStringArray',
      target: object.constructor,
      propertyName: propertyName,
      options: {
        message: '$value in $property is not a correct format, please refer template'
      },
      validator: {
        validate (value: string) {
          try {
            JSON.parse(value).join()
            return true
          } catch (error) {
            return false
          }
        }
      }
    })
  }
}

export function IsOptionalOrOneOfThese (list: string[], convertStringToArray = false, caseSensitive = true) {
  return function (object: object, propertyName: string) {
    registerDecorator({
      name: 'isOptionalOrOneOfThese',
      target: object.constructor,
      propertyName: propertyName,
      options: {
        message: `$value in $property column is not in this list (${list.join(' / ')})`
      },
      validator: {
        validate (value: string) {
          if(value){
            const listItems =list.map(item => item.toLowerCase())
            if (convertStringToArray) {
              if(caseSensitive){
                return value.split(',').every(element => list.includes(element.trim()))
              }
              return value.split(',').every(element => listItems.includes(element?.trim()?.toLowerCase()))
            }
            if(caseSensitive){
              return list.includes(value)
            }
            return value.split(',').every(element => listItems.includes(element?.trim()?.toLowerCase()))
          }
          return true
        }
      }
    })
  }
}

export function IsNotEmptyAndOneOfThese (list: string[], convertStringToArray = false, caseSensitive= true) {
  return function (object: object, propertyName: string) {
    registerDecorator({
      name: 'isOneOfThese',
      target: object.constructor,
      propertyName: propertyName,
      options: {
        message: `$value in $property column is not in this list (${list.join(' / ')})`
      },
      validator: {
        validate (value: string) {
          if (value === undefined || value === null || value.trim() === '') {
            return true;
          }
          const listItems =list.map(item => item.toLowerCase())
          if (convertStringToArray) {
            if(caseSensitive){
              return value.split(',').every(element => list.includes(element.trim()))
            }
            return value.split(',').every(element => listItems.includes(element?.trim()?.toLowerCase()))
          }
          if(caseSensitive){
            return list.includes(value)
          }
          return value.split(',').every(element => listItems.includes(element?.trim()?.toLowerCase()))
        }
      }
    })
  }
}

