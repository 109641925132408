import { IProjectSettings } from '@master/config/IProjectSettings'
import { Ignore } from '@master/annotation/Ignore'
import NeedsBase from '@master/model/kyc-form/need-analysis/NeedsBase'
import KYCClient from '@company-common/model/kyc-form/KYCClient'
import { NA_ERROR_MSG } from '../utils/constants'


export default class PersonalAccidentModel extends NeedsBase {
  @Ignore()
  projectSettings: IProjectSettings

  @Ignore()
  selectedClient?:  KYCClient = undefined

  name = 'Personal Accident Protection'
  needs = 'PersonalAccident'

  personalAccidentalDeathNeedsPA?: number = undefined
  existingResourcesPA?: number = undefined
  totalAssuredPA?: number = undefined
  existingResourcesReason?: string = undefined
  existingResourcesPACR?:boolean = undefined


  get isCompleted() {
    const reasonCheck =
    (this.existingResourcesReasonStatus && !this.existingResourcesReason);
    return this.personalAccidentalDeathNeedsPA != null && !this.exResourcesPAError && !reasonCheck;
  }
 
  get grandTotal() {
    if(this.personalAccidentalDeathNeedsPA === undefined || this.personalAccidentalDeathNeedsPA === 0){
      return parseFloat((this.existingResourcesPA || 0)?.toFixed(2))
    } else {
      const correntpersonalAccidentalDeathNeedsPA =  ((this.existingResourcesPA || 0) - this.personalAccidentalDeathNeedsPA);
      return parseFloat(correntpersonalAccidentalDeathNeedsPA?.toFixed(2))
    }
  }

  get existingResourcesReasonStatus() {
    let needJustification = false;
    if(this.existingResourcesPA < this.totalAssuredPA) 
      needJustification = true;
    else {
      this.existingResourcesPACR = false
      this.existingResourcesReason = undefined;
    }

    return needJustification;
  }

  get exResourcesPAError () {
    if (this.existingResourcesPA > this.totalAssuredPA){
      return NA_ERROR_MSG.TB
    } else {
      return null
    }
  }

}
