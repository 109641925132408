import { ArrayBase } from '@master/model/ArrayBase'
import { ClientBase } from '@master/model/ClientBase';

export const YearsSupportMap = {
  son: {
    label: 'Son',
    year: 25
  },
  daughter: {
    label: 'Daughter',
    year: 23
  },
  male: {
    label: 'Male',
    year: 82
  },
  female: {
    label: 'Female',
    year: 86
  }
}

export default class EIDetails extends ClientBase {
  EIskipExistingInvestment: 'no_existing_investment' | 'no_disclose' | '' = '';

  EIskipRemark = '';
 
  EIDexistingInvestment = [];

  fullyDiscloseExistingInvestments: boolean | null = null;

  additionalNotes='';

  constructor (type: 'client_0' | 'client_1' = 'client_0') {
    super(type);
    this.EIDexistingInvestment = [];
    // this.DDdepentants = Array(4).fill(null).map(() => new Dependant(dateService));
  }

  setData(newData) {
    for (const key in newData) {
      if (this.hasOwnProperty(key)) { 
        this[key] = newData[key];
      }
    }
    // Mapping BE response with FE model
    this.EIskipExistingInvestment = '';
    if (newData?.discloseExistingInvestments === true && !newData.anyExistingInvestments) {
      this.EIskipExistingInvestment = 'no_existing_investment';
    } else if (newData?.anyExistingInvestments === true && !newData.discloseExistingInvestments) {
      this.EIskipExistingInvestment = 'no_disclose';
    }
    this.EIskipRemark = newData?.discloseExistingInvestmentsRemarks;
  }

  toJSON() {
    return {
      discloseExistingInvestments: this.EIskipExistingInvestment === 'no_existing_investment',
      anyExistingInvestments: this.EIskipExistingInvestment === 'no_disclose',
      discloseExistingInvestmentsRemarks: this.EIskipRemark,
      fullyDiscloseExistingInvestments: this.fullyDiscloseExistingInvestments,
      additionalNotes: this.additionalNotes,
    };
  }
}

export class Investments extends ArrayBase {
  type = 'existingInvestment'
  id?: string='';
  typeOfInvestments?:string= '';
  cashCpfSrs?:string= '';
  investmentsAmount?:number = undefined;
  currentValue?: number= undefined;
  remarksInvestment?: string = '';
  
  constructor (type: 'client_0' | 'client_1' = 'client_0') {
    super();
    // this.DDdepentants = Array(4).fill(null).map(() => new Dependant(dateService));
  }

  // isValid() {
  //   return !isUndefined(this.name) && !isNull(this.name) && this.name !== ''
  // }

  // get age() {
  //   if (!this.dob) {
  //     return null;
  //   }
  //   return this.dateService.calculateAge(this.reviewDate ? new Date(this.reviewDate) : new Date(), new Date(this.dob));
  // }
}