import { pv, sum, valueIsUndefined,infAdjReturn} from '@master/model/kyc-form/need-analysis/math'
import { IProjectSettings } from '@master/config/IProjectSettings'
import { isUndefined } from 'lodash/fp'
import { Ignore } from '@master/annotation/Ignore'
import NeedsBase from '@master/model/kyc-form/need-analysis/NeedsBase'
import KYCClient from '@company-common/model/kyc-form/KYCClient'
import { NA_ERROR_MSG, Global} from '../utils/constants'
import { isEmpty } from 'class-validator'

/**
 * Depending on the structure of the PDF, additionalNotes may not be in used
 */
export default class DeathProtectionModel extends NeedsBase {

  @Ignore()
  projectSettings: IProjectSettings

  @Ignore()
  selectedClient?:  KYCClient = undefined

  name = 'Death Protection'
  needs = 'Death'
  isProtection = true
  income?: number = undefined
  // Income section
  incomeMonthly?: number = undefined
  cacheIncomeMonthly?: number = undefined
  // incomeAnnual?: number = undefined
  incomeNoOfYears?: number = undefined
  cacheIncomeNoOfYears?: number = undefined
  incomeInvRate?: number = undefined
  cacheIncomeInvRate?: number = undefined
  incomeAssumedInfRate?: number = undefined
  cacheIncomeAssumedInfRate?: number = undefined
  //incomeInfAdjRate?: number = undefined
  // Liabilities section
  liabilitiesOutstanding?: number = undefined
  cachedLiabilitiesOutstanding?: number = undefined
  liabilitiesDebt?: number = undefined
  cachedLiabilitiesDebt?: number = undefined
  liabilitiesDependantsFunds?: number = undefined
  liabilitiesExpenses?: number = undefined
  liabilitiesOthersDesc?: string = undefined
  liabilitiesOthersAmt?: number = undefined
  // Existing Resources Allocated section
  exResourcesLIC?: number = undefined
  cachedExResourcesLIC?: number = undefined
  exResourcesCA?: number = undefined
  cachedExResourcesCA?: number = undefined
  exResourcesCPF?: number = undefined
  cachedExResourcesCPF?: number = undefined
  exResourcesInves?: number = undefined
  cachedExResourcesInves?: number = undefined
  exResourcesOthersDesc?: string = undefined
  exResourcesOthersAmt?: number = undefined

  // Justification model
  incomeMonthlyReason?: string = undefined
  exResourcesReason?:string = undefined
  incomeNoOfYearsReason?:string = undefined
  incomeInvRateReason?:string = undefined
  incomeAssumedInfRateReason?:string = undefined
  incomeInfAdjRateReason?:string = undefined
  cachedIncomeInfAdjRate?:number = undefined

  // Auto poplate Reason
  liabilitiesOutstandingReason?:string = undefined
  liabilitiesDebtReason?:string = undefined
  exResourcesLICReason?:string = undefined
  exResourcesCAReason?:string = undefined
  exResourcesCPFReason?:string = undefined
  exResourcesInvesReason?:string = undefined

  // cached Auto poplate changes
  cachedLiabilitiesOutstandingAuto?:number = undefined
  cachedLiabilitiesDebtAuto?:number = undefined
  cachedExResourcesLICAuto?:number = undefined
  cachedExResourcesCAAuto?:number = undefined
  cachedExResourcesCPFAuto?:number = undefined
  cachedExResourcesInvesAuto?:string = undefined

  // confirm review changes
  incomeMonthlyCR?:boolean = undefined
  incomeNoOfYearsCR?:boolean = undefined
  incomeInvRateCR?:boolean = undefined
  incomeAssumedInfRateCR?:boolean = undefined
  incomeInfAdjRateCR?:boolean = undefined
  liabilitiesOutstandingCR?:boolean = undefined
  liabilitiesDebtCR?:boolean = undefined
  exResourcesLICCR?:boolean = undefined
  exResourcesCACR?:boolean = undefined
  exResourcesCPFCR?:boolean = undefined
  exResourcesInvesCR?:boolean = undefined
  
  yearsRequired?: number = undefined
  infAdjReturn?: number = undefined

  // required
  required1?: number = undefined
  required2?: number = undefined
  required3?: number = undefined
  required4?: number = undefined
  required5?: number = undefined

  // available
  available1?: number = undefined
  available2?: number = undefined
  available3?: number = undefined
  available4?: number = undefined
  available5?: number = undefined

  additionalNotes = ''
 

  /**
   * this will auto set the corresponding rate to default if undefined
   * @param rate
   */
  setupDefaultRate (rate: number) {
    super.setupDefaultRate(rate)
    if (isUndefined(this.infAdjReturn)) this.infAdjReturn = this.defaultRate
  }

  get annualIncome () {
    if (valueIsUndefined(this.incomeMonthly)) return null
    return (this.projectSettings.needs.period * this.incomeMonthly) || 0
  }

  get incomeAnnual () {
    if (valueIsUndefined(this.incomeMonthly)) return undefined
    return (12 * this.incomeMonthly) || 0
  }

  get incomeInfAdjRate(){
    if (valueIsUndefined(this.incomeInvRate) && valueIsUndefined(this.incomeAssumedInfRate)) return null
    return parseFloat(infAdjReturn(this.incomeInvRate,this.incomeAssumedInfRate)?.toFixed(2)) || 0;
  }

  // PV(B10(incomeInfAdjRate),B7(incomeNoOfYears),B6(incomeAnnual),0,1)
  get incomeTotal() {
    if (valueIsUndefined(this.incomeAnnual) && valueIsUndefined(this.incomeNoOfYears)) return null
    const total = pv(this.incomeAnnual, this.incomeNoOfYears, this.incomeInfAdjRate)
    return parseFloat(total.toFixed(2))
  }
  // sum of (B13(Outstanding mortage)) + B14(Debt) + B15 (DependantsFunds)+ B16(Final expenses) + B18 (others))
  get liabilitiesTotal() {
    const result= sum(this.liabilitiesOutstanding,this.liabilitiesDebt,
      this.liabilitiesDependantsFunds,
      this.liabilitiesExpenses ,this.liabilitiesOthersAmt)||0
      return parseFloat(result.toFixed(2))
  }

  // sum of (B23(LIC) + B24(cash assets) + B25 (cpf)+ B26(investment) + B28 (others))
  get exResourcesTotal() {
    return parseFloat(sum(this.exResourcesLIC,
      this.exResourcesCA,
      this.exResourcesCPF,
      this.exResourcesInves, this.exResourcesOthersAmt)?.toFixed(2)) || 0
  }

  get grandTotal () {
     const result=this.exResourcesTotal - sum(this.incomeTotal, this.liabilitiesTotal)
      return parseFloat(result.toFixed(2))
    }

  get incomeMonthlyReasonStatus () {
    if (!this.selectedClient) return
    let isNeedReason = false
    const selectedClientByCashFlow = this.selectedClient?.cashFlow

    if (selectedClientByCashFlow?.isUserDeclinedCashFlow === true)  {
      this.incomeMonthlyReason = undefined
      this.incomeMonthlyCR = false
      return false
    }
    
    const higherSimplifiedTotalMonthlyAmount = Math.max(selectedClientByCashFlow?.simplifiedTotalInflow, selectedClientByCashFlow?.simplifiedTotalOutflow) || 0
    const higherComprehensiveTotalMonthlyAmount = Math.max(selectedClientByCashFlow?.totalMonthlyOutflow, selectedClientByCashFlow?.netEmploymentIncome) || 0
    const newIncomeMonthly = selectedClientByCashFlow?.isSimplifiedMode ? higherSimplifiedTotalMonthlyAmount : higherComprehensiveTotalMonthlyAmount
    // !this.cacheIncomeMonthly && this.incomeMonthly === undefined && (this.incomeMonthly = newIncomeMonthly)
    // this.cacheIncomeMonthly = newIncomeMonthly
    
    if (newIncomeMonthly < this.incomeMonthly){
      isNeedReason = true
      if (this.cacheIncomeMonthly !== this.incomeMonthly)  {
        this.incomeMonthlyCR = false
        this.cacheIncomeMonthly = this.incomeMonthly
      }
    } else {
      this.incomeMonthlyReason = undefined
      this.incomeMonthlyCR = false
    }

    return isNeedReason
  }

  get incomeNoOfYearsReasonStatus() {
    if (!this.selectedClient) return
    let isNeedReason = false
    let yearsRequired = null
    const selectedClientByPersonalDetails= this.selectedClient?.personalDetails

    // Recalculate no years required whenever life expectancy changes
    const lifeExpectancy = selectedClientByPersonalDetails?.gender === 'male' ? Global.LIFE_EXPECT_MALE : Global.LIFE_EXPECT_FEMALE;
    if (selectedClientByPersonalDetails?.dob && lifeExpectancy) {
      // Extract the year from the date of birth
      const birthYear = new Date(selectedClientByPersonalDetails?.dob).getFullYear();
      // Get the current year
      const currentYear = new Date().getFullYear();
      // Calculate the client's age
      const age = currentYear - birthYear;
      // Calculate the number of years required
      yearsRequired = lifeExpectancy - age;
    } 
        
    if (this.incomeNoOfYears > yearsRequired){
      isNeedReason = true
      if (this.cacheIncomeNoOfYears !== this.incomeNoOfYears)  {
        this.incomeNoOfYearsCR = false
        this.cacheIncomeNoOfYears = this.incomeNoOfYears
      }
    } else {
      this.incomeNoOfYearsReason = undefined
       this.incomeNoOfYearsCR = false
    }

    return isNeedReason
  }

  get incomeInvRateReasonStatus() {
    if (!this.selectedClient) return
    let isNeedReason = false

    if (this.incomeInvRate < 0 || this.incomeInvRate > 9) {
      isNeedReason = true
      if (this.cacheIncomeInvRate !== this.incomeInvRate)  {
        this.incomeInvRateCR = false
        this.cacheIncomeInvRate = this.incomeInvRate
      }
    } else {
      this.incomeInvRateReason = undefined
      this.incomeInvRateCR = false
    }

    return isNeedReason
  }

  get incomeAssumedInfRateReasonStatus() {
    if (!this.selectedClient) return
    let isNeedReason = false

    if (this.incomeAssumedInfRate < 0 || this.incomeAssumedInfRate > 9) {
      isNeedReason = true
      if(this.cacheIncomeAssumedInfRate !== this.incomeAssumedInfRate) {
        this.cacheIncomeAssumedInfRate = this.incomeAssumedInfRate
        this.incomeAssumedInfRateCR = false
      } 
    } else {
      this.incomeAssumedInfRateReason = undefined
      this.cacheIncomeAssumedInfRate = this.incomeAssumedInfRate
      this.incomeAssumedInfRateCR = false
    }

    return isNeedReason
  }

  get incomeInfAdjRateReasonStatus() {
    if (!this.selectedClient) return
    let isNeedReason = false

    if (this.incomeInfAdjRate < -2 || this.incomeInfAdjRate > 9) {
      isNeedReason = true
      if (this.cachedIncomeInfAdjRate !== this.incomeInfAdjRate) {
        this.cachedIncomeInfAdjRate = this.incomeInfAdjRate
        this.incomeInfAdjRateCR = false
      }
    } else {
      this.incomeInfAdjRateReason = undefined
      this.cachedIncomeInfAdjRate = this.incomeInfAdjRate
      this.incomeInfAdjRateCR = false
    }

    return isNeedReason
  }

  get liabilitiesOutstandingReasonStatus() {
    if (!this.selectedClient) return
    let isNeedReason = false
    const selectedClientByNetWorth= this.selectedClient?.netWorth

    if(selectedClientByNetWorth?.isSimplifiedMode) {
      this.liabilitiesOutstandingReason = undefined
      return false
    }

    // const calcTotal = selectedClientByNetWorth?.isUserDeclinedAssetsFlow === true ? 0 : selectedClientByNetWorth?.totalMortgage || 0
    // this.lastTotalMortgage = calcTotal
    const calcTotal = selectedClientByNetWorth?.isUserDeclinedAssetsFlow === true
    ? 0
    : sum(
      selectedClientByNetWorth?.mlResidentialProperty,
      selectedClientByNetWorth?.mlInvestmentProperty01,
      selectedClientByNetWorth?.mlInvestmentProperty02,
      selectedClientByNetWorth?.mlInvestmentProperty03
    ) || 0;

    // if (
    //   this.liabilitiesOutstanding === undefined ||
    //   this.cachedLiabilitiesOutstandingAuto !== calcTotal
    // ) {
    //   this.liabilitiesOutstanding = calcTotal || undefined
    //   this.cachedLiabilitiesOutstandingAuto = calcTotal
    // }
      
    if (this.liabilitiesOutstanding !== calcTotal && (!isEmpty(this.liabilitiesOutstanding) && !isEmpty(calcTotal)) ){
      isNeedReason = true
      if (this.liabilitiesOutstanding !== this.cachedLiabilitiesOutstanding) {
        this.cachedLiabilitiesOutstanding = this.liabilitiesOutstanding
        this.liabilitiesOutstandingCR = false
      }
    } else {
      this.liabilitiesOutstandingReason = undefined
      this.liabilitiesOutstandingCR = false
      this.cachedLiabilitiesOutstanding = this.liabilitiesOutstanding
    }
    
    // if (this.liabilitiesOutstanding !== calcTotal ){
    //   isNeedReason = true
    //   if (calcTotal !== this.liabilitiesOutstanding && this.liabilitiesOutstanding !== this.cachedLiabilitiesOutstanding) {
    //     // update the cached value in model instade of initialization.
    //     // it will handle the reset reason on value input change
    //     this.cachedLiabilitiesOutstanding = this.liabilitiesOutstanding
    //     if (this.liabilitiesOutstandingReason){
    //       this.liabilitiesOutstandingReason = undefined
    //     }
    //   }
    // } else {
    //   this.liabilitiesOutstandingReason = undefined
    //   this.cachedLiabilitiesOutstanding = this.liabilitiesOutstanding
    // }

    return isNeedReason
  }

  get liabilitiesDebtReasonStatus() {
    if (!this.selectedClient) return
    let isNeedReason = false
    const selectedClientByNetWorth= this.selectedClient?.netWorth

    if(selectedClientByNetWorth?.isSimplifiedMode) {
      this.liabilitiesDebtReason = undefined
      return false
    }

    const calcTotal = selectedClientByNetWorth?.isUserDeclinedAssetsFlow === true ? 0 : sum(selectedClientByNetWorth?.creditCard,
      selectedClientByNetWorth?.bankOverDraft,
      selectedClientByNetWorth?.outstandingTax,
      selectedClientByNetWorth?.carLoan,
      selectedClientByNetWorth?.longTermOtherAmount1,
      selectedClientByNetWorth?.longTermOtherAmount2,
      selectedClientByNetWorth?.longTermOtherAmount3,
      selectedClientByNetWorth?.shortTermOtherAmount1,
      selectedClientByNetWorth?.shortTermOtherAmount2,
      selectedClientByNetWorth?.shortTermOtherAmount3) || 0      
        
      if (this.liabilitiesDebt !== calcTotal && (!isEmpty(this.liabilitiesDebt) && !isEmpty(calcTotal)) ){
        isNeedReason = true
        if (this.liabilitiesDebt !== this.cachedLiabilitiesDebt) {
          this.cachedLiabilitiesDebt = this.liabilitiesDebt
          this.liabilitiesOutstandingCR = false
        }
      } else {
        this.liabilitiesDebtReason = undefined
        this.liabilitiesDebtCR = false
        this.cachedLiabilitiesDebt = this.liabilitiesDebt
      }
    
    // if (this.liabilitiesDebt !== calcTotal){
    //   isNeedReason = true
    //   if (this.liabilitiesDebt !== this.cachedLiabilitiesDebt) {
    //     // update the cached value in model instade of initialization.
    //     // it will handle the reset reason on value input change
    //     this.cachedLiabilitiesDebt = this.liabilitiesDebt 
    //     if (this.liabilitiesDebtReason) {
    //       this.liabilitiesDebtReason = undefined
    //     }
    //   }
    // } else {
    //   this.liabilitiesDebtReason = undefined
    //   this.cachedLiabilitiesDebt = this.liabilitiesDebt
    // }

    return isNeedReason
  }

  get exResourcesLICReasonStatus() {
    if (!this.selectedClient) return
    let isNeedReason = false
    const selectedClientByExInsurance = this.selectedClient?.existingInsurence;
      let calcTotal = 0;
      // Sum sumAssuredDeath in client's insuranceRecords
      if (selectedClientByExInsurance?.insuranceRecords) {
        calcTotal += selectedClientByExInsurance.insuranceRecords.reduce((sum, record) => sum + (record.sumAssuredDeath || 0), 0);
      }
    
    if (this.exResourcesLIC < calcTotal ){
      isNeedReason = true
      if (this.exResourcesCAReason && calcTotal !== this.cachedExResourcesLIC && this.exResourcesLIC !== this.cachedExResourcesLIC) {
        this.exResourcesLICCR = false
      }
    } else {
      this.exResourcesLICReason = undefined
      this.exResourcesLICCR = false
    }

    return isNeedReason
  }

  get exResourcesLICError () {
    if (!this.selectedClient) return
    const selectedClientByExInsurance = this.selectedClient?.existingInsurence;
      let calcTotal = 0;
      // Sum sumAssuredDeath in client's insuranceRecords
      if (selectedClientByExInsurance?.insuranceRecords && selectedClientByExInsurance?.discloseExistingInsurance !== true) {
        calcTotal += selectedClientByExInsurance.insuranceRecords.reduce((sum, record) => sum + (record.sumAssuredDeath || 0), 0);
      }
    
    if (this.exResourcesLIC > calcTotal ){
      return NA_ERROR_MSG.TB
    } else {
      return null
    }
  }

  get exResourcesCAReasonStatus() {
    if (!this.selectedClient) return
    let isNeedReason = false
    const selectedClientByNetWorth= this.selectedClient?.netWorth
      
    const calcTotal = selectedClientByNetWorth?.isUserDeclinedAssetsFlow === true
      ? 0
      : selectedClientByNetWorth?.isSimplifiedMode
        ? selectedClientByNetWorth?.simplifiedTotalCash || 0
        : sum(
          selectedClientByNetWorth?.savings,
          selectedClientByNetWorth?.fixedDeposits,
          selectedClientByNetWorth?.cashNearCashOthersAmount
        ) || 0;

    // if (
    //   this.exResourcesCA === undefined ||
    //   this.cachedExResourcesCAAuto !== calcTotal
    // ) {
    //   this.exResourcesCA = calcTotal || undefined
    //   this.cachedExResourcesCAAuto = calcTotal
    // }
      
    if (this.exResourcesCA !== calcTotal && (!isEmpty(this.exResourcesCA) && ! isEmpty(calcTotal)) ){
      isNeedReason = true
      if (this.exResourcesCA !== this.cachedExResourcesCA) {
        this.cachedExResourcesCA = this.exResourcesCA
        this.exResourcesCACR = false
      }
    } else {
      this.exResourcesCAReason = undefined
      this.exResourcesCACR = false
      this.cachedExResourcesCA = this.exResourcesCA
    }

    return isNeedReason
  }

  get exResourcesCPFReasonStatus() {
    if (!this.selectedClient) return
    let isNeedReason = false
    
    const selectedClientByNetWorth= this.selectedClient?.netWorth
      
    const calcTotal = selectedClientByNetWorth?.isSimplifiedMode ? selectedClientByNetWorth?.simplifiedTotalCPF : sum(selectedClientByNetWorth?.cpfBalanceOA,
      selectedClientByNetWorth?.cpfBalanceSA,
      selectedClientByNetWorth?.cpfBalanceMA,
    ) || 0

    
    if ( !selectedClientByNetWorth?.isUserDeclinedAssetsFlow && this.exResourcesCPF !== calcTotal ){
      isNeedReason = true
      if (this.exResourcesCPF !== this.cachedExResourcesCPF) {
        this.cachedExResourcesCPF = this.exResourcesCPF
        this.exResourcesCPFCR = false
      }
    } else {
      this.exResourcesCPFReason = undefined
      this.cachedExResourcesCPF = this.exResourcesCPF 
      this.exResourcesCPFCR = false
    }

    return isNeedReason
  }

  get exResourcesInvesReasonStatus() {
    if (!this.selectedClient) return
    let isNeedReason = false
    const selectedClientByNetWorth= this.selectedClient?.netWorth

    if(selectedClientByNetWorth?.isSimplifiedMode) {
      this.exResourcesInvesReason = undefined
      this.exResourcesInvesCR = false
      return false
    }
      
    const calcTotal = sum(selectedClientByNetWorth?.unitTrusts,
      selectedClientByNetWorth?.stocks,
      selectedClientByNetWorth?.bonds,
    ) || 0
    
    if (this.exResourcesInves !== calcTotal && (!isEmpty(this.exResourcesInves) && !isEmpty(calcTotal)) ){
      isNeedReason = true
      if (this.exResourcesInves !== this.cachedExResourcesInves) {
        this.exResourcesInvesReason = undefined
        this.cachedExResourcesInves = this.exResourcesInves
        this.exResourcesInvesCR = false
      }
    } else {
      this.exResourcesInvesReason = undefined
      this.cachedExResourcesInves = this.exResourcesInves
      this.exResourcesInvesCR = false
    }

    return isNeedReason
  }

  set setSelectedClient (client) {
    this.selectedClient = client
  }



  get fundsRequired () {
    if (valueIsUndefined(this.income) && valueIsUndefined(this.yearsRequired)) return null
    const total = pv(this.income, this.yearsRequired, this.infAdjReturn)
    return Math.round(total)
  }

  get sumRequired () {
    return sum(this.required1, this.required2, this.required3, this.required4, this.required5)
  }

  get totalRequired () {
    return sum(this.fundsRequired, this.sumRequired)
  }

  get sumAvailable () {
    return sum(this.available1, this.available2, this.available3, this.available4, this.available5)
  }

  get surplusShortfall () {
    if (valueIsUndefined(this.sumAvailable) && valueIsUndefined(this.totalRequired)) return null
    return sum(this.sumAvailable, -this.totalRequired)
  }

  constructor (type: 'client_0' | 'client_1' = 'client_0', projectSettings?: IProjectSettings, id = 'p1') {
    super(`${type}_death_${id}`)
    this.projectSettings = projectSettings
  }

  get isDefaultRate () {
    return this.defaultRate === this.infAdjReturn
  }

  get isCompleted () {
    const clearIncomeMandatory = this.incomeMonthly != null || this.incomeNoOfYears != null || this.incomeInvRate != null
    const clearliabilitiesMandatory = this.liabilitiesOthersAmt != null || !!this.liabilitiesOthersDesc
    const clearExResourcesMandatory = !!this.exResourcesOthersDesc || this.exResourcesOthersAmt != null
    let allIncomFilled = true
    let allLiabFilled = true
    let allExFilled = true
    if (clearIncomeMandatory) {
      const aif = this.incomeMonthly && this.incomeNoOfYears && this.incomeInvRate && this.incomeAssumedInfRate
      allIncomFilled = aif != null
    }
    if (clearliabilitiesMandatory) {
      allLiabFilled = this.liabilitiesOthersAmt != null && !!this.liabilitiesOthersDesc
    }
    if (clearExResourcesMandatory) {
      allExFilled = !!this.exResourcesOthersDesc && this.exResourcesOthersAmt != null
    }

    const reasonCheck = 
    ((this.incomeMonthlyReasonStatus && !this.incomeMonthlyReason) || (this.incomeMonthlyReason && !this.incomeMonthlyCR)) ||
    ((this.incomeNoOfYearsReasonStatus && !this.incomeNoOfYearsReason) || (this.incomeNoOfYearsReason && !this.incomeNoOfYearsCR)) || 
    ((this.incomeInvRateReasonStatus && !this.incomeInvRateReason) || (this.incomeInvRateReason && !this.incomeInvRateCR)) ||
    ((this.incomeAssumedInfRateReasonStatus && !this.incomeAssumedInfRateReason) || (this.incomeAssumedInfRateReason && !this.incomeAssumedInfRateCR)) ||
    ((this.incomeInfAdjRateReasonStatus && !this.incomeInfAdjRateReason) || (this.incomeInfAdjRateReason && !this.incomeInfAdjRateCR)) ||
    ((this.liabilitiesOutstandingReasonStatus && !this.liabilitiesOutstandingReason) || (this.liabilitiesOutstandingReason && !this.liabilitiesOutstandingCR)) ||
    ((this.liabilitiesDebtReasonStatus && !this.liabilitiesDebtReason) || (this.liabilitiesDebtReason && !this.liabilitiesDebtCR)) ||
    ((this.exResourcesLICReasonStatus && !this.exResourcesLICReason) || (this.exResourcesLICReason && !this.exResourcesLICCR)) ||
    ((this.exResourcesCAReasonStatus && !this.exResourcesCAReason) || (this.exResourcesCAReason && !this.exResourcesCACR)) ||
    ((this.exResourcesCPFReasonStatus && !this.exResourcesCPFReason) || (this.exResourcesCPFReason && !this.exResourcesCPFCR)) ||
    ((this.exResourcesInvesReasonStatus && !this.exResourcesInvesReason) || (this.exResourcesInvesReason && !this.exResourcesInvesCR)) || 
    !!this.exResourcesLICError


    return allIncomFilled && allLiabFilled && allExFilled && !reasonCheck && this.incomeTotal != null
  }

  resetAllFields (): void {
    super.resetAllFields()
    this.isProtection = true;
  }
}