import { IProjectSettings } from '@master/config/IProjectSettings'
import { Ignore } from '@master/annotation/Ignore'
import NeedsBase from '@master/model/kyc-form/need-analysis/NeedsBase'


export default class HospitalizationModel extends NeedsBase {
  @Ignore()
  projectSettings: IProjectSettings

  name = 'Hospitalization Protection'
  needs = 'Hospitalization'
  isProtection = true

  hospitalType?: string = ""
  deductible?: string = ""
  hospitalPlanCover?: string = ""
  insuranceType?: string = ""


  get isCompleted() {
    const clearHospitalPlanCoverMandatory = !!this.hospitalPlanCover;
    let insuranceTypeFilled = true;

    if (clearHospitalPlanCoverMandatory) {
      insuranceTypeFilled = this.insuranceType.length > 0;
    }

    return !!this.hospitalType && !!this.deductible && insuranceTypeFilled;
  }


  // constructor (type: 'client_0' | 'client_1' = 'client_0', projectSettings: IProjectSettings, id = 'p1') {
  //   super(`${type}_${id}`)
  //   this.projectSettings = projectSettings
  // }

  // resetAllFields (): void {
  //   super.resetAllFields()
  //   this.isProtection = true
  // }
}
